import { List, fromJS } from 'immutable';

import { Client } from '../client/client.model';
import { Cloneable } from '../cloneable.model';
import { Discount } from '../discount/discount.model';
import { Lineitem } from '../line-item/line-item.model';
import { ProductBrand } from '../product-brand/product-brand.model';
import { ProductCategory } from '../product-category/product-category.model';
import { ProductJSON } from './product-json.model';
import { ProductLevelCommissionSetting } from './product-level-comission/product-level-comission-setting.model';
import { ProductSerialized } from './product-serialized.model';
import { ProductSupplier } from '../product-supplier/product-supplier.model';
import { Serializable } from '../serializable.model';
import { Stylist } from '../stylist/stylist.model';
import { Utilities } from 'src/app/utilities/utilities';

export interface ProductSelectEvent {
  index: number;
  product: Product;
}

export interface ProductFormModalCallBackData {
  product: Product;
  action: string;
}

export interface ProductCategoryFormModalCallBackData {
  productCategory: ProductCategory;
  action: string;
}

export interface ProductSupplierFormModalCallBackData {
  productSupplier: ProductSupplier;
  action: string;
}

export class Product
  extends Lineitem
  implements Cloneable<Product, ProductJSON>, Serializable<ProductSerialized> {
  constructor(options: ProductJSON) {
    super(options);
    this.id = options.id;
    this.categoryID = options.categoryID;
    this.category = options.category;
    this.supplierID = options.supplierID;
    this.supplier = options.supplier;
    this.brandID = options.brandID;
    this.brand = options.brand;
    this.description = options.description;
    this.stock = options.stock;
    this.reorderPoint = options.reorderPoint;
    this.reorderAmount = options.reorderAmount;
    this.cost = options.cost;
    this.retail = options.retail;
    this.size = options.size;
    this.markupType = options.markupType;
    this.markup = options.markup;
    this.wholesale = options.wholesale;
    this.supplierCode = options.supplierCode;
    this.productCode = options.productCode;
    this.commissionType = options.commissionType;
    this.commissionDistributionType = options.commissionDistributionType;
    this.commissionValue = options.commissionValue;
    // this.productSalonTaxIDs = options.productSalonTaxIDs || List([]);
    this.productLevelCommissions = options.productLevelCommissions || List([]);
    this.trackInventory = options.trackInventory;
    this.imageURL = options.imageURL
  }
  public readonly id: number;

  public readonly categoryID: number;

  public readonly category: ProductCategory;

  public readonly supplierID: number;

  public readonly supplier: ProductSupplier;

  public readonly brandID: number;

  public readonly brand: ProductBrand;

  public readonly description: string;

  public readonly stock: number;

  // public readonly reorder: number;

  public readonly cost: number;

  public readonly retail: number;

  public readonly size: string;

  public readonly markupType: number;

  public readonly markup: number;

  public readonly wholesale: number;

  public readonly supplierCode: string;

  public readonly productCode: string;

  public readonly commissionType: number;

  public readonly commissionDistributionType: number;

  public readonly commissionValue: number;

  // public readonly productSalonTaxIDs: List<number>;

  public readonly productLevelCommissions: List<ProductLevelCommissionSetting>;

  public readonly trackInventory: number;

  public readonly  reorderAmount: number;

  public readonly  reorderPoint: number;

  public readonly imageURL: string;

  public static parseProductData(params): Product {
    let productLevelCommissions: List<ProductLevelCommissionSetting> = List([]);

    if (params['productLevelCommissions']) {
      for (const setting of params['productLevelCommissions']) {
        productLevelCommissions = productLevelCommissions.push(
          new ProductLevelCommissionSetting({
            stylistID: setting['stylistID'],
            stylist: undefined,
            commissionValue: setting['commissionValue']
          })
        );
      }
    }

    return new Product({
      id: params['id'],
      lineItemID: undefined,
      clientID: undefined,
      client: undefined,
      type: 'product',
      name: params['name'],
      price: params['cost'],
      quantity: 1,
      isRefund: 0,
      stylistID: undefined,
      stylist: undefined,
      discount: undefined,
      taxRateType: params['taxRateType'],
      customTaxes: params['customTaxes']
        ?  Utilities.convertToList(fromJS(params['customTaxes']))
        : List([]),
      isPhantom: true,

      // for products
      categoryID: params['categoryID'],
      category: params['category']
        ? ProductCategory.parse(params['category'])
        : undefined,
      supplierID: params['supplierID'],
      supplier: params['supplier']
        ? ProductSupplier.parse(params['supplier'])
        : undefined,
      brandID: params['brandID'],
      brand: params['brand'] ? ProductBrand.parse(params['brand']) : undefined,
      description: params['description'],
      stock: params['stock'],
      // reorder: params['reorder'],
      cost: params['cost'],
      retail: params['retail'],
      size: params['size'],
      markupType: params['markupType'],
      markup: params['markup'],
      wholesale: params['wholesale'],
      supplierCode: params['supplierCode'],
      productCode: params['productCode'],
      commissionType: params['commissionType'],
      commissionDistributionType: params['commissionDistributionType'],
      commissionValue: params['commissionValue'],
      // productSalonTaxIDs: params['productSalonTaxIDs'] ? fromJS(params['productSalonTaxIDs']) : List([]),
      productLevelCommissions: productLevelCommissions,
      trackInventory: params['trackInventory'],
      reorderAmount:  params['reorderAmount'],
      reorderPoint:  params['reorderPoint'],
      imageURL: params['imageURL']
    });
  }

  public getID(): number {
    return this.id;
  }

  public getRetailPrice(): number {
    let retailPrice = 0;
    if (this.markupType === 1) {
      const percentage: number = this.markup + 1;
      retailPrice = this.price * percentage;
    } else {
      retailPrice = this.price + this.markup;
    }

    return retailPrice;
  }

  public getSize(): string {
    return this.size;
  }

  public getCategoryName(): string {
    if (!!this.category) {
      return this.category.getName();
    }
    return '';
  }

  public getBrandName(): string {
    if (!!this.brand) {
      return this.brand.getName();
    }
    return '';
  }

  public getSupplierName(): string {
    if (!!this.supplier) {
      return this.supplier.getName();
    }
    return '';
  }

  public getSupplierID(): number {
    if (!!this.supplier) {
      return this.supplier.getID();
    }
    return 0;
  }

  public getTrackInventory(): number {
    return this.trackInventory
  }

  public getReorderPoint(): number {
    return this.reorderPoint
  }

  public getReOrderAmount(): number {
    return this.reorderAmount
  }

  public getStock(): number {
    return this.stock
  }

  public getImageURL(): string {
    return this.imageURL
  }

  public setID(value: number): Product {
    const data: ProductJSON = this.toJSON();
    data.id = value;
    return new Product(data);
  }

  public setCustomTaxes(customTaxes: List<string>): Product {
    const data: ProductJSON = this.toJSON();
    data.customTaxes = customTaxes;
    return new Product(data);
  }

  public setPhantom(isPhantom: boolean): Product {
    const data: ProductJSON = this.toJSON();
    data.isPhantom = isPhantom;
    return new Product(data);
  }

  public setLineItemID(newID: number): Product {
    const data: ProductJSON = this.toJSON();
    data.lineItemID = newID;
    return new Product(data);
  }

  public setClientID(clientID: number): Product {
    const data: ProductJSON = this.toJSON();
    data.clientID = clientID;
    return new Product(data);
  }

  public setClient(client: Client): Product {
    const data: ProductJSON = this.toJSON();
    data.client = client;
    return new Product(data);
  }

  public setType(type: string): Product {
    const data: ProductJSON = this.toJSON();
    data.type = type;
    return new Product(data);
  }

  public setName(name: string): Product {
    const data: ProductJSON = this.toJSON();
    data.name = name;
    return new Product(data);
  }

  public setPrice(price: number): Product {
    const data: ProductJSON = this.toJSON();
    data.price = price;
    return new Product(data);
  }

  public setQuantity(quantity: number): Product {
    const data: ProductJSON = this.toJSON();
    data.quantity = quantity;
    return new Product(data);
  }

  public setRefund(refund: number): Product {
    const data: ProductJSON = this.toJSON();
    data.isRefund = refund;
    return new Product(data);
  }

  public setTaxRateType(taxRateType: number): Product {
    const data: ProductJSON = this.toJSON();
    data.taxRateType = taxRateType;
    return new Product(data);
  }

  public setStylist(stylist: Stylist): Product {
    const data: ProductJSON = this.toJSON();
    data.stylistID = stylist.id;
    data.stylist = stylist;
    return new Product(data);
  }

  public setDiscount(discount: Discount): Product {
    const data: ProductJSON = this.toJSON();
    discount = discount.setID(undefined);
    data.discount = discount;
    return new Product(data);
  }

  public removeDiscount(): Product {
    const data: ProductJSON = this.toJSON();
    data.discount = undefined;
    return new Product(data);
  }

  public setCategory(value: ProductCategory): Product {
    const data: ProductJSON = this.toJSON();
    data.categoryID = value.id;
    data.category = value;
    return new Product(data);
  }

  public setSupplier(value: ProductSupplier): Product {
    const data: ProductJSON = this.toJSON();
    data.supplierID = value.id;
    data.supplier = value;
    return new Product(data);
  }

  public setBrand(value: ProductBrand): Product {
    const data: ProductJSON = this.toJSON();
    data.brandID = value.id;
    data.brand = value;
    return new Product(data);
  }

  public setDescription(value: string): Product {
    const data: ProductJSON = this.toJSON();
    data.description = value;
    return new Product(data);
  }

  public setStock(value: number): Product {
    const data: ProductJSON = this.toJSON();
    data.stock = value;
    return new Product(data);
  }

  // public setReorder(value: number): Product {
  //   const data: ProductJSON = this.toJSON();
  //   data.reorder = value;
  //   return new Product(data);
  // }

  public setCost(value: number): Product {
    const data: ProductJSON = this.toJSON();
    data.cost = value;
    return new Product(data);
  }

  public setRetail(value: number): Product {
    const data: ProductJSON = this.toJSON();
    data.retail = value;
    return new Product(data);
  }

  public setSize(value: string): Product {
    const data: ProductJSON = this.toJSON();
    data.size = value;
    return new Product(data);
  }

  public setMarkupType(value: number): Product {
    const data: ProductJSON = this.toJSON();
    data.markupType = value;
    return new Product(data);
  }

  public setMarkup(value: number): Product {
    const data: ProductJSON = this.toJSON();
    data.markup = value;
    return new Product(data);
  }

  public setWholesale(value: number): Product {
    const data: ProductJSON = this.toJSON();
    data.wholesale = value;
    return new Product(data);
  }

  public setSupplierCode(value: string): Product {
    const data: ProductJSON = this.toJSON();
    data.supplierCode = value;
    return new Product(data);
  }

  public setProductCode(value: string): Product {
    const data: ProductJSON = this.toJSON();
    data.productCode = value;
    return new Product(data);
  }

  public setCommissionType(value: number): Product {
    const data: ProductJSON = this.toJSON();
    data.commissionType = value;
    return new Product(data);
  }

  public setCommissionDistributionType(value: number): Product {
    const data: ProductJSON = this.toJSON();
    data.commissionDistributionType = value;
    return new Product(data);
  }

  public setCommissionValue(value: number): Product {
    const data: ProductJSON = this.toJSON();
    data.commissionValue = value;
    return new Product(data);
  }

  public setTrackInventory(value: number): Product {
    const data: ProductJSON = this.toJSON();
    data.trackInventory = value;
    return new Product(data);
  }

  // public setProductSalonTaxIDs (value: List<number>) : Product {
  //   const data: ProductJSON = this.toJSON();
  //   data.productSalonTaxIDs = value;
  //   return new Product(data);
  // }

  public setReorderPoint(value: number): Product {
    const data: ProductJSON = this.toJSON();
    data.reorderPoint = value;
    return new Product(data);
  }

  public setReorderAmount(value: number): Product {
    const data: ProductJSON = this.toJSON();
    data.reorderAmount = value;
    return new Product(data);
  }

  public setProductLevelCommissions(
    value: List<ProductLevelCommissionSetting>
  ): Product {
    const data: ProductJSON = this.toJSON();
    data.productLevelCommissions = value;
    return new Product(data);
  }

  public setImageURL(value: string): Product {
    const data: ProductJSON = this.toJSON();
    data.imageURL = value;
    return new Product(data);
  }

  public toJSON(): ProductJSON {
    return Object.assign(super.toJSON(), {
      id: this.id,
      categoryID: this.categoryID,
      category: this.category,
      supplierID: this.supplierID,
      supplier: this.supplier,
      brandID: this.brandID,
      brand: this.brand,
      description: this.description,
      stock: this.stock,
      // reorder: this.reorder,
      cost: this.cost,
      retail: this.retail,
      size: this.size,
      markupType: this.markupType,
      markup: this.markup,
      wholesale: this.wholesale,
      supplierCode: this.supplierCode,
      productCode: this.productCode,
      commissionType: this.commissionType,
      commissionDistributionType: this.commissionDistributionType,
      commissionValue: this.commissionValue,
      // productSalonTaxIDs: this.productSalonTaxIDs,
      productLevelCommissions: this.productLevelCommissions,
      trackInventory: this.trackInventory,
      reorderAmount: this.reorderAmount,
      reorderPoint: this.reorderPoint,
      imageURL: this.imageURL
    });
  }

  public clone(): Product {
    return new Product(this.toJSON());
  }

  public serialize(): ProductSerialized {
    return {
      id: this.id,
      name: this.name,
      taxRateType: this.taxRateType,
      categoryID: this.categoryID,
      supplierID: this.supplierID,
      brandID: this.brandID,
      description: this.description,
      stock: this.stock,
      // reorder: this.reorder,
      cost: this.cost,
      retail: this.retail,
      size: this.size,
      markupType: this.markupType,
      markup: this.markup,
      wholesale: this.wholesale,
      supplierCode: this.supplierCode,
      productCode: this.productCode,
      commissionType: this.commissionType,
      commissionDistributionType: this.commissionDistributionType,
      commissionValue: this.commissionValue,
      customTaxes: this.customTaxes ? Utilities.convertToList(this.customTaxes.toJSON()) : [],
      productLevelCommissions: this.productLevelCommissions
        ? Utilities.convertToList(this.productLevelCommissions.toJSON())
        : [],
      trackInventory: this.trackInventory,
      reorderAmount: this.reorderAmount,
      reorderPoint: this.reorderPoint,
      imageURL: this.imageURL
    };
  }
}
