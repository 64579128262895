import { Cloneable } from '../cloneable.model';
import { Country } from '../country/country.model';
import { SalonJSON } from './salon-json.model';
import { SalonSerialized } from './salon-serialized.model';
import { Serializable } from '../serializable.model';
import { Utilities } from 'src/app/utilities/utilities';

export class Salon
  implements Cloneable<Salon, SalonJSON>, Serializable<SalonSerialized>
{
  public readonly accountStatus: number;
  public readonly address1: string;
  public readonly address2: string;
  public readonly analytics: string;
  public readonly appVersion: number;
  public readonly calEndTime: string;
  public readonly calStartTime: string;
  public readonly cancellationPolicy: string;
  public readonly chargifyCurrency: string;
  public readonly chargifyID: number;
  public readonly chargifyStatus: string;
  public readonly city: string;
  public readonly country: Country;
  public readonly creationDate: Date;
  public readonly currency: string;
  public readonly currencyID: string;
  public readonly email: string;
  public readonly enablePOS: number;
  public readonly fax: string;
  public readonly id: number;
  public readonly onboarding: number;
  public readonly phone: string;
  public readonly planID: number;
  public readonly postal: string;
  public readonly province: string;
  public readonly readOnly: number;
  public readonly referralCodeUsed: string;
  public readonly salonName: string;
  public readonly sameCancellationPolicyForAllStaff: number;
  public readonly sameCustomizedEmailsForAllStaff: number;
  public readonly sameSocialMediaLinksForAllStaff: number;
  public readonly subDomain: string;
  public readonly timeZone: string;
  public readonly trialLength: number;
  public readonly website: string;
  public readonly stripeUserID: string;

  constructor(options: SalonJSON) {
    this.id = options.id;
    this.subDomain = options.subDomain;
    this.salonName = options.salonName;
    this.calStartTime = options.calStartTime;
    this.calEndTime = options.calEndTime;
    this.appVersion = options.appVersion;
    this.website = options.website;
    this.address1 = options.address1;
    this.address2 = options.address2;
    this.phone = options.phone;
    this.fax = options.fax;
    this.city = options.city;
    this.province = options.province;
    this.postal = options.postal;
    this.cancellationPolicy = options.cancellationPolicy;
    this.email = options.email;
    this.readOnly = options.readOnly;
    this.timeZone = options.timeZone;
    this.planID = options.planID;
    this.accountStatus = options.accountStatus;
    this.creationDate = options.creationDate;
    this.sameSocialMediaLinksForAllStaff =
      options.sameSocialMediaLinksForAllStaff;
    this.trialLength = options.trialLength;
    this.country = options.country;
    this.referralCodeUsed = options.referralCodeUsed;
    this.analytics = options.analytics;
    this.currency = options.currency;
    this.currencyID = options.currencyID;
    this.enablePOS = options.enablePOS;
    this.onboarding = options.onboarding;
    this.chargifyID = options.chargifyID;
    this.chargifyCurrency = options.chargifyCurrency;
    this.chargifyStatus = options.chargifyStatus;
    this.sameCancellationPolicyForAllStaff =
      options.sameCancellationPolicyForAllStaff;
    this.sameCustomizedEmailsForAllStaff =
      options.sameCustomizedEmailsForAllStaff;
    this.stripeUserID = options.stripeUserID;
  }

  public static parse(salonData: Object): Salon {
    return new Salon({
      id: salonData['id'],
      subDomain: salonData['subDomain'],
      salonName: salonData['salonName'],
      calStartTime: salonData['calStartTime'],
      calEndTime: salonData['calEndTime'],
      appVersion: salonData['appVersion'],
      website: salonData['website'],
      address1: salonData['address1'],
      address2: salonData['address2'],
      phone: salonData['phone'],
      fax: salonData['fax'],
      city: salonData['city'],
      province: salonData['province'],
      postal: salonData['postal'],
      cancellationPolicy: salonData['cancellationPolicy'],
      email: salonData['email'],
      readOnly: salonData['readOnly'],
      timeZone: salonData['timeZone'],
      planID: salonData['planID'],
      accountStatus: salonData['accountStatus'],
      creationDate: salonData['creationDate'],
      sameSocialMediaLinksForAllStaff:
        salonData['sameSocialMediaLinksForAllStaff'],
      trialLength: salonData['trialLength'],
      referralCodeUsed: salonData['referralCodeUsed'],
      country: new Country(salonData['countryID'], salonData['countryName']),
      analytics: salonData['analytics'],
      currency: salonData['currency'],
      currencyID: salonData['currencyID'],
      enablePOS: salonData['enablePOS'],
      onboarding: salonData['onboarding'],
      chargifyID: salonData['chargifyID'],
      chargifyCurrency: salonData['chargifyCurrency'],
      chargifyStatus: salonData['chargifyStatus'],
      sameCancellationPolicyForAllStaff:
        salonData['sameCancellationPolicyForAllStaff'],
      sameCustomizedEmailsForAllStaff:
        salonData['sameCustomizedEmailsForAllStaff'],
      stripeUserID: salonData['stripeUserID'],
    });
  }

  public toJSON(): SalonJSON {
    return {
      id: this.getId(),
      subDomain: this.getSubDomain(),
      salonName: this.getName(),
      calStartTime: this.getCalStartTime(),
      calEndTime: this.getCalEndTime(),
      appVersion: this.getAppVersion(),
      website: this.getWebsite(),
      address1: this.getAddress1(),
      address2: this.getAddress2(),
      phone: this.getPhone(),
      fax: this.getFax(),
      city: this.getCity(),
      province: this.getProvince(),
      postal: this.getPostal(),
      cancellationPolicy: this.getCancellationPolicy(),
      email: this.getEmail(),
      readOnly: this.isReadOnly() ? 1 : 0,
      timeZone: this.getTimeZone(),
      planID: this.getPlanID(),
      accountStatus: this.getAccountStatus(),
      creationDate: this.getCreationDate(),
      sameSocialMediaLinksForAllStaff:
        this.getSameSocialMediaLinksForAllStaff(),
      trialLength: this.getTrialLength(),
      country: this.getCountry(),
      referralCodeUsed: this.getReferralCodeUsed(),
      analytics: this.getAnalytics(),
      currency: this.getCurrency(),
      currencyID: this.getCurrencyID(),
      enablePOS: this.getEnablePOS(),
      onboarding: this.onboarding,
      chargifyID: this.chargifyID,
      chargifyCurrency: this.chargifyCurrency,
      chargifyStatus: this.chargifyStatus,
      sameCancellationPolicyForAllStaff: this.sameCancellationPolicyForAllStaff,
      sameCustomizedEmailsForAllStaff: this.sameCustomizedEmailsForAllStaff,
      stripeUserID: this.stripeUserID,
    };
  }

  public clone(): Salon {
    return new Salon(this.toJSON());
  }

  public serialize(): SalonSerialized {
    return {
      countryID: this.getCountry() ? this.getCountry().getID() : undefined,
      countryName: this.getCountry() ? this.getCountry().getCountryName() : '',
      ...this.toJSON(),
    };
  }

  public getId(): number {
    return this.id;
  }

  public getSubDomain(): string {
    return this.subDomain;
  }

  public setSubDomain(subDomain: string): Salon {
    const data: SalonJSON = this.toJSON();
    data.subDomain = subDomain;
    return new Salon(data);
  }

  public getName(): string {
    return this.salonName;
  }

  public setName(salonName: string): Salon {
    const data: SalonJSON = this.toJSON();
    data.salonName = salonName;
    return new Salon(data);
  }

  public getCalStartTime(): string {
    return this.calStartTime;
  }

  public getCalEndTime(): string {
    return this.calEndTime;
  }

  public getAppVersion(): number {
    return this.appVersion;
  }

  public getWebsite(): string {
    return this.website;
  }

  public setWebsite(website: string): Salon {
    const data: SalonJSON = this.toJSON();
    data.website = website;
    return new Salon(data);
  }

  public getAddress1(): string {
    return this.address1;
  }

  public setAddress1(address: string): Salon {
    const data: SalonJSON = this.toJSON();
    data.address1 = address;
    return new Salon(data);
  }

  public getAddress2(): string {
    return this.address2;
  }

  public setAddress2(address: string): Salon {
    const data: SalonJSON = this.toJSON();
    data.address2 = address;
    return new Salon(data);
  }

  public getPhone(): string {
    return this.phone;
  }

  public setPhone(phone: string): Salon {
    const data: SalonJSON = this.toJSON();
    data.phone = phone;
    return new Salon(data);
  }

  public getFax(): string {
    return this.fax;
  }

  public setFax(fax: string): Salon {
    const data: SalonJSON = this.toJSON();
    data.fax = fax;
    return new Salon(data);
  }

  public getCity(): string {
    return this.city;
  }

  public setCity(city: string): Salon {
    const data: SalonJSON = this.toJSON();
    data.city = city;
    return new Salon(data);
  }

  public getProvince(): string {
    return this.province;
  }

  public setProvince(province: string): Salon {
    const data: SalonJSON = this.toJSON();
    data.province = province;
    return new Salon(data);
  }

  public getPostal(): string {
    return this.postal;
  }

  public setPostal(postal: string): Salon {
    const data: SalonJSON = this.toJSON();
    data.postal = postal;
    return new Salon(data);
  }

  public getCancellationPolicy(): string {
    return this.cancellationPolicy;
  }

  public setCancellationPolicy(cancellationPolicy: string): Salon {
    const data: SalonJSON = this.toJSON();
    data.cancellationPolicy = cancellationPolicy;
    return new Salon(data);
  }

  public getEmail(): string {
    return this.email;
  }

  public setEmail(email: string): Salon {
    const data: SalonJSON = this.toJSON();
    data.email = email;
    return new Salon(data);
  }

  public setReadOnly(value: number): Salon {
    const data: SalonJSON = this.toJSON();
    data.readOnly = value;
    return new Salon(data);
  }

  public isReadOnly(): boolean {
    return this.readOnly === 1 || (this.planID === 1 && this.trialExpired());
  }

  public isOnFreeTrial(): boolean {
    return this.planID === 1;
  }

  public trialDaysLeft(): number {
    return this.trialLength - Utilities.daysDiff(this.creationDate, new Date());
  }

  public trialExpired(): boolean {
    return this.trialDaysLeft() < 0;
  }

  public getTimeZone(): string {
    return this.timeZone;
  }

  public setTimeZone(timezone: string): Salon {
    const data: SalonJSON = this.toJSON();
    data.timeZone = timezone;
    return new Salon(data);
  }

  public getPlanID(): number {
    return this.planID;
  }

  public getAccountStatus(): number {
    return this.accountStatus;
  }

  public getCreationDate(): Date {
    return this.creationDate;
  }

  public getSameSocialMediaLinksForAllStaff(): number {
    return this.sameSocialMediaLinksForAllStaff;
  }

  public getTrialLength(): number {
    return this.trialLength;
  }

  public getCountry(): Country {
    return this.country;
  }

  public setCountry(country: Country): Salon {
    const data: SalonJSON = this.toJSON();
    data.country = country;
    return new Salon(data);
  }
 

  public getReferralCodeUsed(): string {
    return this.referralCodeUsed;
  }

  public getAnalytics(): string {
    return this.analytics;
  }

  public setAnalytics(analytics: string): Salon {
    const data: SalonJSON = this.toJSON();
    data.analytics = analytics;
    return new Salon(data);
  }

  public getCurrency(): string {
    return this.currency;
  }

  public setCurrency(currency: string): Salon {
    const data: SalonJSON = this.toJSON();
    data.currency = currency;
    return new Salon(data);
  }

  public getCurrencyID(): string {
    return this.currencyID;
  }

  public setCurrencyID(currencyID: string) {
    const data: SalonJSON = this.toJSON();
    data.currencyID = currencyID;
    return new Salon(data);
  }

  public getEnablePOS(): number {
    return this.enablePOS;
  }

  public setEnablePOS(enablePOS: number) {
    const data: SalonJSON = this.toJSON();
    data.enablePOS = enablePOS;
    return new Salon(data);
  }

  public setOnboarding(value: number): Salon {
    const data: SalonJSON = this.toJSON();
    data.onboarding = value;
    return new Salon(data);
  }

  public setSameCancellationPolicyForAllStaff(value: number): Salon {
    const data: SalonJSON = this.toJSON();
    data.sameCancellationPolicyForAllStaff = value;
    return new Salon(data);
  }

  public setSameCustomizedEmailsForAllStaff(value: number): Salon {
    const data: SalonJSON = this.toJSON();
    data.sameCustomizedEmailsForAllStaff = value;
    return new Salon(data);
  }

  public hasChargify(): boolean {
    return (
      this.chargifyID !== 0 &&
      this.chargifyID !== undefined &&
      this.chargifyID !== null
    );
  }

  public hasStripeAccount(): boolean {
    return (
      (this.stripeUserID !== '' && this.stripeUserID != undefined) &&
      this.stripeUserID !== null
    );
  }
}