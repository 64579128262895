import { Cloneable } from '../cloneable.model';
import { CountryJSON } from './country-json.model';

export class Country implements Cloneable<Country, CountryJSON> {
  public readonly id: number;

  public readonly countryName: string;

  constructor(id: number, countryName: string) {
    this.id = id;
    this.countryName = countryName;
  }

  public clone(): Country {
    return new Country(this.getID(), this.getCountryName());
  }

  public toJSON(): CountryJSON {
    return {
      id: this.id,
      countryName: this.countryName
    };
  }

  public getID(): number {
    return this.id;
  }

  public getCountryName(): string {
    return this.countryName;
  }
}
