import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';

import { ENV_CONFIG } from 'src/bin/env.config';
import { ErrorHandlerService } from './error-handler.service';
import { Injectable } from '@angular/core';
import { List } from 'immutable';
import { Observable } from 'rxjs';
import { Product } from 'src/app/models';
import { SalonmonsterHttpClient } from '../services/salonmonster-http-client';
import { UserService } from '../services/user.service';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductService extends SalonmonsterHttpClient {
  constructor(
    http: HttpClient,
    userService: UserService,
    protected errorHandlerService: ErrorHandlerService
  ) {
    super(http, userService, errorHandlerService);
  }

  public loadProductByID(productID: number): Observable<Product> {
    const url = `${ENV_CONFIG.API_ROOT}/products/${productID}`;
    return this.get(url).pipe(
      map((data) => {
        if (data && data.length === 1) {
          return Product.parseProductData(data[0]);
        } else {
          throwError(new Error('Invalid Product'));
        }
      })
    );
  }

  public loadProducts(): Observable<List<Product>> {
    const url = `${ENV_CONFIG.API_ROOT}/products`;

    return this.get(url).pipe(
      map((data) => {
        let products: List<Product> = List([]);

        for (const productData of data) {
          products = products.push(Product.parseProductData(productData));
        }

        return products;
      }),
      catchError((err) => {
        return throwError(this.errorHandlerService.handleError(err));
      })
    );
  }

  public saveProduct(product: Product): Observable<Product> {
    let url = `${ENV_CONFIG.API_ROOT}/products`;
    url += product.getID() !== undefined ? '/' + product.getID() : '';

    if (product.getID() !== undefined) {
      // update
      return this.put(url, product.serialize());
    } else {
      // create
      return this.post(url, product.serialize()).pipe(
        map((data) => {
          return product.setID(data.id);
        })
      );
    }
  }

  public deleteProduct(product: Product): Observable<Product> {
    const url = `${ENV_CONFIG.API_ROOT}/products/${product.id}`;

    return this.delete(url, {});
  }
}
