import {
  HttpClient,
  HttpParams,
  HttpHeaders,
  HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError, timer } from 'rxjs';
import { UserService } from './user.service';
import { map, catchError, retry } from 'rxjs/operators';
import { ErrorHandlerService } from './error-handler.service';

export class SalonmonsterHttpClient {
  constructor(
    protected http: HttpClient,
    protected userService: UserService,
    protected readonly errorHandlerService: ErrorHandlerService
  ) { }

  protected createAuthorizationHeader(
    headers: HttpHeaders = new HttpHeaders()
  ): HttpHeaders {
    let token = this.userService.getToken();
    if (typeof token === "undefined") {
      return headers.set('Content-Type', 'application/json');
    } else {
      return headers
        .set('Authorization', this.userService.getToken())
        .set('Content-Type', 'application/json');
    }
  }

  protected get<T = any>(url): Observable<T> {
    return this.http
      .get<{ data: T }>(url, {
        headers: this.createAuthorizationHeader()
      })
      .pipe(
        retry({ count: 2, delay: () => timer(1000) }), // Retry twice with 1-second delay
        map((result) => result.data),
        catchError((err: HttpErrorResponse) =>
          throwError(() => this.errorHandlerService.handleError(err))
        )
      );
  }

  protected post<T = any>(url, data): Observable<T> {
    return this.http
      .post<{ data: T }>(url, data, {
        headers: this.createAuthorizationHeader()
      })
      .pipe(
        retry({ count: 2, delay: () => timer(1000) }), // Retry twice with 1-second delay
        map((result) => result.data),
        catchError((err: HttpErrorResponse) =>
          throwError(() => this.errorHandlerService.handleError(err))
        )
      );
  }

  protected put<T = any>(url, data): Observable<T> {
    return this.http
      .put<{ data: T | any }>(url, data, {
        headers: this.createAuthorizationHeader()
      })
      .pipe(
        retry({ count: 2, delay: () => timer(1000) }), // Retry twice with 1-second delay
        map((result) => result.data),
        catchError((err: HttpErrorResponse) =>
          throwError(() => this.errorHandlerService.handleError(err))
        )
      );
  }

  protected delete<T = any>(url, data): Observable<T> {
    return this.http
      .delete<{ data: T }>(url, {
        headers: this.createAuthorizationHeader(),
        body: data
      })
      .pipe(
        retry({ count: 2, delay: () => timer(1000) }), // Retry twice with 1-second delay
        map((result) => result.data),
        catchError((err: HttpErrorResponse) =>
          throwError(() => this.errorHandlerService.handleError(err))
        )
      );
  }
}