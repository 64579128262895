import { Record } from 'immutable';

export const PaymentRecord = Record({
  id: undefined,
  name: '',
  type: undefined,
  amount: 0,
  intentID: undefined,
  last4Digits: undefined,
  expDate: undefined,
  chargeID: undefined,
  isInterac: undefined,
  applicationFee: undefined,
});
