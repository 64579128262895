import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { SalonmonsterDatePipe } from './pipes/salonmonster-date.pipe';
import { SalonMonsterCurrencyPipe } from './pipes/salonmonster-currency.pipe';
import { DiscountPipe } from './pipes/discount.pipe';
import { SafePipe } from './pipes/safe.pipe';
// import { KeyboardAttachDirectiveModule } from './directives/keyboard-attach.module';
//import { CurrencyInputModule } from './directives/currency-input.directive.module';

@NgModule({
    declarations: [
        SalonmonsterDatePipe,
        SalonMonsterCurrencyPipe,
        DiscountPipe,
        SafePipe
    ],
    imports: [
        IonicModule
    ],
    exports: [
        SalonmonsterDatePipe,
        SalonMonsterCurrencyPipe,
        // KeyboardAttachDirectiveModule,
        //CurrencyInputModule,
        DiscountPipe,
        SafePipe
    ],
    providers: []
})
export class SharedModule {}
