import { Booking } from '../booking/booking.model';
import { Country } from '../country/country.model';
import { List } from 'immutable';
import { ServiceDefinition } from '../service-definition/service-definition.model';
import { StylistJSON } from './stylist-json.model';
import { StylistSerialized } from './stylist-serialized.model';
import { User } from '../user/user.model';
import { ENV_CONFIG } from 'src/bin/env.config';

export class Stylist extends User<Stylist> {
  constructor(options?: StylistJSON) {
    super(options);

    if (options) {
      this.active = options.active;
      this.administrator = options.administrator;
      this.isRebook = options.isRebook;
      this.suggestRebookingBeforeCheckout = options.suggestRebookingBeforeCheckout;
      this.stylistKey = options.stylistKey;
      this.confirmApptsManually = options.confirmApptsManually;
      this.calendar = options.calendar;
      this.viewAllCalendars = options.viewAllCalendars;
      this.viewAllClients = options.viewAllClients;
      this.photoUrl = options.photoUrl;
      this.hideDurations = options.hideDurations;
      this.canPush = options.canPush;
      this.settingsControl = options.settingsControl;
      this.canViewReports = options.canViewReports;
      this.cc = options.cc;
      this.allowBookingsUntil = options.allowBookingsUntil;
      this.bookingHorizon = options.bookingHorizon;
      this.sendAppointmentReminderBefore =
        options.sendAppointmentReminderBefore;
      this.bookingInterval = options.bookingInterval;
      this.letClientsRegister = options.letClientsRegister;
      this.letClientsCancel = options.letClientsCancel;
      this.bookingIntro = options.bookingIntro;
      this.notifyForAllStaffActivity = options.notifyForAllStaffActivity;
      this.newBookingNotification = options.newBookingNotification;
      this.editBookingNotification = options.editBookingNotification;
      this.deleteBookingNotification = options.deleteBookingNotification;
      this.waitListRequestNotification = options.waitListRequestNotification;
      this.intercomUserHash = options.intercomUserHash;
      this.password = options.password;
      this.abTest = options.abTest;
      this.fbID = options.fbID;
      this.cancellationPolicy = options.cancellationPolicy;
      this.waitingList = options.waitingList;
      this.allowCancellationUntil = options.allowCancellationUntil;
      this.minCancellation = options.minCancellation;
      this.letClientsUseFBLogin = options.letClientsUseFBLogin;
      this.sort = options.sort;
      this.bookingsCount = options.bookingsCount;
      this.clientsCount = options.clientsCount;
      this.pronoun = options.pronoun;
      this.customPronoun = options.customPronoun;
      this.isChatBubbleEnabled = options.isChatBubbleEnabled;
    }

    this.serviceDefinitions = options.serviceDefinitions
      ? options.serviceDefinitions
      : List.of<ServiceDefinition>();
    this.bookings = options.bookings ? options.bookings : List.of<Booking>();
    this.areServiceDetailsLoaded = options.areServiceDetailsLoaded;
    this.cachedServiceDetailsOptionsEl = options.cachedServiceDetailsOptionsEl
      ? options.cachedServiceDetailsOptionsEl
      : '';
  }

  public readonly active: number;

  public readonly administrator: number;
  public readonly isRebook: number;
  public readonly suggestRebookingBeforeCheckout: number;
  public readonly stylistKey: string | undefined;

  public readonly confirmApptsManually: number;

  public readonly calendar: number;

  public readonly viewAllCalendars: number;

  public readonly viewAllClients: number;

  public readonly photoUrl: string;

  public readonly hideDurations: number;

  public readonly canPush: number;

  public readonly settingsControl: number;

  public readonly canViewReports: number;

  public readonly cc: number;

  public readonly allowBookingsUntil: string;

  public readonly bookingHorizon: string;

  public readonly sendAppointmentReminderBefore: number;

  public readonly bookingInterval: number;

  public readonly letClientsRegister: number;

  public readonly letClientsCancel: number;

  public readonly bookingIntro: string;

  public readonly notifyForAllStaffActivity: number;

  public readonly newBookingNotification: number;

  public readonly editBookingNotification: number;

  public readonly deleteBookingNotification: number;

  public readonly waitListRequestNotification: number;

  public readonly serviceDefinitions: List<ServiceDefinition>;

  public readonly bookings: List<Booking>;

  public readonly areServiceDetailsLoaded: boolean;

  public readonly cachedServiceDetailsOptionsEl: string;

  public readonly intercomUserHash: string;

  public readonly password: string;

  public readonly abTest: number;

  public readonly fbID: string;

  public readonly cancellationPolicy: string;

  public readonly waitingList: number;

  public readonly allowCancellationUntil: string;

  public readonly minCancellation: string;

  public readonly letClientsUseFBLogin: number;
  public readonly bookingsCount?: number;
  public readonly clientsCount?: number;

  public readonly sort: number;
  public readonly pronoun: number;
  public readonly customPronoun: string;
  public readonly isChatBubbleEnabled: boolean;

  public static getDefaultProfilePic(): string {
    return `./assets/images/default-profile.png`;
  }

  public static parseStylistData(stylistData): Stylist {
    let country: Country;
    if (stylistData.countryID) {
      country = new Country(stylistData.countryID, stylistData.countryName);
    }

    let bookings: List<Booking> = List([]);

    if (stylistData.bookings) {
      for (const bookingData of stylistData.bookings) {
        bookings = bookings.push(Booking.parseBooking(bookingData));
      }
    }

    return new Stylist({
      id: stylistData.id,
      firstName: stylistData.firstName,
      lastName: stylistData.lastName,
      title: stylistData.title,
      email: stylistData.email,
      phone1: stylistData.phone1,
      phone2: stylistData.phone2,
      address1: stylistData.address1,
      address2: stylistData.address2,
      city: stylistData.city,
      province: stylistData.province,
      postal: stylistData.postal,
      country: country,
      stylistKey: stylistData.stylistKey,
      active: stylistData.active,
      calendar: stylistData.calendar,
      viewAllCalendars: stylistData.viewAllCalendars,
      viewAllClients: stylistData.viewAllClients,
      photoUrl: stylistData.photoUrl,
      hideDurations: stylistData.hideDurations,
      canPush: stylistData.canPush,
      settingsControl: stylistData.settingsControl,
      canViewReports: stylistData.canViewReports,
      cc: stylistData.cc,
      confirmApptsManually: stylistData.confirmApptsManually,
      administrator: stylistData.administrator,
      isRebook: stylistData.isRebook,
      suggestRebookingBeforeCheckout: stylistData.suggestRebookingBeforeCheckout,
      allowBookingsUntil: stylistData.allowBookingsUntil,
      bookingHorizon: stylistData.bookingHorizon,
      sendAppointmentReminderBefore: stylistData.sendAppointmentReminderBefore,
      bookingInterval: stylistData.bookingInterval,
      letClientsRegister: stylistData.letClientsRegister,
      letClientsCancel: stylistData.letClientsCancel,
      bookingIntro: stylistData.bookingIntro,
      notifyForAllStaffActivity: stylistData.notifyForAllStaffActivity,
      newBookingNotification: stylistData.newBookingNotification,
      editBookingNotification: stylistData.editBookingNotification,
      deleteBookingNotification: stylistData.deleteBookingNotification,
      waitListRequestNotification: stylistData.waitListRequestNotification,
      bookings: bookings,
      intercomUserHash: stylistData.intercomUserHash,
      password: stylistData.password,
      abTest: stylistData.abTest,
      fbID: stylistData.fbID,
      cancellationPolicy: stylistData.cancellationPolicy,
      waitingList: stylistData.waitingList,
      allowCancellationUntil: stylistData.allowCancellationUntil,
      minCancellation: stylistData.minCancellation,
      letClientsUseFBLogin: stylistData.letClientsUseFBLogin,
      sort: stylistData.sort,
      bookingsCount: stylistData.bookingsCount,
      clientsCount: stylistData.clientsCount,
      pronoun: stylistData.pronoun,
      customPronoun: stylistData.customPronoun,
      isChatboxEnabled: true,
      isChatBubbleEnabled: stylistData.isChatBubbleEnabled,
    });
  }

  public setID(newID: number): Stylist {
    const data: StylistJSON = this.toJSON();
    data.id = newID;
    return new Stylist(data);
  }

  public setFirstName(firstName: string): Stylist {
    const data: StylistJSON = this.toJSON();
    data.firstName = firstName;
    return new Stylist(data);
  }

  public setLastName(lastName: string): Stylist {
    const data: StylistJSON = this.toJSON();
    data.lastName = lastName;
    return new Stylist(data);
  }

  public setTitle(title: string): Stylist {
    const data: StylistJSON = this.toJSON();
    data.title = title;
    return new Stylist(data);
  }

  public setEmail(email: string): Stylist {
    const data: StylistJSON = this.toJSON();
    data.email = email;
    return new Stylist(data);
  }

  public setPhone1(phone1: string): Stylist {
    const data: StylistJSON = this.toJSON();
    data.phone1 = phone1;
    return new Stylist(data);
  }

  public setPhone2(phone2: string): Stylist {
    const data: StylistJSON = this.toJSON();
    data.phone2 = phone2;
    return new Stylist(data);
  }

  public setAddress1(address1: string): Stylist {
    const data: StylistJSON = this.toJSON();
    data.address1 = address1;
    return new Stylist(data);
  }

  public setAddress2(address2: string): Stylist {
    const data: StylistJSON = this.toJSON();
    data.address2 = address2;
    return new Stylist(data);
  }

  public setCity(city: string): Stylist {
    const data: StylistJSON = this.toJSON();
    data.city = city;
    return new Stylist(data);
  }

  public setProvince(province: string): Stylist {
    const data: StylistJSON = this.toJSON();
    data.province = province;
    return new Stylist(data);
  }

  public setPostal(postal: string): Stylist {
    const data: StylistJSON = this.toJSON();
    data.postal = postal;
    return new Stylist(data);
  }

  public setCountry(newCountry: Country): Stylist {
    const data: StylistJSON = this.toJSON();
    data.country = newCountry.clone();
    return new Stylist(data);
  }

  public isActive(): boolean {
    return this.active === 1;
  }

  public setActive(active: number): Stylist {
    const data: StylistJSON = this.toJSON();
    data.active = active;
    return new Stylist(data);
  }

  public isAdministrator(): boolean {
    return this.administrator === 1;
  }

  public setadministrator(administrator: boolean): Stylist {
    const data: StylistJSON = this.toJSON();
    data.administrator = administrator ? 1 : 0;
    return new Stylist(data);
  }

  public getIsRebook(): number {
    return this.isRebook;
  }

  public setIsRebook(value: number): Stylist {
    const data: StylistJSON = this.toJSON();
    data.isRebook = value;
    return new Stylist(data);
  }

  public getsuggestRebookingBeforeCheckout(): number {
    return this.suggestRebookingBeforeCheckout;
  }

  public setsuggestRebookingBeforeCheckout(value: number): Stylist {
    const data: StylistJSON = this.toJSON();
    data.suggestRebookingBeforeCheckout = value;
    return new Stylist(data);
  }

  public getStylistKey(): string {
    return this.stylistKey;
  }

  public getConfirmApptsManually(): number {
    return this.confirmApptsManually;
  }

  public setConfirmApptsManually(value: number): Stylist {
    const data: StylistJSON = this.toJSON();
    data.confirmApptsManually = value;
    return new Stylist(data);
  }

  public getCalendar(): number {
    return this.calendar;
  }

  public setCalendar(value: number): Stylist {
    const data: StylistJSON = this.toJSON();
    data.calendar = value;
    return new Stylist(data);
  }

  public getViewAllCalendars(): number {
    return this.viewAllCalendars;
  }

  public setViewAllCalendars(value: number): Stylist {
    const data: StylistJSON = this.toJSON();
    data.viewAllCalendars = value;
    return new Stylist(data);
  }

  public getViewAllClients(): number {
    return this.viewAllClients;
  }

  public setViewAllClients(value: number): Stylist {
    const data: StylistJSON = this.toJSON();
    data.viewAllClients = value;
    return new Stylist(data);
  }

  public getSettingsControl(): number {
    return this.settingsControl;
  }

  public setSettingsControl(settingsControl: number): Stylist {
    const data: StylistJSON = this.toJSON();
    data.settingsControl = settingsControl;
    return new Stylist(data);
  }

  public getPhotoUrl(): string {
    return this.photoUrl;
  }

  public hasPhoto(): boolean {
    const photo = this.getPhotoUrl();
    return photo && photo !== '';
  }

  public getFullPhotoUrl(): string {
    const photo: string = this.getPhotoUrl();

    if (!this.hasPhoto()) {
      return Stylist.getDefaultProfilePic();
    }

    return ENV_CONFIG.PHOTO_ROOT + '/' + this.photoUrl;
  }

  public getFullThumbnailUrl(): string {
    const photo: string = this.getPhotoUrl();

    if (!this.hasPhoto()) {
      return Stylist.getDefaultProfilePic();
    }

    return ENV_CONFIG.STAFF_THUMBNAIL_PHOTO_ROOT + '/' + this.photoUrl;
  }

  public getHideDurations(): number {
    return this.hideDurations;
  }

  public getCanPush(): number {
    return this.canPush;
  }

  public setCanPush(value: number) {
    const data: StylistJSON = this.toJSON();
    data.canPush = value;
    return new Stylist(data);
  }

  public getCanViewReports(): number {
    return this.canViewReports;
  }

  public setCanViewReports(value: number) {
    const data: StylistJSON = this.toJSON();
    data.canViewReports = value;
    return new Stylist(data);
  }

  public getCc(): number {
    return this.cc;
  }

  public setCc(cc: number): Stylist {
    const data: StylistJSON = this.toJSON();
    data.cc = cc;
    return new Stylist(data);
  }

  public getAllowBookingsUntil(): string {
    return this.allowBookingsUntil;
  }

  public setAllowBookingsUntil(value: string): Stylist {
    const data: StylistJSON = this.toJSON();
    data.allowBookingsUntil = value;
    return new Stylist(data);
  }

  public getBookingHorizon(): string {
    return this.bookingHorizon;
  }

  public setBookingHorizon(value: string): Stylist {
    const data: StylistJSON = this.toJSON();
    data.bookingHorizon = value;
    return new Stylist(data);
  }

  public getSendAppointmentReminderBefore(): number {
    return this.sendAppointmentReminderBefore;
  }

  public setSendAppointmentReminderBefore(value: number): Stylist {
    const data: StylistJSON = this.toJSON();
    data.sendAppointmentReminderBefore = value;
    return new Stylist(data);
  }

  public getBookingInterval(): number {
    return this.bookingInterval;
  }

  public setBookingInterval(value: number): Stylist {
    const data: StylistJSON = this.toJSON();
    data.bookingInterval = value;
    return new Stylist(data);
  }

  public getLetClientsRegister(): number {
    return this.letClientsRegister;
  }

  public setLetClientsRegister(value: number): Stylist {
    const data: StylistJSON = this.toJSON();
    data.letClientsRegister = value;
    return new Stylist(data);
  }

  public getLetClientsCancel(): number {
    return this.letClientsCancel;
  }

  public setLetClientsCancel(value: number): Stylist {
    const data: StylistJSON = this.toJSON();
    data.letClientsCancel = value;
    return new Stylist(data);
  }

  public getBookingIntro(): string {
    return this.bookingIntro;
  }

  public setBookingIntro(value: string): Stylist {
    const data: StylistJSON = this.toJSON();
    data.bookingIntro = value;
    return new Stylist(data);
  }

  public getNotifyForAllStaffActivity(): number {
    return this.notifyForAllStaffActivity;
  }

  public setNotifyForAllStaffActivity(value: number): Stylist {
    const data: StylistJSON = this.toJSON();
    data.notifyForAllStaffActivity = value;
    return new Stylist(data);
  }

  public getNewBookingNotification(): number {
    return this.newBookingNotification;
  }

  public setNewBookingNotification(value: number): Stylist {
    const data: StylistJSON = this.toJSON();
    data.newBookingNotification = value;
    return new Stylist(data);
  }

  public getEditBookingNotification(): number {
    return this.editBookingNotification;
  }

  public setEditBookingNotification(value: number): Stylist {
    const data: StylistJSON = this.toJSON();
    data.editBookingNotification = value;
    return new Stylist(data);
  }

  public getDeleteBookingNotification(): number {
    return this.deleteBookingNotification;
  }

  public setDeleteBookingNotification(value: number): Stylist {
    const data: StylistJSON = this.toJSON();
    data.deleteBookingNotification = value;
    return new Stylist(data);
  }

  public getWaitListRequestNotification(): number {
    return this.waitListRequestNotification;
  }

  public setWaitListRequestNotification(value: number): Stylist {
    const data: StylistJSON = this.toJSON();
    data.waitListRequestNotification = value;
    return new Stylist(data);
  }

  public getBookings(): List<Booking> {
    return this.bookings;
  }

  public areServicesLoaded(): boolean {
    return this.areServiceDetailsLoaded;
  }

  public getCachedServiceDetailsOptionsEl() {
    return this.cachedServiceDetailsOptionsEl;
  }

  public getServiceDefinitions(): List<ServiceDefinition> {
    return this.serviceDefinitions;
  }

  public setPassword(password: string): Stylist {
    const data: StylistJSON = this.toJSON();
    data.password = password;
    return new Stylist(data);
  }

  public setAbTest(value: number): Stylist {
    const data: StylistJSON = this.toJSON();
    data.abTest = value;
    return new Stylist(data);
  }

  public setFbID(value: string): Stylist {
    const data: StylistJSON = this.toJSON();
    data.fbID = value;
    return new Stylist(data);
  }

  public setCancellationPolicy(value: string): Stylist {
    const data: StylistJSON = this.toJSON();
    data.cancellationPolicy = value;
    return new Stylist(data);
  }

  public setHideDurations(value: number): Stylist {
    const data: StylistJSON = this.toJSON();
    data.hideDurations = value;
    return new Stylist(data);
  }

  public setWaitingList(value: number): Stylist {
    const data: StylistJSON = this.toJSON();
    data.waitingList = value;
    return new Stylist(data);
  }

  public setallowCancellationUntil(value: string): Stylist {
    const data: StylistJSON = this.toJSON();
    data.allowCancellationUntil = value;
    return new Stylist(data);
  }

  public setLetClientsUseFBLogin(value: number): Stylist {
    const data: StylistJSON = this.toJSON();
    data.letClientsUseFBLogin = value;
    return new Stylist(data);
  }

  public setMinCancellation(value: string): Stylist {
    const data: StylistJSON = this.toJSON();
    data.minCancellation = value;
    return new Stylist(data);
  }

  public setSort(value: number): Stylist {
    const data: StylistJSON = this.toJSON();
    data.sort = value;
    return new Stylist(data);
  }
  public getPronoun(): number {
    return this.pronoun;
  }
  public setPronoun(pronoun: number): Stylist {
    const data: StylistJSON = this.toJSON();
    data.pronoun = pronoun;
    return new Stylist(data);
  }
  public getCustomPronoun(): string {
    return this.customPronoun;
  }
  public setCustomPronoun(customPronoun: string): Stylist {
    const data: StylistJSON = this.toJSON();
    data.customPronoun = customPronoun;
    return new Stylist(data);
  }

  public getIsChatBubbleEnabled(): boolean {
    return this.isChatBubbleEnabled;
  }
  public setIsChatBubbleEnabled(isChatBubbleEnabled: boolean): Stylist {
    const data: StylistJSON = this.toJSON();
    data.isChatBubbleEnabled = isChatBubbleEnabled;
    return new Stylist(data);
  }


  public getSort(): number {
    return this.sort;
  }

  public getBookingsCount(): number {
    return this.bookingsCount;
  }

  public getClientsCount(): number {
    return this.clientsCount;
  }

  public toJSON(): StylistJSON {
    return Object.assign(super.toJSON(), {
      active: this.active,
      administrator: this.administrator,
      isRebook: this.getIsRebook(),
      suggestRebookingBeforeCheckout: this.getsuggestRebookingBeforeCheckout(),
      stylistKey: this.getStylistKey(),
      confirmApptsManually: this.getConfirmApptsManually(),
      calendar: this.getCalendar(),
      viewAllCalendars: this.getViewAllCalendars(),
      viewAllClients: this.getViewAllClients(),
      photoUrl: this.getPhotoUrl(),
      hideDurations: this.getHideDurations(),
      canPush: this.getCanPush(),
      settingsControl: this.getSettingsControl(),
      canViewReports: this.canViewReports,
      cc: this.getCc(),
      allowBookingsUntil: this.getAllowBookingsUntil(),
      bookingHorizon: this.getBookingHorizon(),
      sendAppointmentReminderBefore: this.getSendAppointmentReminderBefore(),
      bookingInterval: this.getBookingInterval(),
      letClientsRegister: this.getLetClientsRegister(),
      letClientsCancel: this.getLetClientsCancel(),
      bookingIntro: this.getBookingIntro(),
      notifyForAllStaffActivity: this.getNotifyForAllStaffActivity(),
      newBookingNotification: this.getNewBookingNotification(),
      editBookingNotification: this.getEditBookingNotification(),
      deleteBookingNotification: this.getDeleteBookingNotification(),
      waitListRequestNotification: this.getWaitListRequestNotification(),
      pronoun: this.getPronoun(),
      customPronoun: this.getCustomPronoun(),
      serviceDefinitions: this.serviceDefinitions,
      areServiceDetailsLoaded: this.areServiceDetailsLoaded,
      cachedServiceDetailsOptionsEl: this.cachedServiceDetailsOptionsEl,
      intercomUserHash: this.intercomUserHash,
      password: this.password,
      abTest: this.abTest,
      fbID: this.fbID,
      cancellationPolicy: this.cancellationPolicy,
      waitingList: this.waitingList,
      allowCancellationUntil: this.allowCancellationUntil,
      letClientsUseFBLogin: this.letClientsUseFBLogin,
      minCancellation: this.minCancellation,
      sort: this.sort,
      bookingsCount: this.bookingsCount,
      clientsCount: this.clientsCount,
      isChatBubbleEnabled: this.isChatBubbleEnabled,
    });
  }

  public clone(): Stylist {
    return new Stylist(this.toJSON());
  }

  public serialize(): StylistSerialized {
    return {
      countryID: this.getCountry() ? this.getCountry().getID() : null,
      ...this.toJSON()
    };
  }
}
