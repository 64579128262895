import { TaxJSON } from './tax-json.model';
import { TaxRecord } from './tax-record.model';

export class Tax extends TaxRecord {
  constructor(params: TaxJSON) {
    super(params);
  }
  public readonly id: number;
  public readonly name: string;
  public readonly rate: number;
  public readonly appliesTo: number;
  public readonly bizNo: number;
  public readonly totals: number;

  public static parse(tax: Object): Tax {
    return new Tax({
      id: tax['id'],
      name: tax['name'],
      rate: tax['rate'],
      appliesTo: tax['appliesTo'],
      bizNo: tax['bizNo'],
      totals: 0
    });
  }

  public setName(value: string): Tax {
    return this.set('name', value) as Tax;
  }

  public setRate(value: number): Tax {
    return this.set('rate', value) as Tax;
  }

  public setAppliesTo(value: number): Tax {
    return this.set('appliesTo', value) as Tax;
  }

  public setBizNo(value: string): Tax {
    return this.set('bizNo', value) as Tax;
  }

  public setTotals(value: number): Tax {
    return this.set('totals', value) as Tax;
  }

  public serialize(): Object {
    return {
      id: this.id,
      name: this.name,
      rate: this.rate,
      appliesTo: this.appliesTo,
      bizNo: this.bizNo
    };
  }
}
