import { Cloneable } from '../cloneable.model';
import { Serializable } from '../serializable.model';
import { WorkDaysJSON } from './work-days-json.model';
import { WorkDaysSerialized } from './work-days-serialized.model';

export class WorkDays
  implements
    Cloneable<WorkDays, WorkDaysJSON>,
    Serializable<WorkDaysSerialized> {
  public readonly id: number;

  public readonly monStartTime: string;

  public readonly monEndTime: string;

  public readonly tueStartTime: string;

  public readonly tueEndTime: string;

  public readonly wedStartTime: string;

  public readonly wedEndTime: string;

  public readonly thuStartTime: string;

  public readonly thuEndTime: string;

  public readonly friStartTime: string;

  public readonly friEndTime: string;

  public readonly satStartTime: string;

  public readonly satEndTime: string;

  public readonly sunStartTime: string;

  public readonly sunEndTime: string;

  public readonly stylistID: number;

  constructor(options: WorkDaysJSON) {
    this.id = options.id;
    this.monStartTime = options.monStartTime;
    this.monEndTime = options.monEndTime;
    this.tueStartTime = options.tueStartTime;
    this.tueEndTime = options.tueEndTime;
    this.wedStartTime = options.wedStartTime;
    this.wedEndTime = options.wedEndTime;
    this.thuStartTime = options.thuStartTime;
    this.thuEndTime = options.thuEndTime;
    this.friStartTime = options.friStartTime;
    this.friEndTime = options.friEndTime;
    this.satStartTime = options.satStartTime;
    this.satEndTime = options.satEndTime;
    this.sunStartTime = options.sunStartTime;
    this.sunEndTime = options.sunEndTime;
    this.stylistID = options.stylistID;
  }

  public static parseWorkDay(data: Object): WorkDays {
    return new WorkDays({
      id: data['id'],
      monStartTime: data['monStartTime'],
      monEndTime: data['monEndTime'],
      tueStartTime: data['tueStartTime'],
      tueEndTime: data['tueEndTime'],
      wedStartTime: data['wedStartTime'],
      wedEndTime: data['wedEndTime'],
      thuStartTime: data['thuStartTime'],
      thuEndTime: data['thuEndTime'],
      friStartTime: data['friStartTime'],
      friEndTime: data['friEndTime'],
      satStartTime: data['satStartTime'],
      satEndTime: data['satEndTime'],
      sunStartTime: data['sunStartTime'],
      sunEndTime: data['sunEndTime'],
      stylistID: data['stylistID']
    });
  }

  public toJSON(): WorkDaysJSON {
    return {
      id: this.getId(),
      monStartTime: this.getMonStartTime(),
      monEndTime: this.getMonEndTime(),
      tueStartTime: this.getTueStartTime(),
      tueEndTime: this.getTueEndTime(),
      wedStartTime: this.getWedStartTime(),
      wedEndTime: this.getWedEndTime(),
      thuStartTime: this.getThuStartTime(),
      thuEndTime: this.getThuEndTime(),
      friStartTime: this.getFriStartTime(),
      friEndTime: this.getFriEndTime(),
      satStartTime: this.getSatStartTime(),
      satEndTime: this.getSatEndTime(),
      sunStartTime: this.getSunStartTime(),
      sunEndTime: this.getSunEndTime(),
      stylistID: this.getStylistID()
    };
  }

  public clone(): WorkDays {
    return new WorkDays(this.toJSON());
  }

  public serialize(): WorkDaysSerialized {
    return this.toJSON();
  }

  public getId(): number {
    return this.id;
  }

  public getMonStartTime(): string {
    return this.monStartTime;
  }

  public getMonEndTime(): string {
    return this.monEndTime;
  }

  public getTueStartTime(): string {
    return this.tueStartTime;
  }

  public getTueEndTime(): string {
    return this.tueEndTime;
  }

  public getWedStartTime(): string {
    return this.wedStartTime;
  }

  public getWedEndTime(): string {
    return this.wedEndTime;
  }

  public getThuStartTime(): string {
    return this.thuStartTime;
  }

  public getThuEndTime(): string {
    return this.thuEndTime;
  }

  public getFriStartTime(): string {
    return this.friStartTime;
  }

  public getFriEndTime(): string {
    return this.friEndTime;
  }

  public getSatStartTime(): string {
    return this.satStartTime;
  }

  public getSatEndTime(): string {
    return this.satEndTime;
  }

  public getSunStartTime(): string {
    return this.sunStartTime;
  }

  public getSunEndTime(): string {
    return this.sunEndTime;
  }

  public getStylistID(): number {
    return this.stylistID;
  }

  public setId(id: number): WorkDays {
    const data: WorkDaysJSON = this.toJSON();
    data.id = id;
    return new WorkDays(data);
  }

  public setMonStartTime(value: string): WorkDays {
    const data: WorkDaysJSON = this.toJSON();
    data.monStartTime = value;
    return new WorkDays(data);
  }

  public setMonEndTime(value: string): WorkDays {
    const data: WorkDaysJSON = this.toJSON();
    data.monEndTime = value;
    return new WorkDays(data);
  }

  public setTueStartTime(value: string): WorkDays {
    const data: WorkDaysJSON = this.toJSON();
    data.tueStartTime = value;
    return new WorkDays(data);
  }

  public setTueEndTime(value: string): WorkDays {
    const data: WorkDaysJSON = this.toJSON();
    data.tueEndTime = value;
    return new WorkDays(data);
  }

  public setWedStartTime(value: string): WorkDays {
    const data: WorkDaysJSON = this.toJSON();
    data.wedStartTime = value;
    return new WorkDays(data);
  }

  public setWedEndTime(value: string): WorkDays {
    const data: WorkDaysJSON = this.toJSON();
    data.wedEndTime = value;
    return new WorkDays(data);
  }

  public setThuStartTime(value: string): WorkDays {
    const data: WorkDaysJSON = this.toJSON();
    data.thuStartTime = value;
    return new WorkDays(data);
  }

  public setThuEndTime(value: string): WorkDays {
    const data: WorkDaysJSON = this.toJSON();
    data.thuEndTime = value;
    return new WorkDays(data);
  }

  public setFriStartTime(value: string): WorkDays {
    const data: WorkDaysJSON = this.toJSON();
    data.friStartTime = value;
    return new WorkDays(data);
  }

  public setFriEndTime(value: string): WorkDays {
    const data: WorkDaysJSON = this.toJSON();
    data.friEndTime = value;
    return new WorkDays(data);
  }

  public setSatStartTime(value: string): WorkDays {
    const data: WorkDaysJSON = this.toJSON();
    data.satStartTime = value;
    return new WorkDays(data);
  }

  public setSatEndTime(value: string): WorkDays {
    const data: WorkDaysJSON = this.toJSON();
    data.satEndTime = value;
    return new WorkDays(data);
  }

  public setSunStartTime(value: string): WorkDays {
    const data: WorkDaysJSON = this.toJSON();
    data.sunStartTime = value;
    return new WorkDays(data);
  }

  public setSunEndTime(value: string): WorkDays {
    const data: WorkDaysJSON = this.toJSON();
    data.sunEndTime = value;
    return new WorkDays(data);
  }

  public setStylistID(value: number): WorkDays {
    const data: WorkDaysJSON = this.toJSON();
    data.stylistID = value;
    return new WorkDays(data);
  }
}
