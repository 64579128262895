import { List, fromJS, } from 'immutable';

import { RegisterClosureJSON } from './register-closure-json.model';
import { RegisterClosureRecord } from './register-closure-record.model';
import { RegisterClosureSerialized } from './register-closure-serialized.model';
import { Serializable } from '../serializable.model';
import { Utilities } from 'src/app/utilities/utilities';

export class RegisterClosure extends RegisterClosureRecord
  implements Serializable<RegisterClosureSerialized> {
  constructor(params: RegisterClosureJSON) {
    super(params);
  }
  public readonly id: number;
  public readonly dateOpened: Date;
  public readonly dateClosed: Date;
  public readonly cashPaidIn: number;
  public readonly cashPaidOut: number;
  public readonly countedCashValue: number;
  public readonly countedDebitValue: number;
  public readonly countedVisaValue: number;
  public readonly countedMasterCardValue: number;
  public readonly countedGiftCardValue: number;
  public readonly countedAccountValue: number;
  public readonly countedAMEXValue: number;
  public readonly countedChequeValue: number;
  public readonly countedETransferValue: number;
  public readonly countedSquareValue: number;
  public readonly countedDiscoverValue: number;
  public readonly closingFloatValue: number;
  public readonly note: string;
  public readonly stylistID: number;
  public readonly dateModified: Date;
  public readonly expectedCashAmountFromSales: number;
  public readonly expectedDebitAmountFromSales: number;
  public readonly expectedVisaAmountFromSales: number;
  public readonly expectedMastercardAmountFromSales: number;
  public readonly expectedAmexAmountFromSales: number;
  public readonly expectedChequeAmountFromSales: number;
  public readonly expectedGiftcardAmountFromSales: number;
  public readonly expectedAccountAmountFromSales: number;
  public readonly expectedEtransferAmountFromSales: number;
  public readonly expectedSquareAmountFromSales: number;
  public readonly expectedDiscoverAmountFromSales: number;

  public readonly expectedStripeDebitAmountFromSales: number;

  public readonly expectedStripeVisaAmountFromSales: number;

  public readonly expectedStripeMastercardAmountFromSales: number;

  public readonly expectedStripeAmexAmountFromSales: number;

  public readonly expectedStripeDinersAmountFromSales: number;

  public readonly expectedStripeDiscoverAmountFromSales: number;

  public readonly expectedStripeJcbAmountFromSales: number;

  public readonly expectedStripeUnionpayAmountFromSales: number;

  public readonly expectedStripeUnknownAmountFromSales: number;

  public readonly expectedStripePrepaidAmountFromSales: number;

  public readonly totalTips: number;
  public readonly openingFloatValue: number;
  public readonly servicesTotal: number;
  public readonly productsTotal: number;
  public readonly taxesTotal: List<{
    get(arg0: string): number;
    name: string;
    totals: number;
  }>;

  public getTotal() {
    return this.totalTips + this.servicesTotal + this.productsTotal + this.taxesTotal.reduce((sum,b)=> sum+b.get('totals'), 0)
  }

  public static parse(params: Object): RegisterClosure {
    return new RegisterClosure({
      id: params['id'],
      dateOpened: params['dateOpened'],
      dateClosed: params['dateClosed'],
      cashPaidIn: params['cashPaidIn'],
      cashPaidOut: params['cashPaidOut'],
      countedCashValue: params['countedCashValue'],
      countedDebitValue: params['countedDebitValue'],
      countedVisaValue: params['countedVisaValue'],
      countedMasterCardValue: params['countedMasterCardValue'],
      countedGiftCardValue: params['countedGiftCardValue'],
      countedAccountValue: params['countedAccountValue'],
      countedAMEXValue: params['countedAMEXValue'],
      countedChequeValue: params['countedChequeValue'],
      countedETransferValue: params['countedETransferValue'],
      countedSquareValue: params['countedSquareValue'],
      countedDiscoverValue: params['countedDiscoverValue'],
      closingFloatValue: params['closingFloatValue'],
      note: params['note'],
      stylistID: params['stylistID'],
      dateModified: params['dateModified'],
      expectedCashAmountFromSales: params['expectedCashAmountFromSales'],
      expectedDebitAmountFromSales: params['expectedDebitAmountFromSales'],
      expectedVisaAmountFromSales: params['expectedVisaAmountFromSales'],
      expectedMastercardAmountFromSales:
        params['expectedMastercardAmountFromSales'],
        expectedAccountAmountFromSales: params['expectedAccountAmountFromSales'],
      expectedAmexAmountFromSales: params['expectedAmexAmountFromSales'],
      expectedChequeAmountFromSales: params['expectedChequeAmountFromSales'],
      expectedGiftcardAmountFromSales:
        params['expectedGiftcardAmountFromSales'],
        
      expectedEtransferAmountFromSales:
        params['expectedEtransferAmountFromSales'],
      expectedSquareAmountFromSales: params['expectedSquareAmountFromSales'],
      expectedDiscoverAmountFromSales:
        params['expectedDiscoverAmountFromSales'],
      expectedStripeDebitAmountFromSales: params['expectedStripeDebitAmountFromSales'],

       expectedStripeVisaAmountFromSales: params['expectedStripeVisaAmountFromSales'],

       expectedStripeMastercardAmountFromSales: params['expectedStripeMastercardAmountFromSales'],

       expectedStripeAmexAmountFromSales: params['expectedStripeAmexAmountFromSales'],
       expectedStripeDiscoverAmountFromSales: params['expectedStripeDiscoverAmountFromSales'],

      expectedStripeDinersAmountFromSales: params['expectedStripeDinersAmountFromSales'],

       expectedStripeJcbAmountFromSales: params['expectedStripeJcbAmountFromSales'],

       expectedStripeUnionpayAmountFromSales: params['expectedStripeUnionpayAmountFromSales'],

      expectedStripeUnknownAmountFromSales: params['expectedStripeUnknownAmountFromSales'],

      expectedStripePrepaidAmountFromSales: params['expectedStripePrepaidAmountFromSales'],

      totalTips: params['totalTips'],
      openingFloatValue: params['openingFloatValue'],
      servicesTotal: params['servicesTotal'],
      productsTotal: params['productsTotal'],
      taxesTotal:  Utilities.convertToList(fromJS(params['taxesTotal'])),
      isMostRecentRegisterClosure: params['isMostRecentRegisterClosure']
    });
  }

  public setCashPaidIn(value: number): RegisterClosure {
    return this.set('cashPaidIn', value) as RegisterClosure;
  }

  public setCashPaidOut(value: number): RegisterClosure {
    return this.set('cashPaidOut', value) as RegisterClosure;
  }

  public setCountedCashValue(value: number): RegisterClosure {
    return this.set('countedCashValue', value) as RegisterClosure;
  }

  public setCountedDebitValue(value: number): RegisterClosure {
    return this.set('countedDebitValue', value) as RegisterClosure;
  }

  public setCountedVisaValue(value: number): RegisterClosure {
    return this.set('countedVisaValue', value) as RegisterClosure;
  }

  public setCountedMasterCardValue(value: number): RegisterClosure {
    return this.set('countedMasterCardValue', value) as RegisterClosure;
  }

  public setCountedGiftCardValue(value: number): RegisterClosure {
    return this.set('countedGiftCardValue', value) as RegisterClosure;
  }

  public setCountedAccountValue(value: number): RegisterClosure {
    return this.set('countedAccountValue', value) as RegisterClosure;
  }
  public setCountedAMEXValue(value: number): RegisterClosure {
    return this.set('countedAMEXValue', value) as RegisterClosure;
  }

  public setCountedChequeValue(value: number): RegisterClosure {
    return this.set('countedChequeValue', value) as RegisterClosure;
  }

  public setCountedETransferValue(value: number): RegisterClosure {
    return this.set('countedETransferValue', value) as RegisterClosure;
  }

  public setCountedSquareValue(value: number): RegisterClosure {
    return this.set('countedSquareValue', value) as RegisterClosure;
  }

  public setCountedDiscoverValue(value: number): RegisterClosure {
    return this.set('countedDiscoverValue', value) as RegisterClosure;
  }

  public setClosingFloatValue(value: number): RegisterClosure {
    return this.set('closingFloatValue', value) as RegisterClosure;
  }

  public setNote(value: string): RegisterClosure {
    return this.set('note', value) as RegisterClosure;
  }

  public setStylistID(value: number): RegisterClosure {
    return this.set('stylistID', value) as RegisterClosure;
  }

  public serialize(): RegisterClosureSerialized {
    return {
      id: this.id,
      dateOpened: Utilities.formatDate(this.dateOpened),
      dateClosed: Utilities.formatDate(this.dateClosed),
      cashPaidIn: this.cashPaidIn,
      cashPaidOut: this.cashPaidOut,
      countedCashValue: this.countedCashValue,
      countedDebitValue: this.countedDebitValue,
      countedVisaValue: this.countedVisaValue,
      countedMasterCardValue: this.countedMasterCardValue,
      countedGiftCardValue: this.countedGiftCardValue,
      countedAccountValue: this.countedAccountValue,
      countedAMEXValue: this.countedAMEXValue,
      countedChequeValue: this.countedChequeValue,
      countedETransferValue: this.countedETransferValue,
      countedSquareValue: this.countedSquareValue,
      countedDiscoverValue: this.countedDiscoverValue,
      closingFloatValue: this.closingFloatValue,
      expectedStripeDebitAmountFromSales: this.expectedStripeDebitAmountFromSales,

      expectedStripeVisaAmountFromSales: this.expectedStripeVisaAmountFromSales,
    
       expectedStripeMastercardAmountFromSales: this.expectedStripeMastercardAmountFromSales,
    
      expectedStripeAmexAmountFromSales: this.expectedStripeAmexAmountFromSales,
    
      expectedStripeDinersAmountFromSales: this.expectedStripeDinersAmountFromSales,
    
      expectedStripeDiscoverAmountFromSales: this.expectedStripeDiscoverAmountFromSales,
    
      expectedStripeJcbAmountFromSales: this.expectedStripeJcbAmountFromSales,
    
      expectedStripeUnionpayAmountFromSales: this.expectedStripeUnionpayAmountFromSales,
    
      expectedStripeUnknownAmountFromSales: this.expectedStripeUnknownAmountFromSales,
    
       expectedStripePrepaidAmountFromSales: this.expectedStripePrepaidAmountFromSales,
      note: this.note
    };
  }
}
