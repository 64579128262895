import { APP_INITIALIZER, ErrorHandler, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouteReuseStrategy } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppAvailability } from '@awesome-cordova-plugins/app-availability/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { Clipboard } from '@awesome-cordova-plugins/clipboard/ngx';
// import { Contacts } from '@awesome-cordova-plugins/contacts/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { InAppPurchase2 } from '@awesome-cordova-plugins/in-app-purchase-2/ngx';
import { Intercom } from '@awesome-cordova-plugins/intercom/ngx';
import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
import { Printer } from '@awesome-cordova-plugins/printer/ngx';
import { SafariViewController } from '@awesome-cordova-plugins/safari-view-controller/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { WebIntent } from '@awesome-cordova-plugins/web-intent/ngx';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';

import { LazyLoadImageModule } from 'ng-lazyload-image';
import { UserService } from './services';
import { HttpErrorInterceptor, LoadingInterceptor } from './interceptors';

import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';


import { SharedModule } from './shared.module';

import { IonicStorageModule } from '@ionic/storage-angular'

import { NavComponentModule } from "./components/nav/nav.module";

import { NgChartsModule } from 'ng2-charts';

import { TooltipModule } from 'primeng/tooltip';
import { StripeService } from './services/stripe.service';
import * as Sentry from "@sentry/angular";

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        BrowserAnimationsModule,
        LazyLoadImageModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        SharedModule,
        IonicStorageModule.forRoot(),
        NavComponentModule,
        NgChartsModule,
        TooltipModule
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        StatusBar,
        AppAvailability,
        BarcodeScanner,
        Clipboard,
        File,
        FileOpener,
        InAppBrowser,
        InAppPurchase2,
        Intercom,
        OneSignal,
        Printer,
        SafariViewController,
        SocialSharing,
        WebIntent,
        Keyboard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoadingInterceptor,
            multi: true
        }, {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        }, {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => { },
            deps: [Sentry.TraceService],
            multi: true,
        },
        StripeService
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
