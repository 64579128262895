import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';

import { Client } from 'src/app/models';
import { ENV_CONFIG } from 'src/bin/env.config';
import { ErrorHandlerService } from './error-handler.service';
import { Injectable } from '@angular/core';
import { List } from 'immutable';
import { Observable } from 'rxjs';
import { SalonmonsterHttpClient } from '../services/salonmonster-http-client';
import { UserService } from './user.service';
import { map } from 'rxjs/operators';
import imageCompression from 'browser-image-compression';
import * as dataURLtoBlob from 'blueimp-canvas-to-blob';



export interface BulkEmailRecipient {
  clientID: number;
  email: string;
  name: string;
  phone: string;
}


@Injectable({
  providedIn: 'root'
})
export class BulkEmailService extends SalonmonsterHttpClient {
  imageCompressorOptions: any;

  constructor(
    http: HttpClient,
    userService: UserService,
    protected errorHandlerService: ErrorHandlerService
  ) {
    super(http, userService, errorHandlerService);
    
    this.imageCompressorOptions = {
      maxSizeMB: 0.7
    };
  }

  private buildStylistClientsFilterParams(
    subject: string,
    body: string,
    stylistID: number,
    excludeClients: List<Client>
  ): any {
    return {
      filterType: 'allclients',
      emailSubject: subject,
      emailBody: body,
      stylistID,
      excludeClients: excludeClients.map((client) => client.email).toArray()
    };
  }

  private buildAppointmentFilterParams(
    subject: string,
    body: string,
    stylistID: number,
    beforeAfter: string,
    startDate: string,
    endDate: string,
    excludeClients: List<Client>
  ): any {
    return {
      filterType: 'appointment',
      emailSubject: subject,
      emailBody: body,
      stylistID,
      beforeAfter,
      startDate,
      endDate,
      excludeClients: excludeClients.map((client) => client.email).toArray()
    };
  }

  private buildSpenfFilterParams(
    subject: string,
    body: string,
    stylistID: number,
    type: string,
    startAmount: number,
    endAmount: number,
    excludeClients: List<Client>
  ): any {
    return {
      filterType: 'spent',
      emailSubject: subject,
      emailBody: body,
      stylistID,
      type,
      startAmount,
      endAmount,
      excludeClients: excludeClients.map((client) => client.email).toArray()
    };
  }

  private buildBirthdayFilter(
    subject: string,
    body: string,
    stylistID: number,
    type: string,
    startBirthDate: string,
    endBirthDate: string,
    excludeClients: List<Client>
  ): any {
    return {
      filterType: 'birthday',
      emailSubject: subject,
      emailBody: body,
      stylistID,
      type,
      startBirthDate,
      endBirthDate,
      excludeClients: excludeClients.map((client) => client.email).toArray()
    };
  }

  public buildServiceFilterParams(
    subject: string,
    body: string,
    stylistID: number,
    serviceName: string,
    excludeClients: List<Client>
  ): any {
    return {
      filterType: 'service',
      emailSubject: subject,
      emailBody: body,
      stylistID,
      serviceName,
      excludeClients: excludeClients.map((client) => client.email).toArray()
    };
  }

  public stylistClients(
    subject: string,
    body: string,
    stylistID: number,
    excludeClients: List<Client>
  ): Observable<List<BulkEmailRecipient>> {
    const url = `${ENV_CONFIG.API_ROOT}/bulkemail`;

    return this.post(
      url,
      this.buildStylistClientsFilterParams(
        subject,
        body,
        stylistID,
        excludeClients
      )
    ).pipe(
      map((data) => {
        let recipients: List<BulkEmailRecipient> = List([]);

        for (const recipient of data) {
          recipients = recipients.push({
            clientID: recipient.clientID,
            email: recipient.email,
            name: recipient.name,
            phone: recipient.phone
          });
        }

        return recipients;
      })
    );
  }

  public stylistClientsRecipients(
    subject: string,
    body: string,
    stylistID: number,
    excludeClients: List<Client>
  ): Observable<List<BulkEmailRecipient>> {
    const url = `${ENV_CONFIG.API_ROOT}/bulkemail/recipients`;

    return this.post(
      url,
      this.buildStylistClientsFilterParams(
        subject,
        body,
        stylistID,
        excludeClients
      )
    ).pipe(
      map((data) => {
        let recipients: List<BulkEmailRecipient> = List([]);

        for (const recipient of data) {
          recipients = recipients.push({
            clientID: recipient.clientID,
            email: recipient.email,
            name: recipient.name,
            phone: recipient.phone
          });
        }

        return recipients;
      })
    );
  }

  public stylistClientsRecipientsCount(
    subject: string,
    body: string,
    stylistID: number,
    excludeClients: List<Client>
  ): Observable<number> {
    const url = `${ENV_CONFIG.API_ROOT}/bulkemail/recipients/count`;

    return this.post(
      url,
      this.buildStylistClientsFilterParams(
        subject,
        body,
        stylistID,
        excludeClients
      )
    ).pipe(
      map((data) => {
        return data.count;
      })
    );
  }

  public clientsWhoseAppointments(
    subject: string,
    body: string,
    stylistID: number,
    beforeAfter: string,
    startDate: string,
    endDate: string,
    excludeClients: List<Client>
  ): Observable<List<BulkEmailRecipient>> {
    const url = `${ENV_CONFIG.API_ROOT}/bulkemail`;

    return this.post(
      url,
      this.buildAppointmentFilterParams(
        subject,
        body,
        stylistID,
        beforeAfter,
        startDate,
        endDate,
        excludeClients
      )
    ).pipe(
      map((data) => {
        let recipients: List<BulkEmailRecipient> = List([]);

        for (const recipient of data) {
          recipients = recipients.push({
            clientID: recipient.clientID,
            email: recipient.email,
            name: recipient.name,
            phone: recipient.phone
          });
        }

        return recipients;
      })
    );
  }

  public clientsWhoseAppointmentsRecipients(
    subject: string,
    body: string,
    stylistID: number,
    beforeAfter: string,
    startDate: string,
    endDate: string,
    excludeClients: List<Client>
  ): Observable<List<BulkEmailRecipient>> {
    const url = `${ENV_CONFIG.API_ROOT}/bulkemail/recipients`;

    return this.post(
      url,
      this.buildAppointmentFilterParams(
        subject,
        body,
        stylistID,
        beforeAfter,
        startDate,
        endDate,
        excludeClients
      )
    ).pipe(
      map((data) => {
        let recipients: List<BulkEmailRecipient> = List([]);

        for (const recipient of data) {
          recipients = recipients.push({
            clientID: recipient.clientID,
            email: recipient.email,
            name: recipient.name,
            phone: recipient.phone
          });
        }

        return recipients;
      })
    );
  }

  public clientsWhoseAppointmentsRecipientsCount(
    subject: string,
    body: string,
    stylistID: number,
    beforeAfter: string,
    startDate: string,
    endDate: string,
    excludeClients: List<Client>
  ): Observable<number> {
    const url = `${ENV_CONFIG.API_ROOT}/bulkemail/recipients/count`;

    return this.post(
      url,
      this.buildAppointmentFilterParams(
        subject,
        body,
        stylistID,
        beforeAfter,
        startDate,
        endDate,
        excludeClients
      )
    ).pipe(
      map((data) => {
        return data.count;
      })
    );
  }

  public clientsWhoSpent(
    subject: string,
    body: string,
    stylistID: number,
    type: string,
    startAmount: number,
    endAmount: number,
    excludeClients: List<Client>
  ): Observable<List<BulkEmailRecipient>> {
    const url = `${ENV_CONFIG.API_ROOT}/bulkemail`;

    return this.post(
      url,
      this.buildSpenfFilterParams(
        subject,
        body,
        stylistID,
        type,
        startAmount,
        endAmount,
        excludeClients
      )
    ).pipe(
      map((data) => {
        let recipients: List<BulkEmailRecipient> = List([]);

        for (const recipient of data) {
          recipients = recipients.push({
            clientID: recipient.clientID,
            email: recipient.email,
            name: recipient.name,
            phone: recipient.phone
          });
        }

        return recipients;
      })
    );
  }

  public clientsWhoSpentRecipients(
    subject: string,
    body: string,
    stylistID: number,
    type: string,
    startAmount: number,
    endAmount: number,
    excludeClients: List<Client>
  ): Observable<List<BulkEmailRecipient>> {
    const url = `${ENV_CONFIG.API_ROOT}/bulkemail/recipients`;

    return this.post(
      url,
      this.buildSpenfFilterParams(
        subject,
        body,
        stylistID,
        type,
        startAmount,
        endAmount,
        excludeClients
      )
    ).pipe(
      map((data) => {
        let recipients: List<BulkEmailRecipient> = List([]);

        for (const recipient of data) {
          recipients = recipients.push({
            clientID: recipient.clientID,
            email: recipient.email,
            name: recipient.name,
            phone: recipient.phone
          });
        }

        return recipients;
      })
    );
  }

  public clientsWhoSpentRecipientsCount(
    subject: string,
    body: string,
    stylistID: number,
    type: string,
    startAmount: number,
    endAmount: number,
    excludeClients: List<Client>
  ): Observable<number> {
    const url = `${ENV_CONFIG.API_ROOT}/bulkemail/recipients/count`;

    return this.post(
      url,
      this.buildSpenfFilterParams(
        subject,
        body,
        stylistID,
        type,
        startAmount,
        endAmount,
        excludeClients
      )
    ).pipe(
      map((data) => {
        return data.count;
      })
    );
  }

  public clientsWhoseBirthday(
    subject: string,
    body: string,
    stylistID: number,
    type: string,
    startBirthDate: string,
    endBirthDate: string,
    excludeClients: List<Client>
  ): Observable<List<BulkEmailRecipient>> {
    const url = `${ENV_CONFIG.API_ROOT}/bulkemail`;

    return this.post(
      url,
      this.buildBirthdayFilter(
        subject,
        body,
        stylistID,
        type,
        startBirthDate,
        endBirthDate,
        excludeClients
      )
    ).pipe(
      map((data) => {
        let recipients: List<BulkEmailRecipient> = List([]);

        for (const recipient of data) {
          recipients = recipients.push({
            clientID: recipient.clientID,
            email: recipient.email,
            name: recipient.name,
            phone: recipient.phone
          });
        }

        return recipients;
      })
    );
  }

  public clientsWhoseBirthdayRecipients(
    subject: string,
    body: string,
    stylistID: number,
    type: string,
    startBirthDate: string,
    endBirthDate: string,
    excludeClients: List<Client>
  ): Observable<List<BulkEmailRecipient>> {
    const url = `${ENV_CONFIG.API_ROOT}/bulkemail/recipients`;

    return this.post(
      url,
      this.buildBirthdayFilter(
        subject,
        body,
        stylistID,
        type,
        startBirthDate,
        endBirthDate,
        excludeClients
      )
    ).pipe(
      map((data) => {
        let recipients: List<BulkEmailRecipient> = List([]);

        for (const recipient of data) {
          recipients = recipients.push({
            clientID: recipient.clientID,
            email: recipient.email,
            name: recipient.name,
            phone: recipient.phone
          });
        }

        return recipients;
      })
    );
  }

  public clientsWhoseBirthdayRecipientCount(
    subject: string,
    body: string,
    stylistID: number,
    type: string,
    startBirthDate: string,
    endBirthDate: string,
    excludeClients: List<Client>
  ): Observable<number> {
    const url = `${ENV_CONFIG.API_ROOT}/bulkemail/recipients/count`;

    return this.post(
      url,
      this.buildBirthdayFilter(
        subject,
        body,
        stylistID,
        type,
        startBirthDate,
        endBirthDate,
        excludeClients
      )
    ).pipe(
      map((data) => {
        return data.count;
      })
    );
  }

  public clientsWhoHadService(
    subject: string,
    body: string,
    stylistID: number,
    serviceName: string,
    excludeClients: List<Client>
  ): Observable<List<BulkEmailRecipient>> {
    const url = `${ENV_CONFIG.API_ROOT}/bulkemail`;

    return this.post(
      url,
      this.buildServiceFilterParams(
        subject,
        body,
        stylistID,
        serviceName,
        excludeClients
      )
    ).pipe(
      map((data) => {
        let recipients: List<BulkEmailRecipient> = List([]);

        for (const recipient of data) {
          recipients = recipients.push({
            clientID: recipient.clientID,
            email: recipient.email,
            name: recipient.name,
            phone: recipient.phone
          });
        }

        return recipients;
      })
    );
  }

  public clientsWhoHadServiceRecipients(
    subject: string,
    body: string,
    stylistID: number,
    serviceName: string,
    excludeClients: List<Client>
  ): Observable<List<BulkEmailRecipient>> {
    const url = `${ENV_CONFIG.API_ROOT}/bulkemail/recipients`;

    return this.post(
      url,
      this.buildServiceFilterParams(
        subject,
        body,
        stylistID,
        serviceName,
        excludeClients
      )
    ).pipe(
      map((data) => {
        let recipients: List<BulkEmailRecipient> = List([]);

        for (const recipient of data) {
          recipients = recipients.push({
            clientID: recipient.clientID,
            email: recipient.email,
            name: recipient.name,
            phone: recipient.phone
          });
        }

        return recipients;
      })
    );
  }

  public clientsWhoHadServiceRecipientCount(
    subject: string,
    body: string,
    stylistID: number,
    serviceName: string,
    excludeClients: List<Client>
  ): Observable<number> {
    const url = `${ENV_CONFIG.API_ROOT}/bulkemail/recipients/count`;

    return this.post(
      url,
      this.buildServiceFilterParams(
        subject,
        body,
        stylistID,
        serviceName,
        excludeClients
      )
    ).pipe(
      map((data) => {
        return data.count;
      })
    );
  }

  public sendPreview(
    subject: string,
    body: string,
    email: string
  ): Observable<number> {
    const url = `${ENV_CONFIG.API_ROOT}/bulkemail/preview`;

    return this.post(url, {
      recipientEmail: email,
      emailSubject: subject,
      emailBody: body
    }).pipe(
      map((data) => {
        return data.count;
      })
    );
  }

  public sendToSelectedClients(
    subject: string,
    stylistID: number,
    body: string,
    selectedClients
  ): Observable<number> {
    const url = `${ENV_CONFIG.API_ROOT}/bulkemail/send`;

    return this.post(url, {
      selectedClients: selectedClients,
      stylistID: stylistID,
      emailSubject: subject,
      emailBody: body
    }).pipe(
      map((data) => {
        return data.count;
      })
    );
  }
  uploadImage(image, folder: string) {
    const formData = new FormData();
    const url = 'https://api.cloudinary.com/v1_1/salonmonster/image/upload';
    formData.append('file', image, image.name);
    formData.append('api_key', '859249814555343');
    const timestamp = (Date.now() / 1000) | 0;
    formData.append('timestamp', `${timestamp}`);
    formData.append('upload_preset', 'o3nkmzlf');
    formData.append('folder', folder); // Add the folder parameter
    return this.http.post(url, formData);
  }

  extractBase64AndUpload(html: string, dir: string): Promise<string> {
    const imageList = this.getBase64(html);
  
    const promiseArray: Promise<any>[] = [];
  
    imageList.forEach((image, index) => {
      const blob = dataURLtoBlob(image);
  
      const promise = imageCompression(blob, this.imageCompressorOptions).then(
        (resizedBlob) => {
          return this.uploadImage(resizedBlob, dir).pipe(
            map((data: any) => {
              return { imageUrl: data.secure_url };
            })
          ).toPromise(); // Convert Observable to Promise
        }
      );
      promiseArray.push(promise);
    });
  
    return Promise.all(promiseArray).then((responses: any[]) => {
      let _html = html;
      responses.forEach((response, index) => {
        _html = _html.replace(imageList[index], response.imageUrl);
      });
      return _html;
    });
  }
  private getBase64(html): string[] {
    const frag = document.createElement('div');
    frag.innerHTML = html;

    return Array.from(frag.querySelectorAll('img'))
      .filter((img) => img.getAttribute('src').startsWith('data'))
      .map((img) => img.getAttribute('src'));
  }
}
