import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';

import { ENV_CONFIG } from 'src/bin/env.config';
import { ErrorHandlerService } from './error-handler.service';
import { Injectable } from '@angular/core';
import { List } from 'immutable';
import { Observable } from 'rxjs';
import { ProductSupplier } from 'src/app/models';
import { SalonmonsterHttpClient } from '../services/salonmonster-http-client';
import { UserService } from '../services/user.service';
import { map } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductSupplierService extends SalonmonsterHttpClient {
  constructor(
    http: HttpClient,
    userService: UserService,
    protected errorHandlerService: ErrorHandlerService
  ) {
    super(http, userService, errorHandlerService);
  }

  public loadProductSupplierByID(
    productSupplierID: number
  ): Observable<ProductSupplier> {
    const url = `${ENV_CONFIG.API_ROOT}/productsuppliers/${productSupplierID}`;
    return this.get(url).pipe(
      map((data) => {
        if (data && data.length === 1) {
          return ProductSupplier.parse(data[0]);
        } else {
          throwError(new Error('Invalid Product supplier'));
        }
      })
    );
  }

  public loadProductSuppliers(): Observable<List<ProductSupplier>> {
    const url = `${ENV_CONFIG.API_ROOT}/productsuppliers`;

    return this.get(url).pipe(
      map((data) => {
        let productCategories: List<ProductSupplier> = List([]);

        for (const productData of data) {
          productCategories = productCategories.push(
            ProductSupplier.parse(productData)
          );
        }

        return productCategories;
      })
    );
  }

  public save(productSupplier: ProductSupplier): Observable<ProductSupplier> {
    let url = `${ENV_CONFIG.API_ROOT}/productsuppliers`;
    url +=
      productSupplier.getID() !== undefined
        ? '/' + productSupplier.getID()
        : '';

    if (productSupplier.getID() !== undefined) {
      // update
      return this.put(url, productSupplier.serialize()).pipe(
        map((data) => {
          return ProductSupplier.parse(data);
        })
      );
    } else {
      // create
      return this.post(url, productSupplier.serialize()).pipe(
        map((data) => {
          return ProductSupplier.parse(data);
        })
      );
    }
  }

  public deleteProductSupplier(
    productSupplier: ProductSupplier
  ): Observable<ProductSupplier> {
    const url = `${ENV_CONFIG.API_ROOT}/productsuppliers/${productSupplier.id}`;

    return this.delete(url, {});
  }
}
