import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';

import { ENV_CONFIG } from 'src/bin/env.config';
import { ErrorHandlerService } from './error-handler.service';
import { Injectable } from '@angular/core';
import { List } from 'immutable';
import { SalonmonsterHttpClient } from '../services/salonmonster-http-client';
import { SpecialHour } from 'src/app/models';
import { UserService } from '../services/user.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpecialHourService extends SalonmonsterHttpClient {
  constructor(
    http: HttpClient,
    userService: UserService,
    protected errorHandlerService: ErrorHandlerService
  ) {
    super(http, userService, errorHandlerService);
  }

  public getStylistSpecialHours(
    stylistID: number,
    forceLoad?: boolean
  ): Observable<List<SpecialHour>> {
    const url = `${ENV_CONFIG.API_ROOT}/stylists/${stylistID}/specialhours`;
    return this.get(url).pipe(
      map((data) => {
        let response: List<SpecialHour> = List.of<SpecialHour>();

        for (const item of data) {
          response = response.push(SpecialHour.parseSpecialHour(item));
        }

        return response;
      })
    );
  }

  public saveSpecialHour(specialHour: SpecialHour): Observable<SpecialHour> {
    let url = `${ENV_CONFIG.API_ROOT}/specialhours`;

    if (specialHour.getId()) {
      url += '/' + specialHour.getId();
      return this.put(url, specialHour.serialize());
    } else {
      return this.post(url, specialHour.serialize()).pipe(
        map((data) => {
          return specialHour.setId(data.id);
        })
      );
    }
  }

  public deleteSpecialHour(specialHour: SpecialHour): Observable<SpecialHour> {
    const url = `${ENV_CONFIG.API_ROOT}/specialhours/${specialHour.id}`;
    return this.delete(url, {});
  }
}
