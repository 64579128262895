import { Client } from '../client/client.model';
import { Cloneable } from '../cloneable.model';
import { Discount } from '../discount/discount.model';
import { Lineitem } from '../line-item/line-item.model';
import { List } from 'immutable';
import { Serializable } from '../serializable.model';
import { ServiceDefinition } from '../service-definition/service-definition.model';
import { ServiceJSON } from './service-json.model';
import { ServiceSerialized } from './service-serialized.model';
import { Stylist } from '../stylist/stylist.model';
import { TimeSlotDurationOptions } from '../time-slot-duration-options.model';
import { Utilities } from 'src/app/utilities/utilities';;

export class Service extends Lineitem
  implements Cloneable<Service, ServiceJSON>, Serializable<ServiceSerialized> {
  constructor(options: ServiceJSON) {
    super(options);
    this.id = options.id;
    this.bookingGroupID = options.bookingGroupID;
    this.startDateTime = options.startDateTime;
    this.durations = options.durations;
    this.serviceDefinition = options.serviceDefinition;
    this.serviceOverlaps = options.serviceOverlaps
      ? options.serviceOverlaps
      : List.of<Service>();
    this.clientNotes = options.clientNotes;
    this.stylistNotes = options.stylistNotes;
    this.clientFirstName = options.clientFirstName;
    this.clientLastName = options.clientLastName;
    this.updateDateTimes();
    this.deleted = options.deleted;
  }
  public readonly id: number;

  public readonly serviceDefinition: ServiceDefinition;

  public readonly clientNotes: string;

  public readonly stylistNotes: string;

  public readonly durations: TimeSlotDurationOptions;

  public readonly bookingGroupID: number;

  public readonly serviceOverlaps: List<Service>;

  private startDateTime: Date;

  private splitStartDateTime: Date;

  private splitEndDateTime: Date;

  private endDateTime: Date;

  private isBookback: boolean;

  public readonly clientFirstName: string;

  public readonly clientLastName: string;

  public readonly deleted: boolean;

  public static parseService(service: Object): Service {
    const serviceDefinitionData = service['serviceDefinition'];
    const stylist: Stylist = Stylist.parseStylistData(
      serviceDefinitionData.stylist
    );

    const serviceDefinition: ServiceDefinition = new ServiceDefinition({
      id: serviceDefinitionData.id,
      name: serviceDefinitionData.name,
      duration: service['duration'],
      processDuration: service['processDuration'],
      finishDuration: service['finishDuration'],
      price: service['price'],
      clientBookable: undefined,
      stylist: stylist,
      description: undefined,
      startingAt: undefined,
      parentID: undefined,
      categoryID: undefined,
      serviceCategory: undefined
    });

    return new Service({
      id: service['id'],
      bookingGroupID: service['bookingGroupID'],
      serviceDefinition: serviceDefinition,
      clientNotes: service['clientNotes'],
      stylistNotes: service['stylistNotes'],
      startDateTime: Utilities.parseDate(service['startDateTime']),
      durations: {
        startDuration: service['duration'],
        processDuration: service['processDuration'],
        finishDuration: service['finishDuration']
      },
      price: service['price'],
      lineItemID: undefined,
      clientID: undefined,
      client: undefined,
      type: service['type'],
      name: serviceDefinitionData.name,
      quantity: 1,
      isRefund: 0,
      taxRateType: 0,
      stylist: stylist,
      stylistID: stylist.getID(),
      discount: undefined,
      customTaxes: List.of<string>(),
      isPhantom: false,
      clientFirstName: service['clientFirstName'],
      clientLastName: service['clientLastName'],
      deleted: service['deleted']
    });
  }

  private updateDateTimes(): void {
    if (
      this.durations.processDuration !== undefined &&
      this.durations.processDuration !== null &&
      this.durations.processDuration > 0
    ) {
      this.isBookback = true;

      this.splitStartDateTime = Utilities.addMinutes(
        this.startDateTime,
        this.durations.startDuration
      );
      this.splitEndDateTime = Utilities.addMinutes(
        this.splitStartDateTime,
        this.durations.processDuration
      );
      this.endDateTime = Utilities.addMinutes(
        this.splitEndDateTime,
        this.durations.finishDuration
      );
    } else {
      this.isBookback = false;
      this.splitStartDateTime = undefined;
      this.splitEndDateTime = undefined;
      this.endDateTime = Utilities.addMinutes(
        this.startDateTime,
        this.durations.startDuration
      );
    }
  }

  public getId(): number {
    return this.id;
  }

  public setId(newId: number): Service {
    const data: ServiceJSON = this.toJSON();
    data.id = newId;
    return new Service(data);
  }

  public getBookingGroupID(): number {
    return this.bookingGroupID;
  }

  public setBookingGroupID(bookingGroupID: number): Service {
    const data: ServiceJSON = this.toJSON();
    data.bookingGroupID = bookingGroupID;
    return new Service(data);
  }

  public getServiceDefinition(): ServiceDefinition {
    return this.serviceDefinition;
  }

  public setServiceDefinition(serviceDefinition: any): Service {
    const data: ServiceJSON = this.toJSON();
    data.serviceDefinition = serviceDefinition;
    data.price = serviceDefinition.getPrice();
    data.durations = {
      startDuration: serviceDefinition.getDuration(),
      processDuration: serviceDefinition.getProcessDuration(),
      finishDuration: serviceDefinition.getFinishDuration()
    };
    return new Service(data);
  }

  public getClientNotes(): string {
    return this.clientNotes;
  }

  public setClientNotes(notes: string): Service {
    const data: ServiceJSON = this.toJSON();
    data.clientNotes = notes;
    return new Service(data);
  }

  public hasClientNotes(): boolean {
    let notes: string = this.getClientNotes();

    if (!notes) {
      return false;
    }

    // remove spaces
    notes = notes.split(' ').join('');
    return notes && notes.length > 0;
  }

  public getStylistNotes(): string {
    return this.stylistNotes;
  }

  public setStylistNotes(notes: string): Service {
    const data: ServiceJSON = this.toJSON();
    data.stylistNotes = notes;
    return new Service(data);
  }

  public hasStylistNotes(): boolean {
    let notes: string = this.getStylistNotes();

    if (!notes) {
      return false;
    }

    // remove spaces
    notes = notes.split(' ').join('');
    return notes && notes.length > 0;
  }

  public getDurations(): TimeSlotDurationOptions {
    return this.durations;
  }

  public setDurations(durations: TimeSlotDurationOptions): Service {
    const data: ServiceJSON = this.toJSON();
    data.durations = {
      startDuration: durations.startDuration,
      processDuration: durations.processDuration,
      finishDuration: durations.finishDuration
    };
    return new Service(data);
  }

  public getTotalServiceDuration(): number {
    let totalDuration = this.durations.startDuration;

    if (this.durations.processDuration) {
      totalDuration += this.durations.processDuration;
    }

    if (this.durations.finishDuration) {
      totalDuration += this.durations.finishDuration;
    }

    return totalDuration;
  }

  public getFormattedTotalServiceDuration(): string {
    const totalDurationInMinutes: number = this.getTotalServiceDuration();
    let hour = 0;
    let mins = 0;
    let label = '';

    if (totalDurationInMinutes >= 60) {
      hour = Math.floor(totalDurationInMinutes / 60);
    }

    mins = totalDurationInMinutes % 60;

    if (hour > 0) {
      label += `${hour} hour${hour > 1 ? 's' : ''}`;
    }

    if (mins > 0) {
      label += ` ${mins} mins`;
    }

    return label;
  }

  public getStartDateTime(): Date {
    return this.startDateTime;
  }

  public setStartDateTime(newDate: Date): Service {
    const data: ServiceJSON = this.toJSON();
    data.startDateTime = newDate;
    return new Service(data);
  }

  public getSplitStartDateTime(): Date {
    return this.splitStartDateTime;
  }

  public getSplitEndDateTime(): Date {
    return this.splitEndDateTime;
  }

  public getEndDateTime(): Date {
    return this.endDateTime;
  }

  public isBookBack(): boolean {
    return this.isBookback;
  }

  public getServiceOverlaps(): List<Service> {
    return this.serviceOverlaps;
  }

  public setServiceOverlaps(overlaps: List<Service>): Service {
    const data: ServiceJSON = this.toJSON();
    data.serviceOverlaps = overlaps;
    return new Service(data);
  }

  public setCustomTaxes(customTaxes: List<string>): Service {
    const data: ServiceJSON = this.toJSON();
    data.customTaxes = customTaxes;
    return new Service(data);
  }

  public setPhantom(isPhantom: boolean): Service {
    const data: ServiceJSON = this.toJSON();
    data.isPhantom = isPhantom;
    return new Service(data);
  }

  public setLineItemID(newID: number): Service {
    const data: ServiceJSON = this.toJSON();
    data.lineItemID = newID;
    return new Service(data);
  }

  public setClientID(clientID: number): Service {
    const data: ServiceJSON = this.toJSON();
    data.clientID = clientID;
    return new Service(data);
  }

  public setClient(client: Client): Service {
    const data: ServiceJSON = this.toJSON();
    data.client = client;
    return new Service(data);
  }

  public setType(type: string): Service {
    const data: ServiceJSON = this.toJSON();
    data.type = type;
    return new Service(data);
  }

  public setName(name: string): Service {
    const data: ServiceJSON = this.toJSON();
    data.name = name;
    return new Service(data);
  }

  public setPrice(price: number): Service {
    const data: ServiceJSON = this.toJSON();
    data.price = price;
    return new Service(data);
  }

  public setQuantity(quantity: number): Service {
    const data: ServiceJSON = this.toJSON();
    data.quantity = quantity;
    return new Service(data);
  }

  public setRefund(refund: number): Service {
    const data: ServiceJSON = this.toJSON();
    data.isRefund = refund;
    return new Service(data);
  }

  public setTaxRateType(taxRateType: number): Service {
    const data: ServiceJSON = this.toJSON();
    data.taxRateType = taxRateType;
    return new Service(data);
  }

  public setStylist(stylist: Stylist): Service {
    const data: ServiceJSON = this.toJSON();
    data.stylistID = stylist.id;
    data.stylist = stylist;
    return new Service(data);
  }

  public setDiscount(discount: Discount): Service {
    const data: ServiceJSON = this.toJSON();
    discount = discount.setID(undefined);
    data.discount = discount;
    return new Service(data);
  }

  public removeDiscount(): Service {
    const data: ServiceJSON = this.toJSON();
    data.discount = undefined;
    return new Service(data);
  }

  public toJSON(): ServiceJSON {
    return Object.assign(super.toJSON(), {
      id: this.id,
      bookingGroupID: this.bookingGroupID,
      serviceDefinition: this.getServiceDefinition(),
      clientNotes: this.clientNotes,
      stylistNotes: this.stylistNotes,
      startDateTime: this.startDateTime,
      durations: {
        startDuration: this.getDurations().startDuration,
        processDuration: this.getDurations().processDuration,
        finishDuration: this.getDurations().finishDuration
      },
      isPhantom: this.isPhantom,
      serviceOverlaps: this.serviceOverlaps,
      clientFirstName: this.clientFirstName,
      clientLastName: this.clientLastName,
      deleted: this.deleted
    });
  }

  public clone(): Service {
    return new Service(this.toJSON());
  }

  public serialize(): ServiceSerialized {
    return {
      id: !this.isPhantom ? this.id : undefined,
      serviceDefinition: {
        id: this.getServiceDefinition().getID(),
        stylistID: this.getServiceDefinition().getStylist().getID()
      },
      price: this.getPrice(),
      duration: this.getDurations().startDuration,
      processDuration: this.getDurations().processDuration,
      finishDuration: this.getDurations().finishDuration,
      clientNotes: this.clientNotes,
      stylistNotes: this.stylistNotes
    };
  }
}
