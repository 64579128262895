import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { LoadingOptions } from '@ionic/core';
// import { StateManagerService } from '../shared/state-manager.service';

@Injectable({
  providedIn: 'root'
})
export class LoadingManagerService {
  loading: HTMLIonLoadingElement;

  constructor(
    private loadingCtrl: LoadingController
  ) // private stateManager: StateManagerService
  {}

  initLoadingComponent() {
    this.loading = null;

    const loadingOptions: LoadingOptions = {
      spinner: 'crescent',
      cssClass: 'default-loading-indicator'
    };

    return this.loadingCtrl.create(loadingOptions).then((loading) => {
      this.loading = loading;
    });
  }

  stopLoading() {
    if (!!this.loading) {
      try {
        this.loading.dismiss();
        this.loading = null;
        // this.stateManager.updateState('isLoading', false);
      } catch (e) {
      }
    }
  }

  startLoading() {
    if (!this.loading) {
      // this.stateManager.updateState('isLoading', true);
      this.initLoadingComponent().then(() => {
        this.loading.present();
      });
    }
  }
}
