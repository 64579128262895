import { CurrencyJSON } from './currency-json.model';
import { CurrencyRecord } from './currency-record';
import { Utilities } from 'src/app/utilities/utilities';;

export class Currency extends CurrencyRecord {
  public readonly id: number;
  public readonly symbol: string;

  constructor(params: CurrencyJSON) {
    super(params);
  }

  public static parse(params: Object): Currency {
    return new Currency({
      id: params['id'],
      symbol: Utilities.decodeHTML(params['symbol'])
    });
  }
}
