import { List, fromJS } from 'immutable';

import { Cloneable } from '../cloneable.model';
import { Serializable } from '../serializable.model';
import { ServiceCategoryJSON } from './service-category-json.model';
import { ServiceCategorySerialized } from './service-category-serialized.model';
import { ServiceDefinition } from '../service-definition/service-definition.model';
import { Utilities } from 'src/app/utilities/utilities';

export class ServiceCategory
  implements
    Cloneable<ServiceCategory, ServiceCategoryJSON>,
    Serializable<ServiceCategorySerialized> {
  public readonly id: number;

  public readonly categoryName: string;

  public readonly serviceDefinitions: List<ServiceDefinition>;

  constructor(options: ServiceCategoryJSON) {
    this.id = options.id;
    this.categoryName = options.categoryName;
    this.serviceDefinitions = options.serviceDefinitions;
  }

  public static parseData(data: Object): ServiceCategory {
    const serviceDefinitions: Array<ServiceDefinition> = [];
    if (data['serviceDetails']) {
      for (const serviceDefinition of data['serviceDetails']) {
        serviceDefinitions.push(
          ServiceDefinition.parseServiceDefinition(serviceDefinition)
        );
      }
    }
    
    return new ServiceCategory({
      id: data['id'],
      categoryName: data['categoryName'],
      serviceDefinitions:  Utilities.convertToList(serviceDefinitions)
    });
  }

  public clone(): ServiceCategory {
    return new ServiceCategory(this.toJSON());
  }

  public toJSON(): ServiceCategoryJSON {
    return {
      id: this.getId(),
      categoryName: this.getCategoryName(),
      serviceDefinitions: this.serviceDefinitions
    };
  }

  public serialize(): ServiceCategorySerialized {
    return {
      id: this.getId(),
      categoryName: this.getCategoryName()
    };
  }

  public getId(): number {
    return this.id;
  }

  public setId(id: number): ServiceCategory {
    const data: ServiceCategoryJSON = this.toJSON();
    data.id = id;
    return new ServiceCategory(data);
  }

  public getCategoryName(): string {
    return this.categoryName;
  }

  public setCategoryName(name: string): ServiceCategory {
    const data: ServiceCategoryJSON = this.toJSON();
    data.categoryName = name;
    return new ServiceCategory(data);
  }

  public getServiceDefinitions(): List<ServiceDefinition> {
    return this.serviceDefinitions;
  }

  public setServiceDefinitions(
    serviceDefinitions: List<ServiceDefinition>
  ): ServiceCategory {
    const data: ServiceCategoryJSON = this.toJSON();
    data.serviceDefinitions = serviceDefinitions;
    return new ServiceCategory(data);
  }
}
