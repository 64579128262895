import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';

import { ENV_CONFIG } from 'src/bin/env.config';
import { ErrorHandlerService } from './error-handler.service';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { SalonmonsterHttpClient } from '../services/salonmonster-http-client';
import { UserService } from '../services/user.service';
import { map, tap } from 'rxjs/operators';
import { OnBoarding } from '../models';

@Injectable({
  providedIn: 'root'
})
export class OnBoardingService extends SalonmonsterHttpClient {
  public onBoarding: OnBoarding;

  public TOTAL_STEPS = 6;

  private onboardingFinishedMonitor = new BehaviorSubject<boolean>(false);

  public readonly onboardingFinishedMonitor$ = this.onboardingFinishedMonitor.asObservable();

  constructor(
    protected http: HttpClient,
    protected errorHandlerService: ErrorHandlerService,
    protected userService: UserService
  ) {
    super(http, userService, errorHandlerService);

    this.userService.getLogOutMonitor().subscribe((logout: boolean) => {
      if (logout) {
        // reset the cache
        this.onBoarding = undefined;
      }
    });
  }

  public getStylistOnBoarding(forceLoad?: boolean): Observable<OnBoarding> {
    if (!forceLoad && this.onBoarding) {
      return of(this.onBoarding);
    }

    const url = `${ENV_CONFIG.API_ROOT}/onboarding`;
    return this.get(url).pipe(
      map((data) => {
        return OnBoarding.parse(data);
      }),
      tap((onboarding: OnBoarding) => {
        this.onBoarding = onboarding;
      })
    );
  }

  public save(onBoarding: OnBoarding): Observable<OnBoarding> {
    const url = `${ENV_CONFIG.API_ROOT}/onboarding`;
    return this.put(url, onBoarding.serialize()).pipe(
      map((data) => {
        return OnBoarding.parse(data);
      }),
      tap((onboarding: OnBoarding) => {
        // update the cache
        this.onBoarding = onboarding;

        if (
          this.checkIfOnboardingHasbeenCompleted() &&
          this.onBoarding.onboarding === 0
        ) {
          this.onboardingFinishedMonitor.next(true);
        }
      })
    );
  }

  public getCompletedStepsCount(): number {
    if (!this.onBoarding) {
      return 0;
    }

    let numberOfStepsCompleted = 0;

    if (this.isPersonalDetailsOnboardingDone()) {
      numberOfStepsCompleted++;
    }

    if (this.isBusinessDetailsOnboardingDone()) {
      numberOfStepsCompleted++;
    }

    if (this.isHoursOnboardingDone()) {
      numberOfStepsCompleted++;
    }

    if (this.isHoursOnboardingDone()) {
      numberOfStepsCompleted++;
    }

    if (this.isImportClientsOnboardingDone()) {
      numberOfStepsCompleted++;
    }

    if (this.isShareOnlineBookingPageOnboardingDone()) {
      numberOfStepsCompleted++;
    }

    return numberOfStepsCompleted;
  }

  public getUncompletedStepsCount(): number {
    if (!this.onBoarding || this.checkIfOnboardingHasbeenCompleted()) {
      return 0;
    }

    let count = this.TOTAL_STEPS - this.getCompletedStepsCount();

    if (count < 0) {
      count = 0;
    }

    return count;
  }

  public checkIfOnboardingHasbeenCompleted(): boolean {
    if (!this.onBoarding) {
      return false;
    }

    return (
      this.onBoarding.onboarding === 1 ||
      this.getCompletedStepsCount() === this.TOTAL_STEPS
    );
  }

  public setOnboarding(onboarding: OnBoarding) {
    this.onBoarding = onboarding;
  }

  public isPersonalDetailsOnboardingDone(): boolean {
    return this.onBoarding && this.onBoarding.personalDetails === 1;
  }

  public isBusinessDetailsOnboardingDone(): boolean {
    return this.onBoarding && this.onBoarding.businessDetails === 1;
  }

  public isHoursOnboardingDone(): boolean {
    return this.onBoarding && this.onBoarding.hours === 1;
  }

  public isServicesOnboardingDone(): boolean {
    return this.onBoarding && this.onBoarding.services === 1;
  }

  public isImportClientsOnboardingDone(): boolean {
    return this.onBoarding && this.onBoarding.importClients === 1;
  }

  public isShareOnlineBookingPageOnboardingDone(): boolean {
    return this.onBoarding && this.onBoarding.shareOnlineBookingPage === 1;
  }

  public getUncompletedOnboardingUnderSettings(): number {
    let count = 0;

    if (!this.onBoarding || this.checkIfOnboardingHasbeenCompleted()) {
      return 0;
    }

    if (!this.isPersonalDetailsOnboardingDone()) {
      count++;
    }

    if (!this.isBusinessDetailsOnboardingDone()) {
      count++;
    }

    if (!this.isHoursOnboardingDone()) {
      count++;
    }

    if (!this.isServicesOnboardingDone()) {
      count++;
    }

    return count;
  }
}
