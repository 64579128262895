// @ts-ignore
import { List } from 'immutable';
import * as moment from 'moment';
import { Client } from '../models';
import { AlertController } from '@ionic/angular';
// export interface AlertBox {
//   title?: string;
//   subTitle?: string;
//   message: string;
//   buttons: Array<String>;
// }

export interface DateRange {
  start: Date;
  end: Date;
}

export interface ErrorMsg {
  message: string;
  buttons: string[];
}

export class Utilities {
  constructor() { }

  public static colorSpectrum: string[] = [
    '#746198',
    '#4781A7',
    '#589A5D',
    '#B3AF38',
    '#B27D58',
    '#A75051'
  ];
  public static colorSpectrumRGB: number[][] = [
    [74, 69, 106],
    [107, 140, 166],
    [132, 187, 148],
    [230, 221, 188],
    [202, 168, 125],
    [181, 100, 112],
    [131, 161, 77],
    [181, 100, 112],
    [134, 53, 213],
    [249, 134, 23],
    [45, 197, 116],
    [161, 28, 102],
    [23, 124, 153],
    [79, 83, 132],
    [164, 63, 69],
    [84, 45, 72],
    [145, 95, 86]
  ];

  public static parseDate(date: string): Date {
    const parsedDate = moment(date, 'Y-MM-DD HH:mm:ss');
    return parsedDate.isValid() ? parsedDate.toDate() : null;
  }

  public static unixToDate(unix: number): Date {
    return moment.unix(unix).toDate();
  }

  public static cloneDate(date: Date): Date {
    return moment(date).toDate();
  }

  public static addDays(date: Date, days: number): Date {
    return moment(date).add(days, 'days').toDate();
  }

  public static subtractDays(date: Date, days: number): Date {
    return moment(date).subtract(days, 'days').toDate();
  }

  public static addMinutes(date: Date, mins: number): Date {
    return moment(date).add(mins, 'minutes').toDate();
  }

  public static formatDate(
    date: Date,
    format: string = 'Y-MM-DD HH:mm:ss'
  ): string {
    return moment(date).format(format);
  }

  public static isDateValid(date: Date): boolean {
    return moment(date).isValid();
  }

  public static compare(dateTimeA: Date, dateTimeB: Date): number {
    const momentA = moment(dateTimeA);
    const momentB = moment(dateTimeB);
    if (momentA > momentB) {
      return 1;
    } else if (momentA < momentB) {
      return -1;
    } else {
      return 0;
    }
  }

  public static isBefore(dateTimeA: Date, dateTimeB: Date): boolean {
    return this.compare(dateTimeA, dateTimeB) === -1;
  }

  public static isEqual(dateTimeA: Date, dateTimeB: Date): boolean {
    return this.compare(dateTimeA, dateTimeB) === 0;
  }

  public static isAfter(dateTimeA: Date, dateTimeB: Date): boolean {
    return this.compare(dateTimeA, dateTimeB) === 1;
  }

  public static roundTo2Decimal(num: number): number {
    return Math.round(num * Math.pow(10, 2)) / Math.pow(10, 2);
  }

  public static roundTo3Decimal(num: number): number {
    return Math.round(num * Math.pow(10, 3)) / Math.pow(10, 3);
  }

  public static startOfTheDay(date: Date): Date {
    return moment(date).hours(0).minutes(0).seconds(0).milliseconds(0).toDate();
  }

  public static endOfTheDay(date: Date): Date {
    return moment(date)
      .hours(23)
      .minutes(59)
      .seconds(59)
      .milliseconds(59)
      .toDate();
  }

  public static timeDiffInYears(date1: Date, date2: Date): number {
    const d1 = moment(date1);
    const d2 = moment(date2);
    const ms = d2.diff(d1);
    return moment.duration(ms).asYears();
  }

  public static timeDiffInMonths(date1: Date, date2: Date): number {
    const d1 = moment(date1);
    const d2 = moment(date2);
    const ms = d2.diff(d1);
    return moment.duration(ms).asMonths();
  }

  public static timeDiffInHours(date1: Date, date2: Date): number {
    const d1 = moment(date1);
    const d2 = moment(date2);
    const ms = d2.diff(d1);
    return moment.duration(ms).asHours();
  }

  public static timeDiffInMinutes(date1: Date, date2: Date): number {
    const d1 = moment(date1);
    const d2 = moment(date2);
    const ms = d2.diff(d1);
    return moment.duration(ms).asMinutes();
  }

  public static daysDiff(date1: Date, date2: Date): number {
    const d1 = moment(date1);
    const d2 = moment(date2);
    return d2.diff(d1, 'days');
  }

  public static areDatesInSameMonth(date1: Date, date2: Date): boolean {
    return date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();
  }

  public static getThisWeekRange(): DateRange {
    let startDate: Date;
    let endDate: Date;
    const days: List<string> = List.of(
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    );
    let index = 0;
    const currentDate = new Date();

    days.some((day) => {
      const d = Utilities.formatDate(currentDate, 'dddd');

      if (d === day) {
        return true;
      }

      index++;

      return false;
    });

    startDate = Utilities.subtractDays(Utilities.cloneDate(currentDate), index);
    endDate = Utilities.addDays(Utilities.cloneDate(startDate), 6);
    return {
      start: this.startOfTheDay(startDate),
      end: this.endOfTheDay(endDate)
    };
  }

  public static getLastWeekRange(): DateRange {
    const range: DateRange = this.getThisWeekRange();
    return {
      start: this.startOfTheDay(Utilities.subtractDays(range.start, 7)),
      end: this.endOfTheDay(Utilities.subtractDays(range.end, 7))
    };
  }

  public static getThisMonthRange(): DateRange {
    const today: Date = new Date();
    const day1: Date = moment(today).startOf('month').toDate();
    const day2: Date = moment(day1).endOf('month').toDate();
    return { start: this.startOfTheDay(day1), end: this.endOfTheDay(day2) };
  }

  public static getLastMonthRange(): DateRange {
    const range: DateRange = this.getThisMonthRange();
    const day1 = moment(Utilities.subtractDays(range.start, 1))
      .startOf('month')
      .toDate();
    const day2 = moment(day1).endOf('month').toDate();
    return { start: this.startOfTheDay(day1), end: this.endOfTheDay(day2) };
  }

  public static getThisYearRange(): DateRange {
    const today: Date = new Date();
    const day1 = moment(today).startOf('year').toDate();
    const day2 = moment(day1).endOf('year').toDate();
    return { start: this.startOfTheDay(day1), end: this.endOfTheDay(day2) };
  }

  public static getLastYearRange(): DateRange {
    const range: DateRange = this.getThisYearRange();
    const day1 = moment(Utilities.subtractDays(range.start, 1))
      .startOf('year')
      .toDate();
    const day2 = moment(day1).endOf('year').toDate();
    return { start: this.startOfTheDay(day1), end: this.endOfTheDay(day2) };
  }

  public static isNumber(value: any): boolean {
    return typeof value === 'number';
  }

  public static isString(value: any): boolean {
    return typeof value === 'string';
  }

  public static isArray(value: any): boolean {
    return value.constructor === Array;
  }

  public static isObject(value: any): boolean {
    return value !== null && typeof value === 'object';
  }

  public static isStringAValidDate(value: any): boolean {
    return this.parseDate(value) !== null;
  }

  public static getDefaultErrorMessageConfig() {
    return {
      message: `Oops! An error occured, if this problem persists please contact our <a href="http://support.salonmonster.com">support</a>.`,
      buttons: ['OK']
    };
  }
  public static wrongPasswordMessageConfig() {
    return {
      message: `Please enter a valid email address.`,
      buttons: ['OK']
    };
  }

  public static clientHasContactDetails(client: Client): boolean {
    if (
      (client.getEmail() !== '' &&
        client.getEmail() !== undefined &&
        client.getEmail() !== null) ||
      (client.getPhone1() !== '' &&
        client.getPhone1() !== undefined &&
        client.getPhone1() !== null) ||
      (client.getPhone2() !== '' &&
        client.getPhone2() !== undefined &&
        client.getPhone2() !== null)
    ) {
      return true;
    } else {
      return false;
    }
  }

  public static clientHasLocationData(client: Client): boolean {
    if (
      (client.getAddress1() !== '' &&
        client.getAddress1() !== undefined &&
        client.getAddress1() !== null) ||
      (client.getAddress2() !== '' &&
        client.getAddress2() !== undefined &&
        client.getAddress2() !== null) ||
      (client.getCity() !== '' &&
        client.getCity() !== undefined &&
        client.getCity() !== null) ||
      (client.getProvince() !== '' &&
        client.getProvince() !== undefined &&
        client.getProvince() !== null) ||
      (client.getPostal() !== '' &&
        client.getPostal() !== undefined &&
        client.getPostal() !== null) ||
      (client.getCountry() !== undefined &&
        client.getCountry().getCountryName() !== '' &&
        client.getCountry().getCountryName() !== undefined &&
        client.getCountry().getCountryName() !== null)
    ) {
      return true;
    } else {
      return false;
    }
  }

  public static getBookingStatuses(): {
    [id: string]: { name: string; class: string };
  } {
    return {
      2: {
        name: 'Requested',
        class: 'smr-booking-status-requested'
      },
      3: {
        name: 'Booked',
        class: 'smr-booking-status-unconfirmed'
      },
      6: {
        name: 'Cancelled',
        class: 'smr-booking-status-cancelled'
      },

      8: {
        name: 'Paid',
        class: 'smr-booking-status-paid'
      },

      9: {
        name: 'Reminded by Phone',
        class: 'smr-booking-status-reminded'
      },
      10: {
        name: 'Reminded by Email',
        class: 'smr-booking-status-reminded'
      },
      11: {
        name: 'Reminded by Text',
        class: 'smr-booking-status-reminded'
      },
      12: {
        name: 'Reminded by Email & Text',
        class: 'smr-booking-status-reminded'
      },

      4: {
        name: 'Arrived',
        class: 'smr-booking-status-arrived'
      },
      5: {
        name: 'No Show',
        class: 'smr-booking-status-no-show'
      }
    };
  }

  public static getTimeZones(): List<{ value: string; text: string }> {
    return List.of(
      { value: 'America/Los_Angeles', text: 'Pacific Time (US & Canada)' },
      { value: 'America/Denver', text: 'Mountain Time (US & Canada)' },
      { value: 'America/Chicago', text: 'Central Time (US & Canada)' },
      { value: 'America/New_York', text: 'Eastern Time (US & Canada)' },
      { value: 'Etc/GMT+10', text: 'Hawaii' },
      { value: 'America/Anchorage', text: 'Alaska' },
      { value: 'America/Dawson_Creek', text: 'Arizona' },
      { value: 'Pacific/Midway', text: 'Midway Island, Samoa' },
      { value: 'America/Adak', text: 'Hawaii-Aleutian' },
      { value: 'Etc/GMT+10', text: 'Hawaii' },
      { value: 'Pacific/Marquesas', text: 'Marquesas Islands' },
      { value: 'Pacific/Gambier', text: 'Gambier Islands' },
      { value: 'America/Anchorage', text: 'Alaska' },
      { value: 'America/Ensenada', text: 'Tijuana, Baja California' },
      { value: 'Etc/GMT+8', text: 'Pitcairn Islands' },
      { value: 'America/Chihuahua', text: 'Chihuahua, La Paz, Mazatlan' },
      { value: 'America/Dawson_Creek', text: 'Arizona' },
      { value: 'America/Belize', text: 'Saskatchewan, Central America' },
      { value: 'America/Cancun', text: 'Guadalajara, Mexico City, Monterrey' },
      { value: 'Chile/EasterIsland', text: 'Easter Island' },
      { value: 'America/Chicago', text: 'Central Time (US & Canada)' },
      { value: 'America/New_York', text: 'Eastern Time (US & Canada)' },
      { value: 'America/Havana', text: 'Cuba' },
      { value: 'America/Bogota', text: 'Bogota, Lima, Quito, Rio Branco' },
      { value: 'America/Caracas', text: 'Caracas' },
      { value: 'America/Santiago', text: 'Santiago' },
      { value: 'America/La_Paz', text: 'La Paz' },
      { value: 'Atlantic/Stanley', text: 'Faukland Islands' },
      { value: 'America/Campo_Grande', text: 'Brazil' },
      { value: 'America/Goose_Bay', text: 'Atlantic Time (Goose Bay)' },
      { value: 'America/Glace_Bay', text: 'Atlantic Time (Canada)' },
      { value: 'America/St_Johns', text: 'Newfoundland' },
      { value: 'America/Araguaina', text: 'UTC-3' },
      { value: 'America/Montevideo', text: 'Montevideo' },
      { value: 'America/Miquelon', text: 'Miquelon, St. Pierre' },
      { value: 'America/Godthab', text: 'Greenland' },
      { value: 'America/Argentina/Buenos_Aires', text: 'Buenos Aires' },
      { value: 'America/Sao_Paulo', text: 'Brasilia' },
      { value: 'America/Noronha', text: 'Mid-Atlantic' },
      { value: 'Atlantic/Cape_Verde', text: 'Cape Verde Is.' },
      { value: 'Atlantic/Azores', text: 'Azores' },
      { value: 'Europe/Belfast', text: 'Greenwich Mean Time : Belfast' },
      { value: 'Europe/Dublin', text: 'Greenwich Mean Time : Dublin' },
      { value: 'Europe/Lisbon', text: 'Greenwich Mean Time : Lisbon' },
      { value: 'Europe/London', text: 'Greenwich Mean Time : London' },
      { value: 'Africa/Abidjan', text: 'Monrovia, Reykjavik' },
      {
        value: 'Europe/Amsterdam',
        text: 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna'
      },
      {
        value: 'Europe/Belgrade',
        text: 'Belgrade, Bratislava, Budapest, Ljubljana, Prague'
      },
      { value: 'Europe/Brussels', text: 'Brussels, Copenhagen, Madrid, Paris' },
      { value: 'Africa/Algiers', text: 'West Central Africa' },
      { value: 'Africa/Windhoek', text: 'Windhoek' },
      { value: 'Asia/Beirut', text: 'Beirut' },
      { value: 'Africa/Cairo', text: 'Cairo' },
      { value: 'Asia/Gaza', text: 'Gaza' },
      { value: 'Africa/Blantyre', text: 'Harare, Pretoria' },
      { value: 'Asia/Jerusalem', text: 'Jerusalem' },
      { value: 'Europe/Minsk', text: 'Minsk' },
      { value: 'Asia/Damascus', text: 'Syria' },
      { value: 'Europe/Moscow', text: 'Moscow, St. Petersburg, Volgograd' },
      { value: 'Africa/Addis_Ababa', text: 'Nairobi' },
      { value: 'Asia/Tehran', text: 'Tehran' },
      { value: 'Asia/Dubai', text: 'Abu Dhabi, Muscat' },
      { value: 'Asia/Yerevan', text: 'Yerevan' },
      { value: 'Asia/Kabul', text: 'Kabul' },
      { value: 'Asia/Yekaterinburg', text: 'Ekaterinburg' },
      { value: 'Asia/Tashkent', text: 'Tashkent' },
      { value: 'Asia/Kolkata', text: 'Chennai, Kolkata, Mumbai, New Delhi' },
      { value: 'Asia/Katmandu', text: 'Kathmandu' },
      { value: 'Asia/Dhaka', text: 'Astana, Dhaka' },
      { value: 'Asia/Novosibirsk', text: 'Novosibirsk' },
      { value: 'Asia/Rangoon', text: 'Yangon (Rangoon)' },
      { value: 'Asia/Bangkok', text: 'Bangkok, Hanoi, Jakarta' },
      { value: 'Asia/Krasnoyarsk', text: 'Krasnoyarsk' },
      {
        value: 'Asia/Hong_Kong',
        text: 'Beijing, Chongqing, Hong Kong, Urumqi'
      },
      { value: 'Asia/Irkutsk', text: 'Irkutsk, Ulaan Bataar' },
      { value: 'Australia/Perth', text: 'Perth' },
      { value: 'Australia/Eucla', text: 'Eucla' },
      { value: 'Asia/Tokyo', text: 'Osaka, Sapporo, Tokyo' },
      { value: 'Asia/Seoul', text: 'Seoul' },
      { value: 'Asia/Yakutsk', text: 'Yakutsk' },
      { value: 'Australia/Adelaide', text: 'Adelaide' },
      { value: 'Australia/Darwin', text: 'Darwin' },
      { value: 'Australia/Brisbane', text: 'Brisbane' },
      { value: 'Australia/Hobart', text: 'Hobart' },
      { value: 'Asia/Vladivostok', text: 'Vladivostok' },
      { value: 'Australia/Lord_Howe', text: 'Lord Howe Island' },
      { value: 'Etc/GMT-11', text: 'Solomon Is., New Caledonia' },
      { value: 'Asia/Magadan', text: 'Magadan' },
      { value: 'Pacific/Norfolk', text: 'Norfolk Island' },
      { value: 'Asia/Anadyr', text: 'Anadyr, Kamchatka' },
      { value: 'Pacific/Auckland', text: 'Auckland, Wellington' },
      { value: 'Etc/GMT-12', text: 'Fiji, Kamchatka, Marshall Is.' },
      { value: 'Pacific/Chatham', text: 'Chatham Islands' },
      { value: 'Pacific/Tongatapu', text: 'Nuku alofa' },
      { value: 'Pacific/Kiritimati', text: 'Kiritimati' }
    );
  }

  public static decodeHTML(str) {
    const map = { gt: '>' /* , … */ };
    return str.replace(/&(#(?:x[0-9a-f]+|\d+)|[a-z]+);?/gi, ($0, $1) => {
      if ($1[0] === '#') {
        return String.fromCharCode(
          $1[1].toLowerCase() === 'x'
            ? parseInt($1.substr(2), 16)
            : parseInt($1.substr(1), 10)
        );
      } else {
        return map.hasOwnProperty($1) ? map[$1] : $0;
      }
    });
  }

  public static pennyRounding(amount: number): number {
    // https://stackoverflow.com/questions/27368213/adding-decimal-string-and-rounding-to-the-nearest-05
    return parseFloat((Math.round(amount * 20) / 20).toFixed(2));
  }

  public static toCents(amount: number): number {
    return Math.round(amount * 100);
  }

  public static isHTM5LocalStorageSupported(): boolean {
    return (window as any).localStorage ? true : false;
  }

  public static extractNumberFromString(value: any): number {
    if (typeof value === 'string') {
      if (!value) {
        return 0;
      }

      // // remove currentcy if exists
      // if(value.includes("$")){
      //   value = value.replace("$","");
      // }

      if (value.includes('-')) {
        return -Utilities.roundTo2Decimal(
          parseFloat(value.replace(/[^0-9.]/g, ''))
        );
      } else {
        return Utilities.roundTo2Decimal(
          parseFloat(value.replace(/[^0-9.]/g, ''))
        );
      }
    }

    return value;
  }

  public static decodeHtml(html: string) {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  }

  public static getPhotoType(photoType: number): string {
    if (photoType === 1) {
      return `Client submitted`;
    } else if (photoType === 2) {
      return `Before`;
    } else if (photoType === 3) {
      return `After`;
    } else {
      return ``;
    }
  }
  public static returnRand(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  public static convertToList(object) {

    if (typeof object.map === 'function') {
      return object.map((value) => {
        //Todo: need more test on this condition
        // if (List.isList(value) && value.every((val) => ['string', 'number'].includes(typeof val))) {
        // return value.join()
        return value;
      });
    }
    return object;
  }
}
