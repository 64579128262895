import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {
  constructor() {} // private userService: UserService

  public handleError(error: HttpErrorResponse): any {
    if (!error) {
      return error;
    }

    if (error.status === 401) {
      // this.userService.logout();
    }

    if (error) {
      const { message, status } = error;
      const _error = { message, status };
      return _error;
    } else {
      return error;
    }
  }
}
