import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';

import { ENV_CONFIG } from 'src/bin/env.config';
import { ErrorHandlerService } from './error-handler.service';
import { Injectable } from '@angular/core';
import { List } from 'immutable';
import { Observable } from 'rxjs';
import { ProductBrand } from 'src/app/models';
import { SalonmonsterHttpClient } from '../services/salonmonster-http-client';
import { UserService } from '../services/user.service';
import { map } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductBrandService extends SalonmonsterHttpClient {
  constructor(
    http: HttpClient,
    userService: UserService,
    protected errorHandlerService: ErrorHandlerService
  ) {
    super(http, userService, errorHandlerService);
  }

  public loadProductBrandByID(
    productBrandID: number
  ): Observable<ProductBrand> {
    const url = `${ENV_CONFIG.API_ROOT}/productbrands/${productBrandID}`;
    return this.get(url).pipe(
      map((data) => {
        if (data && data.length === 1) {
          return ProductBrand.parse(data[0]);
        } else {
          throwError(new Error('Invalid Product category'));
        }
      })
    );
  }

  public loadProductBrands(): Observable<List<ProductBrand>> {
    const url = `${ENV_CONFIG.API_ROOT}/productbrands`;

    return this.get(url).pipe(
      map((data) => {
        let productCategories: List<ProductBrand> = List([]);

        for (const productData of data) {
          productCategories = productCategories.push(
            ProductBrand.parse(productData)
          );
        }

        return productCategories;
      })
    );
  }

  public save(productBrand: ProductBrand): Observable<ProductBrand> {
    let url = `${ENV_CONFIG.API_ROOT}/productbrands`;
    url += productBrand.getID() !== undefined ? '/' + productBrand.getID() : '';

    if (productBrand.getID() !== undefined) {
      // update
      return this.put(url, productBrand.serialize()).pipe(
        map((data) => {
          return ProductBrand.parse(data);
        })
      );
    } else {
      // create
      return this.post(url, productBrand.serialize()).pipe(
        map((data) => {
          return ProductBrand.parse(data);
        })
      );
    }
  }

  public deleteProductBrand(
    productBrand: ProductBrand
  ): Observable<ProductBrand> {
    const url = `${ENV_CONFIG.API_ROOT}/productbrands/${productBrand.id}`;

    return this.delete(url, {});
  }
}
