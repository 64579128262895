import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';

import { Booking } from '../models';
import { BookingService } from './booking.service';
import { ErrorHandlerService } from './error-handler.service';
import { Injectable } from '@angular/core';
import { List } from 'immutable';
import { Observable, BehaviorSubject } from 'rxjs';
import { SalonService } from './salon.service';
import { SalonmonsterHttpClient } from '../services/salonmonster-http-client';
import { StylistService } from './stylist.service';
import { UserService } from './user.service';
import { Utilities } from '../utilities/utilities';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BackgroundProcessService extends SalonmonsterHttpClient {
  private bookingRequestMonitor = new BehaviorSubject<number>(0);

  private bookingWaitListMonitor = new BehaviorSubject<number>(0);

  public readonly bookingRequestMonitor$ = this.bookingRequestMonitor.asObservable();

  public readonly bookingWaitListMonitor$ = this.bookingWaitListMonitor.asObservable();

  public init: boolean;

  private _muteRefreshSession: boolean;

  private bookingRequestCount = 0;

  private bookingWaitListCount = 0;

  constructor(
    http: HttpClient,
    public userService: UserService,
    private stylistService: StylistService,
    private salonService: SalonService,
    private bookingService: BookingService,
    protected errorHandlerService: ErrorHandlerService
  ) {
    super(http, userService, errorHandlerService);

    this.userService.getLogInMonitor().subscribe((login) => {
      if (!this.init) {
        this.init = true;
        return;
      }

      if (!login) {
        return true;
      }

      this.refreshSession().subscribe(() => {});

      this.refreshBookingRequest();
      this.refreshBookingWaitlist();
    });

    this.userService.getLogOutMonitor().subscribe((logout) => {
      if (logout) {
        this.bookingRequestMonitor.next(0);
        this.bookingWaitListMonitor.next(0);
      }
    });

    this.startScript();
  }

  private startScript() {
    // refresh  session every 15 mins
    this.refreshSession().subscribe(() => {});
    setInterval(() => {
      this.refreshSession().subscribe(() => {});
    }, 1000 * (60 * 15));
    // }, 1000 * (5));

    // refresh every 30 mins
    this.refreshBookingRequest();
    this.refreshBookingWaitlist();
    setInterval(() => {
      this.refreshBookingRequest();
      this.refreshBookingWaitlist();
    }, 1000 * (60 * 30));
  }

  public refreshSession(): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      if (this._muteRefreshSession || !this.userService.isLoggedIn()) {
        observer.next(false);
        observer.complete();
        return;
      }

      if (this.userService.isLoggedIn()) {
        this.stylistService
          .loadStylistInfo(this.userService.getUser().getID())
          .subscribe({
            next: (stylist) => {
              this.userService.setUser(stylist);

              // check if this user is still acive
              if (!this.userService.getUser().isActive()) {
                this.userService.logout();
                return;
              }

              this.salonService.load().subscribe({
                next: (salon) => {
                  this.userService.setSalon(salon);
                  this.userService.sessionMonitor.next({
                    user: this.userService.getUser(),
                    salon: this.userService.getSalon(),
                    token: this.userService.getToken()
                  });

                  observer.next(true);
                  observer.complete();
                },
                error: (err) => {
                  if (err.status === 401 || err.status === 404) {
                    this.userService.logout();
                    return;
                  }
                  observer.next(false);
                  observer.complete();
                }}
              );
            },
            error: (err) => {
              if (err.status === 401 || err.status === 404) {
                this.userService.logout();
                return;
              }

              observer.next(false);
              observer.complete();
            }}
          );
      } else {
        // observer.error(new Error('User is not logged in'));
        // observer.complete();
        observer.next(false);
        observer.complete();
      }
    });
  }

  public refreshBookingRequest() {
    if (!this.userService.isLoggedIn()) {
      return;
    }

    this.bookingService
      .loadBookingRequests(this.userService.getUser().getID())
      .subscribe(
        (bookingRequests) => {
          this.bookingRequestCount = bookingRequests.count();
          this.bookingRequestMonitor.next(this.bookingRequestCount);
        }
      );
  }

  public refreshBookingWaitlist() {
    if (!this.userService.isLoggedIn()) {
      return;
    }

    this.bookingService
      .loadWaitListBookings(this.userService.getUser().getID())
      .subscribe(
        (bookingWaitList) => {
          this.bookingWaitListCount = bookingWaitList.count();
          this.bookingWaitListMonitor.next(this.bookingWaitListCount);
        }
      );
  }

  public muteRefreshSession() {
    this._muteRefreshSession = true;
  }

  public unMuteRefreshSession() {
    this._muteRefreshSession = false;
  }

  public updateBookingRequestCount(delta: number) {
    this.bookingRequestCount += delta;
    this.bookingRequestCount =
      this.bookingRequestCount >= 0 ? this.bookingRequestCount : 0;
    this.bookingRequestMonitor.next(this.bookingRequestCount);
  }

  public updateBookingWaitListCount(delta: number) {
    this.bookingWaitListCount += delta;
    this.bookingWaitListCount =
      this.bookingWaitListCount >= 0 ? this.bookingWaitListCount : 0;
    this.bookingWaitListMonitor.next(this.bookingWaitListCount);
  }
}
