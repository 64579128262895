import { ProductLevelCommissionOpts } from './product-level-commision-opts.model';
import { ProductLevelCommissionRecord } from './product-level-commision-record.model';
import { Stylist } from '../../stylist/stylist.model';

export class ProductLevelCommissionSetting extends ProductLevelCommissionRecord {
  public readonly stylistID: number;

  public readonly stylist: Stylist;

  public readonly commissionValue: number;

  constructor(params: ProductLevelCommissionOpts) {
    super(params);
  }

  public setCommissionValue(value: number): ProductLevelCommissionSetting {
    return this.set('commissionValue', value) as ProductLevelCommissionSetting;
  }
}
