import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';

import { ENV_CONFIG } from '../../bin/env.config';
import { ErrorHandlerService } from './error-handler.service';
import { Injectable } from '@angular/core';
import { List } from 'immutable';
import { Observable } from 'rxjs';
import { SalonmonsterHttpClient } from '../services/salonmonster-http-client';
import { UserService } from './user.service';
import { map } from 'rxjs/operators';

interface GraduatedCommission {
  id: number;
  stylistID?: number;
  itemType: number;
  commissionValue: number;
  upperBound: number;
}

interface DefaultCommission {
  id: number;
  stylistID: number;
  itemType: number;
  commissionType: number;
  commissionValue: number;
}

interface BaseSetting {
  // commissionType
  // 1 = Flat Fee
  // 2 = Percent Commission
  // 3 = Graduated Percent Commission
  commissionType: number;

  // distributionType
  // 1 = Same commission for all staff
  // 2 = Different staff ahve different commissions
  distributionType: number;

  // commissionValue (optional) if the commissionType = 1 | 2 && distributionType = 1
  commissionValue?: number;

  // (optional) if commissionType = 1 | 2 && distributionType = 2
  stylistsCommissions?: DefaultCommission[];
  // (optional) if commissionType = 3 && distributionType = 1
  salonGraduatedCommissions?: GraduatedCommission[];
  // (optional) if commissionType = 3 && distributionType = 2
  // stylistsGraduatedCommissions?: Array<{ stylistID: number, graduatedCommissions: Array<IGraduatedCommission>}>
}

export interface CommissionSetting {
  trackCommissions: number;

  // (optional) if trackCommissions = 1
  service?: BaseSetting;

  // (optional) if trackCommissions = 1
  product?: BaseSetting;
}

@Injectable({
  providedIn: 'root'
})
export class CommissionService extends SalonmonsterHttpClient {
  constructor(
    http: HttpClient,
    protected userService: UserService,
    protected errorHandlerService: ErrorHandlerService
  ) {
    super(http, userService, errorHandlerService);
  }

  public getCommissionSettings(): Observable<CommissionSetting> {
    const url = `${ENV_CONFIG.API_ROOT}/commissions`;

    return this.get(url).pipe(map((result) => result as CommissionSetting));
  }

  public save(params: CommissionSetting): Observable<CommissionSetting> {
    const url = `${ENV_CONFIG.API_ROOT}/commissions`;

    return this.put(url, params);
  }
}
