import {
  Availability,
  Communication,
  Discount,
  Salon,
  SalonPaymentTypes,
  Stylist,
  Tax
} from 'src/app/models';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { List, Map } from 'immutable';

import { ENV_CONFIG } from '../../bin/env.config';
import { ErrorHandlerService } from './error-handler.service';
import { CalendarAvailabilities } from '../bookings/calendar/day-view-calendar/day-view-calendar.component';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SalonmonsterHttpClient } from '../services/salonmonster-http-client';
import { UserService } from '../services/user.service';
import { Utilities } from '../utilities/utilities';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SalonService extends SalonmonsterHttpClient {
  public taxes: List<Tax>;

  public salon: Salon;

  constructor(
    http: HttpClient,
    userService: UserService,
    protected errorHandlerService: ErrorHandlerService
  ) {
    super(http, userService, errorHandlerService);

    userService.getLogOutMonitor().subscribe((logout) => {
      if (logout) {
        this.taxes = null;
      }
    });
  }

  public getIsReadOnly(): boolean {
    return this.salon.isReadOnly();
  }

  public getAllStaff(date: Date): Observable<List<Stylist>> {
    const url = `${
      ENV_CONFIG.API_ROOT
    }/salons/allstaff/bookings?date=${Utilities.formatDate(date)}`;
    return this.get(url).pipe(
      map((data) => {
        let stylists: List<Stylist> = List([]);

        for (const stylist of data) {
          stylists = stylists.push(Stylist.parseStylistData(stylist));
        }

        return stylists;
      })
    );
  }

  public load(): Observable<Salon> {
    const url = `${ENV_CONFIG.API_ROOT}/salons`;
    return this.get(url).pipe(
      map((data) => {
        this.salon = Salon.parse(data[0]);
        return this.salon;
      })
    );
  }

  public getTaxes(forceLoad: boolean = false): Observable<List<Tax>> {
    if (this.taxes && !forceLoad) {
      return of(this.taxes);
    } else {
      const url = `${ENV_CONFIG.API_ROOT}/salons/taxes`;
      return this.get(url).pipe(
        map((data) => {
          let taxes: List<Tax> = List([]);

          for (const tax of data) {
            taxes = taxes.push(Tax.parse(tax));
          }

          return taxes;
        }),
        tap((taxes) => {
          this.taxes = taxes;
        })
      );
    }
  }

  public saveTax(tax: Tax): Observable<Tax> {
    let url = `${ENV_CONFIG.API_ROOT}/salons/taxes`;
    if (tax.id) {
      url += '/' + tax.id;

      return this.put(url, tax.serialize()).pipe(
        map((data) => {
          return Tax.parse(data);
        })
      );
    } else {
      return this.post(url, tax.serialize()).pipe(
        map((data) => {
          return Tax.parse(data);
        })
      );
    }
  }

  public deleteTax(tax: Tax): Observable<boolean> {
    const url = `${ENV_CONFIG.API_ROOT}/salons/taxes/${tax.id}`;

    return this.delete(url, {}).pipe(
      map((data) => {
        return data.success;
      })
    );
  }

  public updateTaxes(taxes: List<Tax>) {
    this.taxes = taxes;
  }

  public getAllStaffAvailabilities(
    year: string,
    month: string
  ): Observable<Map<string, CalendarAvailabilities>> {
    const url = `${ENV_CONFIG.API_ROOT}/salons/allstaff/availabilities?year=${year}&month=${month}`;
    return this.get(url).pipe(
      map((data) => {
        let allStaffAvailabilities: Map<string, CalendarAvailabilities> = Map();

        for (const stylistID in data) {
          if (Object.prototype.hasOwnProperty.call(data, stylistID)) {
            const element = data[stylistID];

            allStaffAvailabilities = allStaffAvailabilities.set(
              stylistID,
              Availability.parseAvailabilityData(element)
            );
          }
        }

        return allStaffAvailabilities;
      })
    );
  }

  public saveSalon(salon: Salon): Observable<Salon> {
    const url = `${ENV_CONFIG.API_ROOT}/salons`;
    return this.put(url, salon.serialize()).pipe(
      map((data) => {
        return Salon.parse(data);
      })
    );
  }

  public getSalonPaymentTypes(): Observable<SalonPaymentTypes> {
    const url = `${ENV_CONFIG.API_ROOT}/salons/paymenttypes`;
    return this.get(url).pipe(
      map((data) => {
        return SalonPaymentTypes.parse(data[0]);
      })
    );
  }

  public savePaymentType(
    salonPaymentTypes: SalonPaymentTypes
  ): Observable<SalonPaymentTypes> {
    const url = `${ENV_CONFIG.API_ROOT}/salons/paymenttypes`;
    return this.put(url, salonPaymentTypes.serialize()).pipe(
      map((data) => {
        return SalonPaymentTypes.parse(data);
      })
    );
  }

  public getSalonDiscounts(): Observable<List<Discount>> {
    const url = `${ENV_CONFIG.API_ROOT}/salons/discounts`;
    return this.get(url).pipe(
      map((data) => {
        let discounts: List<Discount> = List([]);

        for (const discountData of data) {
          discounts = discounts.push(Discount.parseDiscount(discountData));
        }

        return discounts;
      })
    );
  }

  public getClientsCount(): Observable<number> {
    const url = `${ENV_CONFIG.API_ROOT}/salons/clients/count`;
    return this.get(url).pipe(
      map((data) => {
        return data.numOfClients;
      })
    );
  }

  public saveSalonDiscount(discount: Discount): Observable<Discount> {
    let url = `${ENV_CONFIG.API_ROOT}/salons/discounts`;

    if (discount.id) {
      url += '/' + discount.id;

      return this.put(url, discount.serialize()).pipe(
        map((data) => {
          return Discount.parseDiscount(data);
        })
      );
    } else {
      return this.post(url, discount.serialize()).pipe(
        map((data) => {
          return Discount.parseDiscount(data);
        })
      );
    }
  }

  public deleteSalonDiscounts(discount: Discount): Observable<Discount> {
    const url = `${ENV_CONFIG.API_ROOT}/salons/discounts/${discount.id}`;
    return this.delete(url, {}).pipe(
      map((data) => {
        return Discount.parseDiscount(data);
      })
    );
  }

  public bulkCommunicationHistory(): Observable<List<Communication>> {
    const url = `${ENV_CONFIG.API_ROOT}/salons/bulkcommunications`;

    return this.get(url).pipe(
      map((data) => {
        let communications: List<Communication> = List([]);

        for (const communicationData of data) {
          communications = communications.push(
            Communication.parse(communicationData)
          );
        }

        return communications;
      })
    );
  }

  public subDomainChecker(subDomain: string): Observable<boolean> {
    const url = `${ENV_CONFIG.API_ROOT}/salons/subdomainchecker/${subDomain}`;

    return this.get(url).pipe(
      map((data) => {
        return data.available;
      })
    );
  }

  public getOnlineBookingSharePhotos(): Observable<List<string>> {
    const url = `${ENV_CONFIG.API_ROOT}/onlinebooking/share`;

    return this.get(url).pipe(
      map((data) => {
        let photos: List<string> = List([]);
        for (const photo of data) {
          photos = photos.push(photo);
        }

        return photos;
      })
    );
  }
}
