import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/user/auth.guard';
const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'bookings',
    loadChildren: () => import('./bookings/bookings.module').then( m => m.BookingsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'clients',
    loadChildren: () => import('./clients/clients.module').then( m => m.ClientsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'requests',
    loadChildren: () => import('./requests/requests.module').then( m => m.RequestsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'waitlist',
    loadChildren: () => import('./waitlist/waitlist.module').then( m => m.WaitlistPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'sales',
    loadChildren: () => import('./sales/sales.module').then( m => m.SalesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'register-closures',
    loadChildren: () => import('./register-closures/register-closures.module').then( m => m.RegisterClosuresPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'products',
    loadChildren: () => import('./products/products.module').then( m => m.ProductsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'inventory',
    loadChildren: () => import('./inventory/inventory.module').then( m => m.InventoryPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'marketing',
    loadChildren: () => import('./marketing/marketing.module').then( m => m.MarketingPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'reports',
    loadChildren: () => import('./reports/reports.module').then( m => m.ReportsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then( m => m.SettingsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'help',
    loadChildren: () => import('./help/help.module').then( m => m.HelpPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'password-reset',
    loadChildren: () => import('./password-reset/password-reset.module').then( m => m.PasswordResetPageModule)
  },
  {
    path: 'password-reset-password/:token',
    loadChildren: () => import('./password-reset-password/password-reset-password.module').then( m => m.PasswordResetPasswordPageModule)
  },
  {
    path: 'import-contacts',
    loadChildren: () => import('./import-contacts/contact-list/contact-list.module').then( m => m.ContactListPageModule),
    canActivate: [AuthGuard]

  },
  {
    path: 'online-bookings',
    loadChildren: () => import('./online-bookings/online-booking-page/online-booking-page.module').then( m => m.OnlineBookingPagePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'activity',
    loadChildren: () => import('./activity/activity.module').then( m => m.ActivityPageModule)
  },
  {
    path: 'brands',
    loadChildren: () => import('./brands/brands.module').then( m => m.BrandsPageModule)
  },
  {
    path: 'suppliers',
    loadChildren: () => import('./suppliers/suppliers.module').then( m => m.SuppliersPageModule)
  },
  {
    path: 'categories',
    loadChildren: () => import('./categories/categories.module').then( m => m.CategoriesPageModule)
  },
  {
    path: 'text-marketing',
    loadChildren: () => import('./text-marketing/text-marketing.module').then( m => m.TextMarketingPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'inventory-count',
    loadChildren: () => import('./inventory-count/inventory-count.module').then( m => m.InventoryCountPageModule)
  },
  {
    path: 'inventory-history',
    loadChildren: () => import('./inventory-history/inventory-history.module').then( m => m.InventoryHistoryPageModule)
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
