import { Injectable } from '@angular/core';

import { UserService } from '../services/user.service';
import { Utilities } from '../utilities/utilities';
import { List } from 'immutable';
import { TimeSlot } from '../bookings/calendar/time-slot/time-slot.component';

@Injectable({
  providedIn: 'root'
})
export class CalendarCacheService {
  private cachedTimeslot: List<TimeSlot>;

  public timeOptions: { name: string; value: string }[];

  constructor(private userService: UserService) {
    this.userService.getLogOutMonitor().subscribe((logout) => {
      if (logout) {
        this.cachedTimeslot = null;
        this.timeOptions = null;
      }
    });
  }

  public getCachedTimeoptions(): { name: string; value: string }[] {
    if (this.timeOptions) {
      return this.timeOptions;
    }

    const salon = this.userService.getSalon();
    const startDate = Utilities.parseDate('2017-01-01 00:00:00');
    const currentHour = startDate.getHours();
    let calStartHour = 7;
    let calEndHour = 18;
    const timeOptions = [];

    if (
      salon.getCalStartTime() !== null &&
      salon.getCalStartTime() !== undefined &&
      salon.getCalStartTime() !== '' &&
      isNaN(parseInt(salon.getCalStartTime().substring(0, 2), 10)) === false
    ) {
      const h = parseInt(salon.getCalStartTime().substring(0, 2), 10);
      if (h !== 0) {
        calStartHour = h - 1;
      }
    }

    if (
      salon.getCalEndTime() !== null &&
      salon.getCalEndTime() !== undefined &&
      salon.getCalEndTime() !== '' &&
      isNaN(parseInt(salon.getCalEndTime().substring(0, 2), 10)) === false
    ) {
      const h = parseInt(salon.getCalEndTime().substring(0, 2), 10);
      if (h !== 0 && h > calStartHour) {
        calEndHour = h + 1;
      }
    }

    for (let hour = calStartHour; hour < calEndHour; hour += 1) {
      const newHour = currentHour + hour;

      startDate.setHours(newHour);
      startDate.setMinutes(0);

      timeOptions.push({
        name: Utilities.formatDate(startDate, 'h:mm A'),
        value: Utilities.formatDate(startDate, 'HH:mm')
      });

      for (let min = 15; min < 60; min += 15) {
        startDate.setMinutes(min);

        timeOptions.push({
          name: Utilities.formatDate(startDate, 'h:mm A'),
          value: Utilities.formatDate(startDate, 'HH:mm')
        });
      }
    }

    this.timeOptions = timeOptions;
    return timeOptions;
  }

  public getCachedTimeslots(): List<TimeSlot> {
    if (this.cachedTimeslot) {
      // return the cache
      return this.cachedTimeslot;
    }

    const salon = this.userService.getSalon();
    const startDate = Utilities.parseDate('2017-01-01 00:00:00');
    const currentHour = startDate.getHours();
    let calStartHour = 7;
    let calEndHour = 18;
    let timeslots: List<TimeSlot> = List([]);

    if (
      salon.getCalStartTime() !== null &&
      salon.getCalStartTime() !== undefined &&
      salon.getCalStartTime() !== '' &&
      isNaN(parseInt(salon.getCalStartTime().substring(0, 2), 10)) === false
    ) {
      const h = parseInt(salon.getCalStartTime().substring(0, 2), 10);
      if (h !== 0) {
        calStartHour = h - 1;
      }
    }

    if (
      salon.getCalEndTime() !== null &&
      salon.getCalEndTime() !== undefined &&
      salon.getCalEndTime() !== '' &&
      isNaN(parseInt(salon.getCalEndTime().substring(0, 2), 10)) === false
    ) {
      const h = parseInt(salon.getCalEndTime().substring(0, 2), 10);
      if (h !== 0 && h > calStartHour) {
        calEndHour = h + 1;
      }
    }

    for (let hour = calStartHour; hour < calEndHour; hour += 1) {
      const newHour = currentHour + hour;

      timeslots = timeslots.push(
        new TimeSlot({
          hour: newHour,
          minutes: 0,
          events: List([]),
          isAvailable: true,
          eventsOverlap: 0,
          eventsStartIndex: 0,
          hideLabels: true,
          showLeftMinuteLabels: false
        })
      );

      for (let min = 15; min < 60; min += 15) {
        timeslots = timeslots.push(
          new TimeSlot({
            hour: newHour,
            minutes: min,
            events: List([]),
            isAvailable: true,
            eventsOverlap: 0,
            eventsStartIndex: 0,
            hideLabels: true,
            showLeftMinuteLabels: false
          })
        );
      }
    }


    this.cachedTimeslot = timeslots;

    return this.cachedTimeslot;
  }
}
