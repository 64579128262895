import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Salon, Stylist } from 'src/app/models';

import { ENV_CONFIG } from 'src/bin/env.config';
import { ErrorHandlerService } from './error-handler.service';
import { Auth } from '../services/login.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SalonmonsterHttpClient } from '../services/salonmonster-http-client';
import { UserService } from '../services/user.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SignUpService extends SalonmonsterHttpClient {
  constructor(
    http: HttpClient,
    userService: UserService,
    protected errorHandlerService: ErrorHandlerService
  ) {
    super(http, userService, errorHandlerService);
  }

  public process(salon: Salon, stylist: Stylist, referral: any): Observable<Auth> {
    const url = `${ENV_CONFIG.API_ROOT}/signup`;

    return this.post(url, {
      salon: salon.serialize(),
      stylist: stylist.serialize(),
      referral: referral
    });
  }
}
