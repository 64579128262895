import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ENV_CONFIG } from 'src/bin/env.config';
// import { InAppPurchase2, IAPProduct } from '@awesome-cordova-plugins/in-app-purchase-2';
import { Subject } from 'rxjs';
import { Platform } from '@ionic/angular';

interface InAppProductSetting {
  id: string;
  alias: string;
}

export const SMInAppProducts = {
  SOLO_MONTHLY_PLAN: {
    id: 'com.salonmonster.subscriptions.monthly.solo',
    alias: 'Solo Monthly Plan'
  },
  SMALL_MONTHLY_PLAN: {
    id: 'com.salonmonster.subscriptions.monthly.small',
    alias: 'Small Monthly Plan'
  },
  MEDIUM_MONTHLY_PLAN: {
    id: 'com.salonmonster.subscriptions.monthly.medium',
    alias: 'Medium Monthly Plan'
  },
  LARGE_MONTHLY_PLAN: {
    id: 'com.salonmonster.subscriptions.monthly.large',
    alias: 'Large Monthly Plan'
  }

  // TODO: annual plans
  // ,
  // SOLO_ANNUALLY_PLAN: {
  //   id: "com.salonmonster.plans.annually.solo",
  //   alias: "Solo Monthly Plan"
  // },
  // SMALL_ANNUALLY_PLAN: {
  //   id: "com.salonmonster.plans.annually.small",
  //   alias: "Small Monthly Plan"
  // },
  // MEDIUM_ANNUALLY_PLAN: {
  //   id: "com.salonmonster.plans.annually.medium",
  //   alias: "Medium Monthly Plan"
  // },
  // LARGE_ANNUALLY_PLAN: {
  //   id: "com.salonmonster.plans.annually.large",
  //   alias: "Large Monthly Plan"
  // }
};

@Injectable({
  providedIn: 'root'
})
export class InAppPurchaseService {
  // public soloMonthly: IAPProduct;

  // public smallMonthly: IAPProduct;

  // public mediumMonthly: IAPProduct;

  // public largeMonthly: IAPProduct;

  // private productUpdateMonitor = new Subject<IAPProduct>();

  // private productUpdateMonitor$ = this.productUpdateMonitor.asObservable();

  constructor(
    // private store: InAppPurchase2,
    private platform: Platform
  ) {}

  public init() {
    // this.platform.ready().then(() => {
    //   this.store.validator = `${ENV_CONFIG.API_ROOT}/appstore/receipt/verify`;
    //   for (let key in SMInAppProducts) {
    //     this.registerProduct(SMInAppProducts[key]);
    //   }
    //   this.soloMonthly = this.store.get(SMInAppProducts.SOLO_MONTHLY_PLAN.id);
    //   this.smallMonthly = this.store.get(SMInAppProducts.SMALL_MONTHLY_PLAN.id);
    //   this.mediumMonthly = this.store.get(SMInAppProducts.MEDIUM_MONTHLY_PLAN.id);
    //   this.largeMonthly = this.store.get(SMInAppProducts.LARGE_MONTHLY_PLAN.id);
    //   this.store.refresh();
    // });
  }

  // private registerProduct (productSetting: InAppProductSetting) {
  //   const productID: string = productSetting.id;

  //   this.store.register({
  //     id: productSetting.id,
  //     alias: productSetting.alias,
  //     type: this.store.PAID_SUBSCRIPTION
  //   });

  //   this.store.when(productID).updated(this.productUpdated);
  //   this.store.when(productID).approved(this.productApproved);
  //   this.store.when(productID).verified(this.productVerified);
  //   this.store.when(productID).cancelled(this.productCancelled);
  //   this.store.when(productID).owned(this.productOwned);
  //   this.store.when(productID).error(this.storeError);
  // }

  // private productUpdated (product: IAPProduct) {

  //   this.productUpdateMonitor.next(product);

  //   // check if product.owned
  //   // if owned then check if expiry date in localstorage has changed
  // }

  // private productApproved (product: IAPProduct) {
  //   // update our db with expiry date (1 month)
  //   // if expiry date has changed then update db with latest expiry date .... keeps it updated when they renew

  //   product.verify();
  //   // product.finish();
  // }

  // private productVerified (product: IAPProduct) {
  //   // update our db with expiry date (1 month)
  //   // if expiry date has changed then update db with latest expiry date .... keeps it updated when they renew

  //   product.finish();
  // }

  // private productCancelled (product: IAPProduct){
  // }

  // private productOwned (product: IAPProduct){
  // }

  // private storeError (error) {
  // }

  // public getProductMonitor () : Observable <IAPProduct> {
  //   return this.productUpdateMonitor$;
  // }
}
