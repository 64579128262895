import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

import { CustomValidator } from '../utilities/custom-validator';
import { AppAvailability } from '@awesome-cordova-plugins/app-availability/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Sale } from 'src/app/models';

interface PayPalItem {
  name: string;
  description?: string;
  unitPrice: number;
  quantity: number;
  taxName?: string;
  taxRate?: number;
}

@Injectable({
  providedIn: 'root'
})
export class PaypalPOSService {
  constructor(
    private platform: Platform,
    private inAppBrowser: InAppBrowser,
    private appAvailability: AppAvailability
  ) {}

  public launchPOS(sale: Sale) {
    if (
      !this.platform.is('capacitor') ||
      (!this.platform.is('ios') && !this.platform.is('android'))
    ) {
      return;
    }

    // PayPalHere: https://github.com/paypal/here-sideloader-api-samples/blob/master/docs/README.md
    // Paypal Invoice fields:
    //  https://developer.paypal.com/docs/classic/api/invoicing/CreateAndSendInvoice_API_Operation/?mark=discountPercent

    const paypalItemList: PayPalItem[] = [];

    sale.lineitems.forEach((lineitem) => {
      const item: PayPalItem = {
        name: lineitem.name,
        unitPrice: lineitem.price,
        quantity: lineitem.quantity
        // taxName?: string,
        // taxRate?: number
      };

      // TODO tax - issue paypal doesn't allow multiple taxes per lineitem
      // src: https://www.paypal-community.com/t5/My-PayPal-account/Charging-two-seperate-taxes/td-p/253272?profile.language=en
      // if (lineitem.taxRateType === 0) {

      // }

      // const taxName: string = '';
      // const taxRate: number = 0;

      paypalItemList.push(item);
    });

    const invoiceData = {
      paymentTerms: 'DueOnReceipt',
      currencyCode: 'CAD', // TODO
      // "number": "1457",
      itemList: {
        item: paypalItemList
      }
    };

    const clientEmail: string =
      sale.client && CustomValidator.isEmailValid(sale.client.email)
        ? sale.client.email
        : '';

    if (clientEmail !== '') {
      invoiceData['payerEmail'] = clientEmail;
    }

    let app: string;
    let appStoreLink: string;
    let paypaylDeeplink: string;

    if (this.platform.is('ios')) {
      app = 'paypalhere://';
      appStoreLink = `https://itunes.apple.com/ca/app/paypal-here-mobile-point-of-sale-pos-system/id505911015`;
      paypaylDeeplink = 'paypalhere://takePayment';
    } else if (this.platform.is('android')) {
      app = 'com.paypal.here';
      appStoreLink = `https://play.google.com/store/apps/details?id=${app}`;
      paypaylDeeplink = 'paypalhere://takePayment/v2';
    } else {
      return;
    }

    // TODO
    const acceptedPaymentTypes = `cash,card,paypal`;

    const payerPhone: string =
      sale.client && CustomValidator.isPhoneValid(sale.client.phone2)
        ? sale.client.phone2
        : '';

    const returnUrl: string = encodeURIComponent(
      'salonmonster://payment?type={Type}&invoiceID={InvoiceId}&grandTotal={GrandTotal}&merchantReferenceID={Number}&tip={Tip}'
    );

    // convert to base64 (recommended)
    const invoice: string = btoa(JSON.stringify(invoiceData));

    paypaylDeeplink += `?accepted:${acceptedPaymentTypes}&returnUrl=${returnUrl}&as=b64&step=choosePayment&invoice=${invoice}`;

    if (payerPhone !== '') {
      paypaylDeeplink += `&payerPhone=${payerPhone}`;
    }

    this.appAvailability.check(app).then(
      () => {
        this.inAppBrowser.create(paypaylDeeplink, '_system');
      },
      () => {
        this.inAppBrowser.create(appStoreLink, '_system');
      }
    );
  }
}
