import { Stylist } from '../stylist/stylist.model';
import { TipJSON } from './tip-json.model';
import { TipRecord } from './tip-record.model';

export class Tip extends TipRecord {
  public readonly id: number;
  public readonly isRefunded: number;
  public readonly stylist: Stylist;
  public readonly stylistID: number;
  public readonly value: number;

  constructor(params: TipJSON) {
    super(params);
  }

  public setID(value: number): Tip {
    return this.set('id', value) as Tip;
  }

  public setStylist(stylist: Stylist): Tip {
    return this.set('stylist', stylist).set('stylistID', stylist.id) as Tip;
  }

  // public setStylistID (value: number) : Tip {
  //   return <Tip> this.set('stylistID', value);
  // }

  public setValue(value: number): Tip {
    return this.set('value', value) as Tip;
  }

  public setIsRefunded(value: number): Tip {
    return this.set('isRefunded', value) as Tip;
  }
}
