import { Pipe, PipeTransform } from '@angular/core';
import { Utilities } from '../utilities/utilities';
import { UserService } from '../services/user.service';

@Pipe({name: 'salonMonsterCurrency'})
export class SalonMonsterCurrencyPipe implements PipeTransform {

  constructor (private userService: UserService) {}

  transform(value: number) : string {
    const currency = (this.userService.getSalon()) ? this.userService.getSalon().getCurrency() : '';
    // check if negative
    if(Math.sign(value) === -1){
    	return "-" + Utilities.decodeHTML(currency) + (Utilities.roundTo2Decimal(value).toFixed(2) + '').replace("-","");
    }
    return Utilities.decodeHTML(currency) + (Utilities.roundTo2Decimal(value).toFixed(2) + '');
  }

}

