import { Cloneable } from '../cloneable.model';
import { ProductSupplierJSON } from './product-supplier-json.model';
import { ProductSupplierSerialized } from './product-supplier-serialized.model';
import { Serializable } from '../serializable.model';

export class ProductSupplier
  implements
    Cloneable<ProductSupplier, ProductSupplierJSON>,
    Serializable<ProductSupplierSerialized> {
  constructor(options: ProductSupplierJSON) {
    this.id = options.id;
    this.name = options.name;
    this.firstName = options.firstName;
    this.lastName = options.lastName;
    this.workPh = options.workPh;
    this.cellPh = options.cellPh;
    this.fax = options.fax;
    this.website = options.website;
    this.email = options.email;
    this.address = options.address;
    this.city = options.city;
    this.zip = options.zip;
    this.country = options.country;
    this.state = options.state;
    this.notes = options.notes;
  }

  public readonly id: number;

  public readonly name: string;

  public readonly firstName: string;

  public readonly lastName: string;

  public readonly workPh: string;

  public readonly cellPh: string;

  public readonly fax: string;

  public readonly website: string;

  public readonly email: string;

  public readonly address: string;

  public readonly city: string;

  public readonly zip: string;

  public readonly country: string;

  public readonly state: string;

  public readonly notes: string;

  public static parse(params: Object): ProductSupplier {
    return new ProductSupplier({
      id: params['id'],
      name: params['name'],
      firstName: params['firstName'],
      lastName: params['lastName'],
      workPh: params['workPh'],
      cellPh: params['cellPh'],
      fax: params['fax'],
      website: params['website'],
      email: params['email'],
      address: params['address'],
      city: params['city'],
      zip: params['zip'],
      country: params['country'],
      state: params['state'],
      notes: params['notes']
    });
  }

  public getID(): number {
    return this.id;
  }

  public setID(value: number): ProductSupplier {
    const data: ProductSupplierJSON = this.toJSON();
    data.id = value;
    return new ProductSupplier(data);
  }

  public getName(): string {
    return this.name;
  }

  public setName(value: string): ProductSupplier {
    const data: ProductSupplierJSON = this.toJSON();
    data.name = value;
    return new ProductSupplier(data);
  }

  public setFirstName(value: string): ProductSupplier {
    const data: ProductSupplierJSON = this.toJSON();
    data.firstName = value;
    return new ProductSupplier(data);
  }

  public setLastName(value: string): ProductSupplier {
    const data: ProductSupplierJSON = this.toJSON();
    data.lastName = value;
    return new ProductSupplier(data);
  }

  public setWorkPh(value: string): ProductSupplier {
    const data: ProductSupplierJSON = this.toJSON();
    data.workPh = value;
    return new ProductSupplier(data);
  }

  public setCellPh(value: string): ProductSupplier {
    const data: ProductSupplierJSON = this.toJSON();
    data.cellPh = value;
    return new ProductSupplier(data);
  }

  public setFax(value: string): ProductSupplier {
    const data: ProductSupplierJSON = this.toJSON();
    data.fax = value;
    return new ProductSupplier(data);
  }

  public setWebsite(value: string): ProductSupplier {
    const data: ProductSupplierJSON = this.toJSON();
    data.website = value;
    return new ProductSupplier(data);
  }

  public setEmail(value: string): ProductSupplier {
    const data: ProductSupplierJSON = this.toJSON();
    data.email = value;
    return new ProductSupplier(data);
  }

  public setAddress(value: string): ProductSupplier {
    const data: ProductSupplierJSON = this.toJSON();
    data.address = value;
    return new ProductSupplier(data);
  }

  public setCity(value: string): ProductSupplier {
    const data: ProductSupplierJSON = this.toJSON();
    data.city = value;
    return new ProductSupplier(data);
  }

  public setZip(value: string): ProductSupplier {
    const data: ProductSupplierJSON = this.toJSON();
    data.zip = value;
    return new ProductSupplier(data);
  }

  public setCountry(value: string): ProductSupplier {
    const data: ProductSupplierJSON = this.toJSON();
    data.country = value;
    return new ProductSupplier(data);
  }

  public setState(value: string): ProductSupplier {
    const data: ProductSupplierJSON = this.toJSON();
    data.state = value;
    return new ProductSupplier(data);
  }

  public setNotes(value: string): ProductSupplier {
    const data: ProductSupplierJSON = this.toJSON();
    data.notes = value;
    return new ProductSupplier(data);
  }

  public toJSON(): ProductSupplierJSON {
    return {
      id: this.id,
      name: this.name,
      firstName: this.firstName,
      lastName: this.lastName,
      workPh: this.workPh,
      cellPh: this.cellPh,
      fax: this.fax,
      website: this.website,
      email: this.email,
      address: this.address,
      city: this.city,
      zip: this.zip,
      country: this.country,
      state: this.state,
      notes: this.notes
    };
  }

  public clone(): ProductSupplier {
    return new ProductSupplier(this.toJSON());
  }

  public serialize(): ProductSupplierSerialized {
    return this.toJSON();
  }
}
