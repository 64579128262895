import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';

import { Country } from 'src/app/models';
import { ENV_CONFIG } from 'src/bin/env.config';
import { ErrorHandlerService } from './error-handler.service';
import { Injectable } from '@angular/core';
import { List } from 'immutable';
import { Observable, of } from 'rxjs';
import { SalonmonsterHttpClient } from '../services/salonmonster-http-client';
import { UserService } from './user.service';
// import 'rxjs/add/observable/of';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CountryService extends SalonmonsterHttpClient {
  private countries: List<Country>;

  constructor(
    http: HttpClient,
    protected userService: UserService,
    protected errorHandlerService: ErrorHandlerService
  ) {
    super(http, userService, errorHandlerService);
    this.countries = List.of<Country>();
  }

  public getCountries(): Observable<List<Country>> {
    const url = `${ENV_CONFIG.API_ROOT}/countries?fields=id,name`;

    if (this.countries.count() === 0) {
      return this.get(url).pipe(
        map((data) => {
          let countries: List<Country> = List.of<Country>();
          for (const countryData of data) {
            const id = countryData.id;
            const name = countryData.name;
            countries = countries.push(new Country(id, name));
          }

          this.countries = countries;
          return this.countries;
        })
      );
    } else {
      // return cached countries
      return of(this.countries);
    }
  }
}
