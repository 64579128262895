import { ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { BackgroundProcessService } from 'src/app/services/background-process.service'
import { Storage } from '@ionic/storage-angular';
import { StylistService } from 'src/app/services//stylist.service';
import { ENV_CONFIG } from '../bin/env.config';
declare let gtag: Function;
import { PopoverController, NavController, MenuController, ModalController, ToastController, Platform, ViewWillEnter } from '@ionic/angular';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { NavComponent } from './components/nav/nav.component';
import { Stylist } from './models/stylist/stylist.model';
import { HelpPage } from './help/help.page';
import * as Sentry from "@sentry/angular";
import { SubscriptionLike } from 'rxjs';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, ViewWillEnter {
  pages_1 = [
    {
      title: 'Bookings',
      url: '/bookings',
      icon: 'calendar',
    },
    {
      title: 'Clients',
      url: '/clients',
      icon: 'person',
    },
  ]
  pages_2 = [
    {
      title: 'Sales',
      url: '/sales',
      icon: 'card',
    },
    {
      title: 'Register Closures',
      url: '/register-closures',
      icon: 'cash',
    },
    {
      title: 'Products',
      url: '/products',
      icon: 'pricetags',
      expandable: true,
      children: [
        { title: 'Products', url: '/products' },
        { title: 'Categories', url: '/categories' },
        { title: 'Brands', url: '/brands' },
        { title: 'Suppliers', url: '/suppliers' },
      ]
    },
    {
      title: 'Inventory',
      url: '/inventory',
      icon: 'cube',
      expandable: true,
      children: [
        { title: 'Purchase Orders', url: '/inventory' },
        { title: 'Inventory History', url: '/inventory-history' },
        { title: 'Inventory Count', url: '/inventory-count' },
      ]
    },
    {
      title: 'Marketing',
      url: '/marketing',
      icon: 'send',
      // expandable: true,
      // children: [
      //   {title: 'Email', url: '/marketing'},
      //   {title: 'Text', url: '/text-marketing'},
      // ]
    },
    {
      title: 'Reports',
      url: '/reports',
      icon: 'pie-chart',
      disabled: false
    },
    {
      title: '',
      url: '',
      icon: '',
      disabled: true
    },
    {
      title: 'Accept Online Bookings',
      url: '/online-bookings',
      icon: 'desktop',
    },
    {
      title: 'Import Contacts',
      url: '/import-contacts',
      icon: 'receipt',
    },
    {
      title: 'Subscribe to Calendar',
      icon: 'today',
      url: '/subscribe-to-calendar',
    },
    {
      title: '',
      url: '',
      icon: '',
      disabled: true
    },
    {
      title: 'Settings',
      url: '/settings',
      icon: 'settings',
      disabled: false
    },
    /*{
      title: 'Help',
      url: '/help',
      icon: 'help',
    },  */
  ];

  user;

  profileImg;

  selectedPath = '';

  bookingWaitListCount = 0;

  bookingRequestCount = 0;

  isLoggedIn = false;

  public collapsed: boolean = false;
  public appVersion: string;
  public stylists: Stylist;
  public loginLastRecorded: number = Date.now();
  public allowSettings: boolean;
  public allowReports: boolean;
  public isNativeApp: boolean;
  private onResumeSubscription: SubscriptionLike;

  constructor(
    public router: Router,
    public userService: UserService,
    private backgroundProcessService: BackgroundProcessService,
    private storage: Storage,
    private popoverCtrl: PopoverController,
    private stylistService: StylistService,
    private platform: Platform,
    private inAppBrowser: InAppBrowser,

    private navCtrl: NavController,
    private menuCtrl: MenuController,
    private modalCtrl: ModalController, private toastCtrl: ToastController,
    private changeDetectorRef: ChangeDetectorRef) {


    this.appVersion = ENV_CONFIG.appVersion;
    this.isNativeApp = this.platform.is('hybrid');
    this.router.events.subscribe((event: RouterEvent) => {
      if (event && event.url) {
        this.selectedPath = event.url;
      }
      if (event instanceof NavigationEnd) {
        // track google analytics page views
        gtag('config', 'G-MFKK9R8PZ9', { 'page_path': 'app' + event.urlAfterRedirects });

      }
    });


    this.allowSettings = this.pages_2.find(x => x.title == 'Settings').disabled;
    // this.allowReports = this.pages_2.find(x=>x.title == 'Reports').disabled;

    /*if((this.stylists.canViewReports === 0) || (this.stylists.settingsControl === 0)){
      this.allowSettings = false;
      this.allowReports = false;
      //this.pages_2.find(x=>x.title == 'Settings').disabled = true;
      //this.pages_2.find(x=>x.title == 'Reports').disabled = true;
    }*/

  }


  async ngOnInit() {

    this.isLoggedIn = this.userService.isLoggedIn();
    this.userService.profileImageMonitor$.subscribe((data) => {
      this.profileImg = ENV_CONFIG.STAFF_THUMBNAIL_PHOTO_ROOT + '/' + data;
      // Handle data change in AppComponent
    });
    await this.storage.create();
    this.storage.set("collapsed", false)
    this.userService.getLogInMonitor().subscribe((login) => {
      if (login) {
        this.isLoggedIn = login;
        this.stylistService.recordStylistActivity(this.userService.getUser().getID(), "First Load");
        if (this.userService.getUser()) {
          this.user = this.userService.getUser();
          //canViewReports == 1
          //settingsControl ==1
          if (this.userService.getUser().getFullThumbnailUrl()) {
            this.profileImg = this.userService.getUser().getFullThumbnailUrl()
          }
          this.stylistService.recordStylistActivity(this.userService.getUser().getID(), "First Load")
        }
      }
    })

    this.backgroundProcessService.bookingRequestMonitor$.subscribe(
      (bookingRequestCount: number) => {
        this.bookingRequestCount = bookingRequestCount;
      }
    );

    this.backgroundProcessService.bookingWaitListMonitor$.subscribe(
      (bookingWaitListCount: number) => {
        this.bookingWaitListCount = bookingWaitListCount;
      }
    );


    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    if (this.platform.is('capacitor')) {
      PushNotifications.requestPermissions().then(result => {
        if (result.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
        } else {
          // Show some error
        }
      });
    }

    // activate Sentry
    Sentry.init({
      dsn: ENV_CONFIG.SENTRY_DSN,
      environment: ENV_CONFIG.SENTRY_ENVIRONMENT,
      // release: ENV_CONFIG.appVersion,
      integrations: [

        // Registers and configures the Tracing integration,
        // which automatically instruments your application to monitor its
        // performance, including custom Angular routing instrumentation
        Sentry.browserTracingIntegration(),
        // // Registers the Replay integration,
        // // which automatically captures Session Replays
        // new Sentry.Replay({
        //   maskAllText: false,
        //   blockAllMedia: false,
        //   networkDetailAllowUrls: ['^https://api3.salonmonster.com(?:/.*)?$'],
        //   networkRequestHeaders: ['X-Custom-Header'],
        //   networkResponseHeaders: ['X-Custom-Header'],
        // }),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.2,

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/api.salonmonster\.com\/api/, /^https:\/\/api2.salonmonster\.com\/api/, /^https:\/\/api3.salonmonster\.com\/api/],

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 1.0,
    });


  }

  public ngOnDestroy() {
    if (this.onResumeSubscription) {
      this.onResumeSubscription.unsubscribe();
    }
  }

  public ionViewWillEnter() {
    if (this.platform.is("capacitor")) {
      this.platform.ready().then(() => {
        this.onResumeSubscription = this.platform.resume.subscribe(() => {
          this.changeDetectorRef.markForCheck();
          // This prevents the app from accidentally showing the client picker
          // instead of the booking editor when the app is first opened on the phone
        });

      })
    }
  }

  public async popover(ev, page) {
    const popover = await this.popoverCtrl.create({
      component: NavComponent,
      event: ev,
      componentProps: {
        options: page.children
      }
    })
    popover.present();
  }

  public collapseMenu() {
    this.collapsed = !this.collapsed;
    this.storage.set("collapsed", this.collapsed)
  }

  public logout() {
    this.isLoggedIn = false;
    this.userService.logout();
  }

  public open(url) {
    if (url == "https://salontastic.salonmonster.com") {
      window.location = url;
      return
    }
    this.navCtrl.setDirection('root')
    this.router.navigateByUrl(url);
    this.menuCtrl.toggle();
  }

  public onSubscribeToCalendar() {
    //this.collapseMenu()
    const user = this.userService.getUser();
    const salon = this.userService.getSalon();

    if (user && salon) {
      const subDomain = salon.getSubDomain();
      const stylistKey = user.getStylistKey();

      if (!subDomain || subDomain.length === 0) {
        return;
      }

      if (!stylistKey || stylistKey.length === 0) {
        return;
      }

      const link =
        'webcal://' +
        subDomain +
        '.salonmonster.com/live/feed/ical.php?calendar=' +
        stylistKey.substr(0, 20);

      if (this.platform.is('capacitor')) {
        this.inAppBrowser.create(link, '_system');
      } else {
        window.location.href =
          'webcal://' +
          subDomain +
          '.salonmonster.com/live/feed/ical.php?calendar=' +
          stylistKey.substr(0, 20);
      }
    }
  }
  public async onClickHelp() {
    const modal = await this.modalCtrl.create({
      component: HelpPage,
    });
    return await modal.present();
  }

  public onReload() {
    // This reloads the app's index.html page with a random query string to force it to reload from the server (not cache)
    var randomNumber = Math.floor(Math.random() * 1000000000); // Generates a random number
    var base = window.location.href.split('#')[0]; // Get the base URL before the hash
    var newUrl = base.replace(/\/$/, '') + '/index.html?update=' + randomNumber; // Append 'index.html' with the update parameter
    window.location.href = newUrl; // Redirect to the new URL
  }

}
