import { Cloneable } from '../cloneable.model';
import { DiscountJSON } from './discount-json.model';
import { DiscountSerialized } from './discount-serialized.model';
import { Serializable } from '../serializable.model';

export class Discount
  implements
    Cloneable<Discount, DiscountJSON>,
    Serializable<DiscountSerialized> {
  constructor(options: DiscountJSON) {
    this.id = options.id;
    this.name = options.name;
    this.type = options.type;
    this.amount = options.amount;
    this.discountCommission = options.discountCommission;
    this.stylistID = options.stylistID;
  }

  public readonly id: number;

  public readonly name: string;

  public readonly type: number;

  public readonly amount: number;

  public readonly discountCommission: number;

  public readonly stylistID: number;

  public static parseDiscount(data): Discount {
    return new Discount({
      id: data['id'],
      name: data['name'],
      type: data['type'],
      amount: data['amount'],
      discountCommission: data['discountCommission'],
      stylistID: data['stylistID']
    });
  }

  public clone(): Discount {
    return new Discount(this.toJSON());
  }

  public toJSON(): DiscountJSON {
    return {
      id: this.getID(),
      name: this.getName(),
      type: this.getType(),
      amount: this.getAmount(),
      discountCommission: this.getDiscountCommission(),
      stylistID: this.getStylistID()
    };
  }

  public serialize(): DiscountSerialized {
    return this.toJSON();
  }

  public getID(): number {
    return this.id;
  }

  public setID(newID: number): Discount {
    const data: DiscountJSON = this.toJSON();
    data.id = newID;
    return new Discount(data);
  }

  public getName(): string {
    return this.name;
  }

  public setName(name: string): Discount {
    const data: DiscountJSON = this.toJSON();
    data.name = name;
    return new Discount(data);
  }

  public getType(): number {
    return this.type;
  }

  public setType(type: number): Discount {
    const data: DiscountJSON = this.toJSON();
    data.type = type;
    return new Discount(data);
  }

  public getAmount(): number {
    return this.amount;
  }

  public setAmount(amount: number): Discount {
    const data: DiscountJSON = this.toJSON();
    data.amount = amount;
    return new Discount(data);
  }

  public getDiscountCommission(): number {
    return this.discountCommission;
  }

  public setDiscountCommission(discountCommission: number): Discount {
    const data: DiscountJSON = this.toJSON();
    data.discountCommission = discountCommission;
    return new Discount(data);
  }

  public getStylistID(): number {
    return this.stylistID;
  }

  public setStylistID(stylistID: number): Discount {
    const data: DiscountJSON = this.toJSON();
    data.stylistID = stylistID;
    return new Discount(data);
  }
}
