import { Pipe, PipeTransform } from '@angular/core';
import { Discount } from '../models';
import { UserService } from '../services';
import { Utilities } from '../utilities/utilities';

@Pipe({ name: 'discountLabel' })
export class DiscountPipe implements PipeTransform {
  constructor(private userService: UserService) {}

  transform(discount: Discount): string {
    const currency = this.userService.getSalon() ? this.userService.getSalon().getCurrency() : '';
    const discountValueWithCurrency: string =
      Utilities.decodeHTML(currency) + (Utilities.roundTo2Decimal(discount.amount).toFixed(2) + '');

    const discountValueToDisplay: string = discount.type === 1 ? discount.amount + '%' : discountValueWithCurrency;

    return `${discount.name} (${discountValueToDisplay})`;
  }
}
