import { Booking, Stylist } from '../models';
import {
  CalendarEvent,
  CalendarAvailabilities,
  CalendarEvents
} from '../bookings/calendar/day-view-calendar/day-view-calendar.component';
import { List, Map } from 'immutable';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { Utilities } from '../utilities/utilities';

export interface CalendarData {
  events: CalendarEvents;
  availabilities: CalendarAvailabilities;
}

@Injectable({
  providedIn: 'root'
})
export class CalendarService {
  private lastUpdated: Date;

  private bookingCalendarViewStylist: Stylist;

  private calendarDate: Date;

  private calendarUpdateMonitor = new Subject<boolean>();

  private calendarUpdateMonitor$ = this.calendarUpdateMonitor.asObservable();

  constructor() {
    this.lastUpdated = null;

    this.calendarDate = new Date();

    if (Utilities.isHTM5LocalStorageSupported()) {
      if (localStorage.getItem('smCalendarDate')) {
        const smCalendarDate: string = localStorage.getItem('smCalendarDate');
        const parsedDate: Date = Utilities.parseDate(smCalendarDate);
        if (parsedDate) {
          this.calendarDate = parsedDate;
        }
      }
    }
  }

  public getBookingCalendarViewStylist(): Stylist {
    return this.bookingCalendarViewStylist;
  }

  public getCalendarDate(): Date {
    return this.calendarDate;
  }

  public setBookingCalendarViewStylist(stylist: Stylist) {
    this.bookingCalendarViewStylist = stylist;
  }

  public setCalendarDate(date: Date) {
    this.calendarDate = date;
    if (Utilities.isHTM5LocalStorageSupported()) {
      localStorage.setItem(
        'smCalendarDate',
        Utilities.formatDate(this.calendarDate)
      );
    }
  }

  public update() {
    // set the current datetime
    this.lastUpdated = new Date();
    this.calendarUpdateMonitor.next(true);
  }

  public getCalendarUpdateMonitor(): Observable<boolean> {
    return this.calendarUpdateMonitor$;
  }

  public makeCalendarOutdated() {
    this.lastUpdated = null;
    this.update();
  }

  public isOutdated(): boolean {
    // check if it has been > 15mins
    return (
      this.lastUpdated === null ||
      Utilities.timeDiffInMinutes(new Date(), this.lastUpdated) > 15
    );
  }

  public timeManager(dateRangeStart, dateRangeEnd) {
    const timeDiff = (dateRangeEnd - dateRangeStart) / (1000 * 3600 * 24);
    let xAxisDateChanger = '';
    let yearIndicator = false;

    switch (true) {
      case timeDiff < 11:
        xAxisDateChanger = 'day';
        yearIndicator = false;
        break;
      case timeDiff > 10 && timeDiff < 62:
        xAxisDateChanger = 'week';
        yearIndicator = false;
        break;
      case timeDiff > 61:
        xAxisDateChanger = 'month';
        yearIndicator = true;
        break;
    }
    return { xAxisDateChanger, yearIndicator };
  }
  public groupBookings(
    stylist: Stylist,
    bookings: List<Booking>
  ): CalendarEvents {
    // let newEvents: CalendarEvents = Map.of();
    let newEvents: CalendarEvents = Map();

    bookings.forEach((booking) => {
      const services = booking.getServices();
      const clientName =
        booking.getClient().getFirstName() +
        ' ' +
        (booking.getClient().getLastName()
          ? booking.getClient().getLastName()
          : '');

      services
        .filter(
          (service) =>
            Utilities.isDateValid(service.getStartDateTime()) &&
            Utilities.isDateValid(service.getEndDateTime())
        )
        .filter(
          (service) =>
            service.getStylist() &&
            service.getStylist().getID() === stylist.getID()
        )
        .map((service) => {
          const serviceNAme = service.getServiceDefinition().getServiceName();
          let title: string;
          let content: string;
          let eventClass: string;

          if (service.getType() === 'service') {
            title = clientName;
            content = serviceNAme;
            eventClass = this.getEventClass(booking.getStatus());
          } else {
            title =
              service.getStylistNotes() !== null &&
              service.getStylistNotes() !== undefined &&
              service.getStylistNotes().trim() !== ''
                ? service.getStylistNotes()
                : 'Time Off';

            content = '';
            eventClass = 'timeoff';
          }

          if (service.isBookBack()) {
            // advanced service
            const durations = service.getDurations();

            const event1 = new CalendarEvent({
              id: booking.getId(),
              serviceID: service.getId(),
              service,
              title,
              content,
              startTime: Utilities.cloneDate(service.getStartDateTime()),
              endTime: Utilities.addMinutes(
                Utilities.cloneDate(service.getStartDateTime()),
                durations.startDuration
              ),
              booking,
              allDay: false,
              cssClass: eventClass,
              part: 1
            });

            newEvents = this.placeEvent(newEvents, event1);

            const event2 = new CalendarEvent({
              id: booking.getId(),
              serviceID: service.getId(),
              title,
              content: '... continued',
              startTime: Utilities.addMinutes(
                Utilities.cloneDate(service.getStartDateTime()),
                durations.startDuration + durations.processDuration
              ),
              endTime: Utilities.cloneDate(service.getEndDateTime()),
              service,
              booking,
              allDay: false,
              cssClass: eventClass,
              part: 2
            });

            newEvents = this.placeEvent(newEvents, event2);
          } else {
            // simple service
            const event = new CalendarEvent({
              id: booking.getId(),
              serviceID: service.getId(),
              service,
              title,
              content,
              startTime: Utilities.cloneDate(service.getStartDateTime()),
              endTime: Utilities.cloneDate(service.getEndDateTime()),
              booking,
              allDay: false,
              cssClass: eventClass,
              part: 1
            });

            // newEvents.push(event);
            newEvents = this.placeEvent(newEvents, event);
          }
        });
    });

    return newEvents;
  }

  private placeEvent(
    events: CalendarEvents,
    event: CalendarEvent
  ): CalendarEvents {
    const eventYMD = Utilities.formatDate(event.startTime, 'Y-MM-DD');
    const eventTimeStamp = Utilities.formatDate(event.startTime);
    const dayEvents = events.get(eventYMD);

    if (Map.isMap(dayEvents)) {
      const timeSlotEvents = dayEvents.get(eventTimeStamp);
      if (List.isList(timeSlotEvents)) {
        events = events.setIn(
          [eventYMD, eventTimeStamp],
          timeSlotEvents.push(event)
        );
      } else {
        events = events.setIn([eventYMD, eventTimeStamp], List.of(event));
      }
    } else {
      events = events.setIn([eventYMD, eventTimeStamp], List.of(event));
    }

    return events;
  }

  private getEventClass(status: number): string {
    if (status === 2) {
      return 'requested';
    } else if (status === 3) {
      return 'unconfirmed';
    } else if (
      status === 9 ||
      status === 10 ||
      status === 11 ||
      status === 12
    ) {
      return 'reminded';
    } else if (status === 4) {
      return 'arrived';
    } else if (status === 5) {
      return 'no-show';
    } else if (status === 8) {
      return 'paid';
    } else {
      // invalid
      return '';
    }
  }
}
