import { Cloneable } from '../cloneable.model';
import { Serializable } from '../serializable.model';
import { VacationJSON } from './vacation-json.model';
import { VacationSerialized } from './vacation-serialized.model';

export class Vacation
  implements
    Cloneable<Vacation, VacationJSON>,
    Serializable<VacationSerialized> {
  public readonly id: number;

  public readonly stylistID: number;

  public readonly vacationStartDate: string;

  public readonly vacationEndDate: string;

  constructor(options: VacationJSON) {
    this.id = options.id;
    this.stylistID = options.stylistID;
    this.vacationStartDate = options.vacationStartDate;
    this.vacationEndDate = options.vacationEndDate;
  }

  public static parseVacation(data: Object): Vacation {
    return new Vacation({
      id: data['id'],
      stylistID: data['stylistID'],
      vacationStartDate: data['vacationStartDate'],
      vacationEndDate: data['vacationEndDate']
    });
  }

  public clone(): Vacation {
    return new Vacation(this.toJSON());
  }

  public toJSON(): VacationJSON {
    return {
      id: this.getId(),
      stylistID: this.getStylistID(),
      vacationStartDate: this.getVacationStartDate(),
      vacationEndDate: this.getVacationEndDate()
    };
  }

  public serialize(): VacationSerialized {
    return this.toJSON();
  }

  public getId(): number {
    return this.id;
  }

  public getStylistID(): number {
    return this.stylistID;
  }

  public getVacationStartDate(): string {
    return this.vacationStartDate;
  }

  public getVacationEndDate(): string {
    return this.vacationEndDate;
  }

  public setId(value: number): Vacation {
    const data: VacationJSON = this.toJSON();
    data.id = value;
    return new Vacation(data);
  }

  public setStylistID(value: number): Vacation {
    const data: VacationJSON = this.toJSON();
    data.stylistID = value;
    return new Vacation(data);
  }

  public setVacationStartDate(value: string): Vacation {
    const data: VacationJSON = this.toJSON();
    data.vacationStartDate = value;
    return new Vacation(data);
  }

  public setVacationEndDate(value: string): Vacation {
    const data: VacationJSON = this.toJSON();
    data.vacationEndDate = value;
    return new Vacation(data);
  }
}
