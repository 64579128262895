import { Pipe, PipeTransform } from '@angular/core';
import { Utilities } from '../utilities/utilities';
@Pipe({ name: 'salonMonsterDate' })
export class SalonmonsterDatePipe implements PipeTransform {
  transform(value: Date | string, format: string = 'Y-MM-DD HH:mm:ss'): string {
    if (typeof value === 'string') {
      if (value.length <= 10) {
        value = this._formatToISO(value);
      }
      const parseDateValue = new Date(value);
      if (!isNaN(parseDateValue.getTime())) {
        value = parseDateValue;
      } else {
        return value;
      }
    }

    // Format the value as a Date
    return Utilities.formatDate(value, format);
  }

  private  _formatToISO(dateString) {
    return dateString + "T00:00:00"; // Constructed in the 'YYYY-MM-DDT00:00:00' format
}
}

