import { Cloneable } from '../cloneable.model';
import { OnBoardingJSON } from './on-boarding-json.model';
import { OnBoardingSerialized } from './on-boarding-serialized.model';
import { Serializable } from '../serializable.model';

export class OnBoarding
  implements
    Cloneable<OnBoarding, OnBoardingJSON>,
    Serializable<OnBoardingSerialized> {
  public readonly id: number;

  public readonly personalDetails: number;

  public readonly businessDetails: number;

  public readonly hours: number;

  public readonly services: number;

  public readonly importClients: number;

  public readonly shareOnlineBookingPage: number;

  public readonly stylistID: number;

  public readonly onboarding: number;

  constructor(options: OnBoardingJSON) {
    this.id = options.id;
    this.personalDetails = options.personalDetails;
    this.businessDetails = options.businessDetails;
    this.hours = options.hours;
    this.services = options.services;
    this.importClients = options.importClients;
    this.shareOnlineBookingPage = options.shareOnlineBookingPage;
    this.stylistID = options.stylistID;
    this.onboarding = options.onboarding;
  }

  public static parse(params: Object): OnBoarding {
    return new OnBoarding({
      id: params['id'],
      personalDetails: params['personalDetails'],
      businessDetails: params['businessDetails'],
      hours: params['hours'],
      services: params['services'],
      importClients: params['importClients'],
      shareOnlineBookingPage: params['shareOnlineBookingPage'],
      stylistID: params['stylistID'],
      onboarding: params['onboarding']
    });
  }

  public toJSON(): OnBoardingJSON {
    return {
      id: this.id,
      personalDetails: this.personalDetails,
      businessDetails: this.businessDetails,
      hours: this.hours,
      services: this.services,
      importClients: this.importClients,
      shareOnlineBookingPage: this.shareOnlineBookingPage,
      stylistID: this.stylistID,
      onboarding: this.onboarding
    };
  }

  public clone(): OnBoarding {
    return new OnBoarding(this.toJSON());
  }

  public serialize(): OnBoardingSerialized {
    return this.toJSON();
  }

  public setPersonalDetails(value: number): OnBoarding {
    const data: OnBoardingJSON = this.toJSON();
    data.personalDetails = value;
    return new OnBoarding(data);
  }

  public setBusinessDetails(value: number): OnBoarding {
    const data: OnBoardingJSON = this.toJSON();
    data.businessDetails = value;
    return new OnBoarding(data);
  }

  public setHours(value: number): OnBoarding {
    const data: OnBoardingJSON = this.toJSON();
    data.hours = value;
    return new OnBoarding(data);
  }

  public setServices(value: number): OnBoarding {
    const data: OnBoardingJSON = this.toJSON();
    data.services = value;
    return new OnBoarding(data);
  }

  public setImportClients(value: number): OnBoarding {
    const data: OnBoardingJSON = this.toJSON();
    data.importClients = value;
    return new OnBoarding(data);
  }

  public setSharedOnlinePage(value: number): OnBoarding {
    const data: OnBoardingJSON = this.toJSON();
    data.shareOnlineBookingPage = value;
    return new OnBoarding(data);
  }

  public setOnboarding(value: number): OnBoarding {
    const data: OnBoardingJSON = this.toJSON();
    data.onboarding = value;
    return new OnBoarding(data);
  }
}
