import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';

import { ENV_CONFIG } from 'src/bin/env.config';
import { ErrorHandlerService } from './error-handler.service';
import { Injectable } from '@angular/core';
import { List } from 'immutable';
import { Observable } from 'rxjs';
import { SalonmonsterHttpClient } from '../services/salonmonster-http-client';
import { UserService } from '../services/user.service';
import { map } from 'rxjs/operators';

export interface CustomizedEmails {
  bookingRequest: string;
  bookingConfirmation: string;
  bookingCancelled: string;
  bookingReminder: string;
}

@Injectable({
  providedIn: 'root'
})
export class CustomizedEmailService extends SalonmonsterHttpClient {
  constructor(
    http: HttpClient,
    userService: UserService,
    protected errorHandlerService: ErrorHandlerService
  ) {
    super(http, userService, errorHandlerService);
  }

  public loadSalonCustomizedEmails(): Observable<List<CustomizedEmails>> {
    const url = `${ENV_CONFIG.API_ROOT}/customizedemails/salons/me`;
    return this.get(url).pipe(
      map((data) => {
        let customizedEmails: List<CustomizedEmails> = List([]);

        for (const customizedEmailData of data) {
          customizedEmails = customizedEmails.push({
            bookingRequest: customizedEmailData.bookingRequest,
            bookingConfirmation: customizedEmailData.bookingConfirmation,
            bookingCancelled: customizedEmailData.bookingCancelled,
            bookingReminder: customizedEmailData.bookingReminder
          });
        }

        return customizedEmails;
      })
    );
  }

  public loadStylistCustomizedEmails(
    stylistID: number
  ): Observable<List<CustomizedEmails>> {
    const url = `${ENV_CONFIG.API_ROOT}/customizedemails/stylists/${stylistID}`;
    return this.get(url).pipe(
      map((data) => {
        let customizedEmails: List<CustomizedEmails> = List([]);

        for (const customizedEmailData of data) {
          customizedEmails = customizedEmails.push({
            bookingRequest: customizedEmailData.bookingRequest,
            bookingConfirmation: customizedEmailData.bookingConfirmation,
            bookingCancelled: customizedEmailData.bookingCancelled,
            bookingReminder: customizedEmailData.bookingReminder
          });
        }

        return customizedEmails;
      })
    );
  }

  public saveSalonCustomizedEmails(
    customizedEmail: CustomizedEmails
  ): Observable<CustomizedEmails> {
    const url = `${ENV_CONFIG.API_ROOT}/customizedemails/salons/me`;

    return this.put(url, customizedEmail);
  }

  public saveStylistCustomizedEmails(
    stylistID: number,
    customizedEmail: CustomizedEmails
  ): Observable<CustomizedEmails> {
    const url = `${ENV_CONFIG.API_ROOT}/customizedemails/stylists/${stylistID}`;

    return this.put(url, customizedEmail);
  }
}
