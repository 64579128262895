import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { Utilities } from '../../../utilities/utilities';
import { Booking, Service, Stylist } from '../../../models';
import { UserService } from '../../../services/user.service';
import { CalendarCacheService } from '../../../services/calendar-cache.service';
import { TimeSlot } from '../time-slot/time-slot.component';
import { Map, List, Record } from 'immutable';
import { ViewWillEnter } from '@ionic/angular';

import { polyfill } from 'mobile-drag-drop';

export interface CalendarEvents extends Map<string, Map<string, List<CalendarEvent>>>{};

export interface CalendarDayViewAvailability extends Map<string, boolean>{};

export interface CalendarAvailabilities extends Map<string, CalendarDayViewAvailability>{};

const Event = Record({
  id: undefined,
  serviceID: undefined,
  title: '',
  content: '',
  startTime: undefined,
  endTime: undefined,
  service: undefined,
  booking: undefined,
  allDay: false,
  cssClass: '',
  part: 1
});

export interface ICalendarEventOpts {
  id: number;
  serviceID: number;
  title: string;
  content: string;
  startTime: Date;
  endTime: Date;
  service: Service;
  booking: Booking;
  allDay: boolean;
  cssClass: string;
  part: number;
}

export class CalendarEvent extends Event {
  public readonly id: number;
  public readonly serviceID: number;
  public readonly title: string;
  public readonly content: string;
  public readonly startTime: Date;
  public readonly endTime: Date;
  public readonly service: Service;
  public readonly booking: Booking;
  public readonly allDay: boolean;
  public readonly cssClass: string;
  public readonly part: number;

  constructor (params: ICalendarEventOpts) {
    super(params);
  }
}

const DayViewConfigRecord = Record({
  date: undefined,
  events: undefined,
  availabilities: undefined,
  stylist: undefined,
  hideTimeSlotLabels: false,
  showLeftMinuteLabels: false,
  width: '100 px',
  muteEventTappability: false
});

interface IDayViewConfigOpts {
  date: Date;
  events: CalendarEvents;
  availabilities: CalendarDayViewAvailability;
  stylist: Stylist,
  hideTimeSlotLabels: boolean,
  showLeftMinuteLabels: boolean,
  width: string,
  muteEventTappability?: boolean
}



export class DayViewConfig extends DayViewConfigRecord {
  public readonly date: Date;
  public readonly events: CalendarEvents;
  public readonly availabilities: CalendarDayViewAvailability;
  public readonly stylist: Stylist;
  public readonly hideTimeSlotLabels: boolean;
  public readonly showLeftMinuteLabels: boolean;
  public readonly width: string;
  public readonly muteEventTappability: boolean;

  constructor (params: IDayViewConfigOpts) {
    super(params);
  }

  public setDate (d: Date): DayViewConfig {
    return <DayViewConfig> this.set('date', d);
  }
  

  public setEvents (events: CalendarEvents): DayViewConfig {
    return <DayViewConfig> this.set('events', events);
  }

  public setAvailabilities (availabilities: CalendarDayViewAvailability): DayViewConfig {
    return <DayViewConfig> this.set('availabilities', availabilities);
  }

  public setStylist (s: Stylist): DayViewConfig {
    return <DayViewConfig> this.set('stylist', s);
  }
  public setWidth (w: string): DayViewConfig {
    return <DayViewConfig> this.set('width', w);
  }


  public setHideTimeSlotLabels (val: boolean): DayViewConfig {
    return <DayViewConfig> this.set('hideTimeSlotLabels', val);
  }

  public setMuteEventTappability (val: boolean): DayViewConfig {
    return <DayViewConfig> this.set('muteEventTappability', val);
  }
}

export interface TimeSlotEvent {
  date: Date;
  stylist: Stylist
}

export interface SMDragEndEvent { calendarEvent: CalendarEvent, newDate: Date }

@Component({
  selector: 'smr-day-view-calendar',
  templateUrl: './day-view-calendar.component.html',
  styleUrls: ['./day-view-calendar.component.scss'],
})
export class DayViewCalendarComponent implements OnInit, OnChanges, ViewWillEnter {
  @Input() config: DayViewConfig;

  @Output() onEventSelected = new EventEmitter<CalendarEvent>();

  @Output() onTimeSelected = new EventEmitter<TimeSlotEvent>();

  @Output() onEventDragEnd = new EventEmitter<SMDragEndEvent>();

  @Output() eventsDidUpdate = new EventEmitter<boolean>();

  public inited:boolean = false;
  
  public view: List<TimeSlot>;
  
  constructor(private userService: UserService,
            private calendarCacheService: CalendarCacheService) {
    this.view = List([]);
    polyfill({
      holdToDrag: 300
    });
  }

  ionViewWillEnter() {
    this.refreshView();
  }

  ngOnInit () {
    this.inited = true;

    this.view = this.calendarCacheService.getCachedTimeslots();
    this.refreshView();
  }

  ngOnChanges (changes) {

    if (!this.inited) {
        return;
    }
    
    const configChange = changes['config'];
    if (configChange && configChange.currentValue) {
      this.refreshView();
    }
  }

  public refreshView () {
    const viewDate = this.config.date;
    let view = this.view;
    let len = view.count();
    let timestamp: string = Utilities.formatDate(viewDate, 'Y-MM-DD');
    let events: Map<string, List<CalendarEvent>> = Map({'': List.of<CalendarEvent>()});

    if (!view) {
      // return;
    }

    if (this.config.events &&
        this.config.events.get(timestamp) &&
        Map.isMap(this.config.events.get(timestamp))) {
      events = this.config.events.get(timestamp);
    }
    
    // loop through each timestamp for this view and set availability, events
    let timeSlotDate = new Date(viewDate.getTime());
    timeSlotDate.setSeconds(0);

    for (let timeSlotIndex = len - 1; timeSlotIndex >= 0; timeSlotIndex--) {
      let timeslot: TimeSlot = view.get(timeSlotIndex);
      const hour: number = timeslot.hour;
      const minutes: number = timeslot.minutes;
      let eventsOverlap:number = 0;
      let eventsStartIndex:number = 0;
      let timeSlotTimeStamp: string;
      let timeSlotEvents: List<CalendarEvent> = List<CalendarEvent>();
      
      timeSlotDate.setHours(hour);
      timeSlotDate.setMinutes(minutes);
      timeSlotTimeStamp = Utilities.formatDate(timeSlotDate);
      
      if (events) {
        timeSlotEvents = events.get(timeSlotTimeStamp);
      }      

      timeslot = this.resetTimeslot(timeSlotDate, timeslot);

      // update this timeslot
      view = view.update(timeSlotIndex, (ts) => {
        return timeslot
      });
  
      if (!List.isList(timeSlotEvents)) {
        timeSlotEvents = List([]);
      }

      eventsOverlap = timeSlotEvents.count();

      // loop through each events
      let overlapToApplyToThisTimeSlot: number = 0;
      for (let eventIndex = 0; eventIndex < timeSlotEvents.count(); eventIndex++) {
        const event: CalendarEvent = timeSlotEvents.get(eventIndex);
        const totalServiceDuration: number = Utilities.timeDiffInMinutes(event.startTime, event.endTime);
        const timeSotsToNavigate = timeSlotIndex + totalServiceDuration / 15;

        // update the timeslots below this event
        for (let j = timeSlotIndex + 1; j < timeSotsToNavigate; j++) {

          // check if this timeslot has at least one events
          const ts: TimeSlot = view.get(j);
          if (ts && ts.events && ts.events.count() > 0) {

            // update our current timeslot's eventsOverlap so its width can be properly calculated
            // by the timeslot.component
            // IMPORTANT FIX: Only apply overlap if thee `previous count` has CHANGED
            // If an event overlaps with 7 bookings, it doesn't have to be 1/7 width
            if (ts.eventsOverlap > overlapToApplyToThisTimeSlot) {

              // update the previous count
              overlapToApplyToThisTimeSlot += ts.eventsOverlap;
            }
          }

          if (j >= view.count()) {
            continue;
          }

          view = view.update(j, (timeslot) => {
            return timeslot
                    .setEventsOverlap(timeslot.eventsOverlap + 1)
                    .setEventsStartIndex(timeslot.eventsStartIndex + 1);
          });    
        }
      }

      eventsOverlap += overlapToApplyToThisTimeSlot;

      // update this timeslot
      view = view.update(timeSlotIndex, (timeslot) => {
              return timeslot
                .setEvents(timeSlotEvents)
                .setEventsOverlap(eventsOverlap )
                .setEventsStartIndex(eventsStartIndex);
      });
    }

    this.view = view;
    this.eventsDidUpdate.emit(true);
  }

  public resetTimeslot (timeSlotDate: Date, timeslot: TimeSlot): TimeSlot {
    let isAvailable: boolean = this.isTimeSlotAvailable(timeSlotDate);

    // skip updateing this row if it hasn't changed for optimization (to prevent it from re-rendering)
    if (timeslot.isAvailable === isAvailable && 
      timeslot.eventsOverlap === 0 && 
      timeslot.eventsStartIndex === 0 && 
      timeslot.events.count() === 0 && 
      timeslot.hideLabels === this.config.hideTimeSlotLabels &&
      timeslot.showLeftMinuteLabels === this.config.showLeftMinuteLabels &&
      timeslot.muteEventTappability === this.config.muteEventTappability) {
      return timeslot;
    }
    
    return timeslot
            .setAvailable(isAvailable)
            .setEvents(List([]))
            .setEventsOverlap(0)
            .setEventsStartIndex(0)
            .setHideLabels(this.config.hideTimeSlotLabels)
            .setShowLeftMinuteLabels(this.config.showLeftMinuteLabels)
            .setMuteEventTappability(this.config.muteEventTappability);
  }


  public resetViewTimeslots (viewDate: Date): List<TimeSlot> {
    let view = this.view;
    let len = view.count();

    const timeSlotDate = new Date(viewDate.getTime());
    timeSlotDate.setSeconds(0);
    for (let timeSlotIndex = 0; timeSlotIndex < len; timeSlotIndex++) {
      const timeslot = view.get(timeSlotIndex);
      const hour: number = timeslot.get('hour');
      const minutes: number = timeslot.get('minutes');
      let isAvailable: boolean = true;
      timeSlotDate.setHours(hour);
      timeSlotDate.setMinutes(minutes);
      isAvailable = this.isTimeSlotAvailable(timeSlotDate);

      // skip updateing this row if it hasn't changed for optimization (to prevent it from re-rendering)
      if (timeslot.isAvailable === isAvailable && 
        timeslot.eventsOverlap === 0 && 
        timeslot.eventsStartIndex === 0 && 
        timeslot.events.count() === 0 && 
        timeslot.hideLabels === this.config.hideTimeSlotLabels &&
        timeslot.showLeftMinuteLabels === this.config.showLeftMinuteLabels) {
        continue;
      }

      
      view = view.update(timeSlotIndex, (timeslot) => {
        return timeslot
              .setAvailable(isAvailable)
              .setEvents(List([]))
              .setEventsOverlap(0)
              .setEventsStartIndex(0)
              .setHideLabels(this.config.hideTimeSlotLabels)
              .setShowLeftMinuteLabels(this.config.showLeftMinuteLabels);
      });
    }
    return view;
  }

  public getTitle () {
    return Utilities.formatDate(this.config.date, 'dddd, MMMM D, Y');
  }

  public onTimeSlotSelectedHanadler (timeslot: TimeSlot) {
    const currentCalendarDate = Utilities.cloneDate(this.config.date);
    currentCalendarDate.setHours(timeslot.hour);
    currentCalendarDate.setMinutes(timeslot.minutes);
    currentCalendarDate.setSeconds(0);
    this.onTimeSelected.emit({
      date: currentCalendarDate,
      stylist: this.config.stylist
    });
  }

  public onEventSelectedHandler (event: CalendarEvent) {
    this.onEventSelected.emit(event);
    this.eventsDidUpdate.emit(true);
  }

  public isTimeSlotAvailable (timeslot: Date) : boolean {
    // const unix = Utilities.formatDate(timeslot, 'X');
    const timeslotTime = Utilities.formatDate(timeslot, 'HH:mm');
    
    if (timeslotTime) {
      return (this.config.availabilities && this.config.availabilities.has(timeslotTime) && this.config.availabilities.getIn([timeslotTime]) === true);
    } else {
      return false;
    }
  }

  public onDropSuccess ($event: { dragData: CalendarEvent, mouseEvent: any }, tm: TimeSlot) {
    const date: Date = this.config.date;
    date.setHours(tm.get('hour'));
    date.setMinutes(tm.get('minutes'));
    date.setSeconds(0);

    this.onEventDragEnd.emit({ calendarEvent: $event.dragData, newDate: date})
  }

  public trackByFn (index, item) {

    //src: https://github.com/angular/angular/issues/10182
    // src: https://netbasal.com/angular-2-improve-performance-with-trackby-cc147b5104e5
    return index; // or item.id
  }

  // prevent default on some drag events on dnd-droppable (important!)
  // src: https://github.com/akserg/ng2-dnd/issues/9
  // https://github.com/timruffles/ios-html5-drag-drop-shim#polyfill-requires-dragenter-listener
  public preventDefault (event) {
    event.mouseEvent.preventDefault();
    return false;
  }
}