import { Buffer } from "buffer";
Buffer.from("anything", "base64");
window.Buffer = window.Buffer || require("buffer").Buffer;

import { Injectable } from '@angular/core';

import S3 from 'aws-s3';
import imageCompression from 'browser-image-compression';
import * as dataURLtoBlob from 'blueimp-canvas-to-blob';
import { UserService } from '../services/user.service';

export enum S3DirName {
  CUSTOM_EMAIL_BOOKING_REQUEST = 'custom-email-booking-request',
  CUSTOM_EMAIL_BOOKING_CONFIRMATION = 'custom-email-booking-confirmation',
  CUSTOM_EMAIL_BOOKING_CANCELLED = 'custom-email-booking-cancelled',
  CUSTOM_EMAIL_BOOKING_REMINDER = 'custom-email-booking-reminder',
  MARKETING_EMAIL = 'marketing-email'
}

@Injectable({
  providedIn: 'root'
})
export class AwsBucketManagerProvider {
  s3Config: any;
  imageCompressorOptions: any;
  userID: number;

  constructor(private userService: UserService) {
    this.setupEmail();
    this.setupConfig();
  }

  private setupEmail() {
    this.userID = this.userService.getUser().getID();
  }

  private setupConfig() {
    this.s3Config = {
      bucketName: 'photos.salonmonster.com',
      region: 'ca-central-1',
      accessKeyId: 'AKIAQUXGUBVUGD63Z54E',
      secretAccessKey: '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',
      s3Url: 'https://photos.salonmonster.com.s3.amazonaws.com',
      dirName: ''
    };

    this.imageCompressorOptions = {
      maxSizeMB: 0.7
    };
  }

  private uploadImage(image, dir: S3DirName) {
    this.s3Config.dirName = dir + '/' + this.userID;
    const S3Client = new S3(this.s3Config);

    return S3Client.uploadFile(image);
  }

  private getBase64(html): string[] {
    const frag = document.createElement('div');
    frag.innerHTML = html;

    return Array.from(frag.querySelectorAll('img'))
      .filter((img) => img.getAttribute('src').startsWith('data'))
      .map((img) => img.getAttribute('src'));
  }

  extractBase64AndUpload(html: string, dir: S3DirName): Promise<string> {
    const imageList = this.getBase64(html);

    const promiseArray: Promise<string>[] = [];

    imageList.forEach((image, index) => {
      const blob = dataURLtoBlob(image);

      const promise = imageCompression(blob, this.imageCompressorOptions).then(
        (resizedBlob) => {
          return this.uploadImage(resizedBlob, dir).then((data) => {
            return { imageUrl: data.location, index };
          });
        }
      );
      promiseArray.push(promise);
    });

    return Promise.all(promiseArray).then((responses: any[]) => {
      let _html = html;
      responses.forEach((response) => {
        _html = _html.replace(imageList[response.index], response.imageUrl);
      });
      return _html;
    });
  }
}
