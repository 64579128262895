import {
  Activity,
  Availability,
  Client,
  Discount,
  Stylist
} from '../../app/models';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';

import { ENV_CONFIG } from '../../bin/env.config';
import { ErrorHandlerService } from './error-handler.service';
import { CalendarAvailabilities } from '../bookings/calendar/day-view-calendar/day-view-calendar.component';
import { Injectable } from '@angular/core';
import { List } from 'immutable';
import { Observable, of, throwError } from 'rxjs';
import { SalonmonsterHttpClient } from '../services/salonmonster-http-client';
import { UserService } from '../services/user.service';
import { catchError, map, mapTo } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StylistService extends SalonmonsterHttpClient {
  private stylists: List<Stylist>;

  private isOutdated: boolean;

  public emailCheckerStylistID: number;
  private activityLastSavedToDb: number;
  constructor(
    http: HttpClient,
    userService: UserService,
    protected errorHandlerService: ErrorHandlerService
  ) {
    super(http, userService, errorHandlerService);
    this.stylists = List.of<Stylist>();

    userService.getLogOutMonitor().subscribe((logout) => {
      if (logout) {
        this.stylists = List([]);
        this.isOutdated = true;
        this.emailCheckerStylistID = 0;
      }
    });
  }

  public setEmailCheckerStylistID(stylistID: number) {
    this.emailCheckerStylistID = stylistID;
  }

  public setStylist(stylist: Stylist) {
    let found = false;
    let index = 0;

    this.stylists.find((value: Stylist) => {
      if (stylist.id === value.id) {
        found = true;
        return true;
      }

      index++;
      return false;
    });

    if (found) {
      this.stylists = this.stylists.set(index, stylist);
    }
  }

  public loadStylistInfo(stylistID: number): Observable<Stylist> {
    const url = `${ENV_CONFIG.API_ROOT}/stylists/${stylistID}`;
    return this.get(url).pipe(
      map((data) => {
        return Stylist.parseStylistData(data[0]);
      }),
      catchError((err) => {
        this.isOutdated = true;
        return throwError(err);
      })
    );
  }

  public getStylists(): Observable<List<Stylist>> {
    const url = `${ENV_CONFIG.API_ROOT}/salons/stylists`;

    if (this.stylists.count() === 0 || this.isOutdated) {
      return this.get(url).pipe(
        map((data) => {
          let stylists: List<Stylist> = List.of<Stylist>();

          for (const stylistData of data) {
            stylists = stylists.push(Stylist.parseStylistData(stylistData));
          }

          this.stylists = stylists;
          this.isOutdated = false;
          return this.stylists;
        }),
        catchError((err) => {
          this.isOutdated = true;
          return throwError(err);
        })
      );
    } else {
      // return cached stylists
      return of(this.stylists);
    }
  }

  public setOutdated() {
    this.isOutdated = true;
  }

  public getClients(
    stylist: Stylist,
    page: number = 1,
    useStylistSettings: boolean = false
  ): Observable<List<Client>> {
    if (stylist.getID() === 0) {
      useStylistSettings = true;
    }

    const url = `${ENV_CONFIG.API_ROOT}/stylists/${
      stylist.getID() !== 0 ? stylist.id : this.userService.getUser().getID()
    }/clients?page=${page}&useStylistSettings=${useStylistSettings}`;
    return this.get(url).pipe(
      map((data) => {
        let clients: List<Client> = List([]);
        for (const clientData of data) {
          clients = clients.push(Client.parseClientData(clientData));
        }
        return clients;
      })
    );
  }

  public getAvailabilities(
    stylist: Stylist,
    year: string,
    month: string
  ): Observable<CalendarAvailabilities> {
    const url = `${
      ENV_CONFIG.API_ROOT
    }/stylists/${stylist.getID()}/availabilities?year=${year}&month=${month}&startDuration=0&processDuration=0&finishDuration=0&ignoreBookings=true`;
    return this.get(url).pipe(
      map((data) => {
        return Availability.parseAvailabilityData(data);
      })
    );
  }

  public getActivities(stylist: Stylist): Observable<List<Activity>> {
    const url = `${ENV_CONFIG.API_ROOT}/stylists/${stylist.getID()}/activities`;

    return this.get(url).pipe(
      map((data) => {
        let response: List<Activity> = List.of<Activity>();
        for (const activity of data) {
          response = response.push(Activity.parseActivityData(activity));
        }
        return response;
      })
    );
  }

  public getDiscounts(stylist: Stylist): Observable<List<Discount>> {
    const url = `${ENV_CONFIG.API_ROOT}/stylists/${stylist.getID()}/discounts`;

    return this.get(url).pipe(
      map((data) => {
        let response: List<Discount> = List.of<Discount>();
        for (const discount of data) {
          response = response.push(Discount.parseDiscount(discount));
        }
        return response;
      })
    );
  }

  public saveStylistDiscount(discount: Discount): Observable<Discount> {
    let url = `${ENV_CONFIG.API_ROOT}/stylists/${discount.stylistID}/discounts`;

    if (discount.id) {
      url += '/' + discount.id;

      return this.put(url, discount.serialize()).pipe(
        map((data) => {
          return Discount.parseDiscount(data);
        })
      );
    } else {
      return this.post(url, discount.serialize()).pipe(
        map((data) => {
          return Discount.parseDiscount(data);
        })
      );
    }
  }

  public deleteStylistDiscounts(discount: Discount): Observable<Discount> {
    const url = `${ENV_CONFIG.API_ROOT}/stylists/${discount.stylistID}/discounts/${discount.id}`;
    return this.delete(url, {}).pipe(
      map((data) => {
        return Discount.parseDiscount(data);
      })
    );
  }

  public saveStylist(stylist: Stylist): Observable<Stylist> {
    let url = `${ENV_CONFIG.API_ROOT}/stylists`;
    if (stylist.getID()) {
      url += '/' + stylist.getID();
      return this.put(url, stylist.serialize()).pipe(
        map((data) => {
          return Stylist.parseStylistData(data);
        })
      );
    } else {
      return this.post(url, stylist.serialize()).pipe(
        map((data) => {
          return Stylist.parseStylistData(data);
        })
      );
    }
  }

  public deleteStylist(stylist: Stylist): Observable<boolean> {
    const url = `${ENV_CONFIG.API_ROOT}/stylists/${stylist.getID()}`;
    return this.delete(url, {}).pipe(
      map((data) => {
        return true;
      })
    );
  }

  public updateStylist(stylist: Stylist) {
    let index = 0;
    let stylistFound = false;

    this.stylists.some((s) => {
      if (s.getID() === stylist.getID()) {
        stylistFound = true;
        return true;
      }

      index++;
      return false;
    });

    this.stylists = stylistFound
      ? this.stylists.setIn([index], stylist)
      : this.stylists.push(stylist);
  }

  public changePassword(
    stylist: Stylist,
    currentPassword: string,
    newPassword: string
  ): Observable<Stylist> {
    const url = `${
      ENV_CONFIG.API_ROOT
    }/stylists/${stylist.getID()}/changepassword`;
    const params = {
      currentPassword,
      newPassword
    };

    return this.post(url, params);
  }

  public requestPasswordReset(email: string): Observable<boolean> {
    const url = `${ENV_CONFIG.API_ROOT}/passwordreset/stylist/request`;
    return this.post(url, {
      email
    }).pipe(mapTo(true));
  }

  public checkPasswordResetTokenValidity(token: string): Observable<boolean> {
    const url = `${ENV_CONFIG.API_ROOT}/passwordreset/validity`;
    return this.post(url, {
      token
    });
  }

  public recordStylistActivity( stylistID: number, page: string ) {
    // record the user's login time to the db every 5 minutes
    if(isNaN(this.activityLastSavedToDb)){
      this.activityLastSavedToDb = Date.now();
      this.saveStylistActivityToDB(stylistID);
    }
    if((Date.now() - this.activityLastSavedToDb) > 300000){
      this.activityLastSavedToDb = 0;
      this.saveStylistActivityToDB(stylistID);
    } else {
    }
  }
  public saveStylistActivityToDB( stylistID: number) {
    const url = `${ENV_CONFIG.API_ROOT}/stylists/${stylistID}/recordstylistactivity`;
    let body = { stylistID: stylistID};
    this.put(url, body)
    .pipe(map((res: Response) => {
      //const body = res.json();
     // const data = body.data;
      return res;
    })
    ).subscribe({
      next:(data) => {
    },
    error: (err) => {
    }
  });
  }
  public updateStylistIntercomConfiguration(stylistID: number, isIntercomeEnabled: boolean) {
    const url = `${ENV_CONFIG.API_ROOT}/stylists/${stylistID}/changeIntercom`;
    let body = { stylistID: stylistID, IsChatBubbleEnabled: isIntercomeEnabled};
    return this.post(url, body)
    .pipe(map((res) => {
      return res;
    })
    )
  }
  public passwordReset(
    token: string,
    newPassword: string
  ): Observable<boolean> {
    const url = `${ENV_CONFIG.API_ROOT}/passwordreset/stylist`;
    return this.post(url, {
      token,
      password: newPassword
    }).pipe(mapTo(true));
  }

  public changePhoto(
    stylist: Stylist,
    base64Image: string
  ): Observable<Stylist> {
    const url = `${
      ENV_CONFIG.API_ROOT
    }/stylists/${stylist.getID()}/changephoto`;
    const params = {
      image: base64Image
    };

    return this.post(url, params).pipe(
      map((data) => {
        return Stylist.parseStylistData(data);
      })
    );
  }

  // public registerDeviceForNotification (deviceToken: string) : Observable <boolean> {
  //   return new Observable <boolean> (observer => {
  //     const url = `${ENV_CONFIG.API_ROOT}/stylists/notifications/register`;
  //     const params = {
  //       deviceToken: deviceToken
  //     };

  //     this.post(url, params)
  //       .pipe(map((res: Response) => {
  //         const body = res.json();
  //         return body && body.success === true;
  //       })
  //       ).subscribe((success: boolean) => {
  //         observer.next(success);
  //         observer.complete();
  //       },
  //       (err) => {
  //         observer.error(this.errorHandlerService.handleError(err));
  //         observer.complete();
  //       });
  //   });
  // }

  // public unRegisterDeviceForNotification (): Observable <boolean> {
  //   return new Observable <boolean> (observer => {
  //     const url = `${ENV_CONFIG.API_ROOT}/stylists/notifications/unregister`;
  //     const params = {};

  //     this.post(url, params)
  //       .pipe(map((res: Response) => {
  //         const body = res.json();
  //         return body && body.success === true;
  //       })
  //       ).subscribe((success: boolean) => {
  //         observer.next(success);
  //         observer.complete();
  //       },
  //       (err) => {
  //         observer.error(this.errorHandlerService.handleError(err));
  //         observer.complete();
  //       });
  //   });
  // }

  public emailChecker(email: string): Observable<boolean> {
    let url = `${ENV_CONFIG.API_ROOT}/stylists/emailchecker/${email}`;

    if (this.emailCheckerStylistID) {
      url += `?stylistID=${this.emailCheckerStylistID}`;
    }

    return this.get(url).pipe(
      map((data) => {
        const available: boolean = data.available;
        return available;
      })
    );
  }
}
