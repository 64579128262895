import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';

import { CalendarService } from '../services/calendar.service';
import { ENV_CONFIG } from '../../bin/env.config';
import { ErrorHandlerService } from './error-handler.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StylistService } from '../services/stylist.service';
import { catchError, map } from 'rxjs/operators';

export interface Auth {
  salon: any;
  token: string;
  user: any;
}

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(
    private http: HttpClient,
    private stylistService: StylistService,
    private calendarService: CalendarService,
    protected errorHandlerService: ErrorHandlerService
  ) {}

  public loginUser(username: string, password: string): Observable<Auth> {
    const apiURL = ENV_CONFIG.API_ROOT + '/auth';
    const body = {
      username,
      password
    };

    // make stylist service outdated to reload the cache
    this.stylistService.setOutdated();
    this.calendarService.makeCalendarOutdated();
    this.calendarService.setBookingCalendarViewStylist(undefined);

    return this.http
      .post<{ data: { salon: any; token: any; user: any } }>(apiURL, body)
      .pipe(
        map((response) => {
          const { data } = response;
          return data;
        })
      );
  }

  public loginFacebookUser(
    fbID: string,
    email?: string
  ): Observable<any | Auth> {
    const apiURL = ENV_CONFIG.API_ROOT + '/auth/stylists/facebook';
    const body = {
      fbID,
      email
    };

    // make stylist service outdated to reload the cache
    this.stylistService.setOutdated();

    return this.http
      .post<{ data: { salon: any; token: any; user: any } }>(apiURL, body)
      .pipe(
        map((result) => result.data as Auth),
        catchError((error) => this.errorHandlerService.handleError(error))
      );
  }
}
