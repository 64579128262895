import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { List } from 'immutable';
import { ENV_CONFIG } from 'src/bin/env.config';
import { SalonmonsterHttpClient } from '../services/salonmonster-http-client';
import { ErrorHandlerService } from './error-handler.service';
import { UserService } from './user.service';

import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

export interface ChargifyCustomer {
  first_name: string;
  last_name: string;
  email: string;
  cc_emails: string;
  organization: string;
  reference: string;
  id: number;
  created_at: string;
  updated_at: string;
  address: string;
  address_2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  phone: string;
  verified: boolean;
  tax_exempt: boolean;
}

export interface ChargifyCreditCard {
  id: number;
  payment_type: string;
  first_name: string;
  last_name: string;
  masked_card_number: string;
  card_type: string;
  expiration_month: number;
  expiration_year: number;
  billing_address: string;
  billing_address_2: string;
  billing_city: string;
  billing_state: string;
  billing_country: string;
  billing_zip: string;
  current_vault: string;
  vault_token: string;
  customer_vault_token: string;
  customer_id: number;
}

export interface ChargifyProduct {
  id: number;
  name: string;
  handle: string;
  description: string;
  accounting_code: string;
  price_in_cents: number;
  interval: number;
  interval_unit: string;
  initial_charge_in_cents: number;
  expiration_interval: number;
  expiration_interval_unit: string;
  trial_price_in_cents: number;
  trial_interval: number;
  trial_interval_unit: string;
  initial_charge_after_trial: boolean;
  return_params: string;
  request_credit_card: boolean;
  require_credit_card: boolean;
  created_at: string;
  updated_at: string;
  archived_at: string;
  update_return_url: string;
  tax_code: string;
  update_return_params: string;
  product_family: {
    id: number;
    name: string;
    description: string;
    handle: string;
  };
  taxable: boolean;
  version_number: number;
}

export interface ChargifySubscription {
  id: number;
  state: string;
  balance_in_cents: number;
  total_revenue_in_cents: number;
  product_price_in_cents: number;
  product_version_number: number;
  current_period_ends_at: string;
  next_assessment_at: string;
  trial_started_at: string;
  trial_ended_at: string;
  activated_at: string;
  expires_at: string;
  created_at: string;
  updated_at: string;
  cancellation_message: string;
  cancellation_method: string;
  cancel_at_end_of_period: boolean;
  canceled_at: string;
  current_period_started_at: string;
  previous_state: string;
  signup_payment_id: number;
  signup_revenue: string;
  delayed_cancel_at: string;
  coupon_code: string;
  payment_collection_method: string;
  snap_day: string;
  customer: ChargifyCustomer;
  product: ChargifyProduct;
  credit_card: ChargifyCreditCard;
  payment_type: string;
  referral_code: string;
  reason_code: string;
}

export interface ChargifyTransaction {
  subscription: ChargifySubscription;
}

export interface ChargifyNewSubscriptionParams {
  handle: string;
  firstName: string;
  lastName: string;
  email: string;
  full_number: number;
  expiration_month: number;
  expiration_year: number;
  card_type: string;
  billing_address: string;
  billing_address_2: string;
  billing_city: string;
  billing_state: string;
  billing_country: string;
  billing_zip: string;
}

export interface ChargifyUpdateSubscriptionPaymentDetailsParams {
  subscriptionID: number;
  full_number: number;
  expiration_month: number;
  expiration_year: number;
  card_type: string;
  billing_address: string;
  billing_address_2: string;
  billing_city: string;
  billing_state: string;
  billing_country: string;
  billing_zip: string;
}

export interface ChargifyProductInfo {
  title: string;
  description: string;
  price: number;
  handle: string;
  frequency: string;
  planID: number;
}

@Injectable({
  providedIn: 'root'
})
export class ChagifyService extends SalonmonsterHttpClient {
  constructor(
    protected userService: UserService,
    protected errorHandlerService: ErrorHandlerService,
    public http: HttpClient
  ) {
    super(http, userService, errorHandlerService);
  }

  public fetchChargifyTransactions(): Observable<List<ChargifyTransaction>> {
    const url = `${ENV_CONFIG.API_ROOT}/salons/chargify`;
    return this.get(url).pipe(
      map((data) => {
        let transactions: List<ChargifyTransaction> = List([]);

        for (const transaction of data) {
          transactions = transactions.push(transaction);
        }

        return transactions;
      })
    );
  }

  public subscribe(
    params: ChargifyNewSubscriptionParams
  ): Observable<ChargifyTransaction> {
    const url = `${ENV_CONFIG.API_ROOT}/salons/chargify`;
    return this.post(url, params);
  }

  public upgradeSubscription(
    subscriptionID: number,
    productHande: string
  ): Observable<ChargifyTransaction> {
    const url = `${ENV_CONFIG.API_ROOT}/salons/chargify/upgrade`;
    return this.put(url, {
      subscriptionID,
      handle: productHande
    });
  }

  public updateSubscriptionPaymentDetails(
    params: ChargifyUpdateSubscriptionPaymentDetailsParams
  ): Observable<ChargifyTransaction> {
    const url = `${ENV_CONFIG.API_ROOT}/salons/chargify/paymentdetails`;
    return this.put(url, params);
  }

  public cancelSubscription(
    subscriptionID: number
  ): Observable<ChargifyTransaction> {
    const url = `${ENV_CONFIG.API_ROOT}/salons/chargify`;
    return this.delete(url, {
      subscriptionID
    });
  }

  public reactivateSubscription(
    subscriptionID: number
  ): Observable<ChargifyTransaction> {
    const url = `${ENV_CONFIG.API_ROOT}/salons/chargify/reactivate`;
    return this.put(url, {
      subscriptionID
    });
  }
}
