import { Booking } from '../booking/booking.model';
import { ClientJSON } from './client-json.model';
import { ClientSerialized } from './client-serialized.model';
import { Communication } from '../communication/communication.model';
import { Country } from '../country/country.model';
import { List } from 'immutable';
import { User } from '../user/user.model';
import { Utilities } from '../../utilities/utilities';

export interface ClientDetailCallBackData {
  client: Client;
  action: string;
}

export class Client extends User<Client> {
  constructor(options?: ClientJSON) {
    super(options);
    if (options) {
      this.pronouns = options.pronouns;
      this.customPronouns = options.customPronouns;
      this.workPh = options.workPh;
      this.workExt = options.workExt;
      this.reminderType = options.reminderType;
      this.badEmail = options.badEmail;
      this.blockedOnline = options.blockedOnline;
      this.unsubscribed = options.unsubscribed;
      this.notes = options.notes;
      this.stylistID = options.stylistID;
      this.salonID = options.salonID;
      this.birthDate = Utilities.isDateValid(
        Utilities.parseDate(options.birthDate)
      )
        ? Utilities.parseDate(options.birthDate)
        : undefined;
      this.bookingHistoryLoaded = options.bookingHistoryLoaded;
      this.communicationHistoryLoaded = options.communicationHistoryLoaded;
      this.bookings = options.bookings ? options.bookings : List.of<Booking>();
      this.communications = options.communications
        ? options.communications
        : List.of<Communication>();
      this.profilePhoto = options.profilePhoto;
    } else {
      this.bookings = List.of<Booking>();
      this.communications = List.of<Communication>();
    }
  }

  public readonly workPh: string;

  public readonly workExt: string;

  public readonly reminderType: string;

  public readonly badEmail: number;

  public readonly blockedOnline: number;

  public readonly unsubscribed: number;

  public readonly notes: string;
  public readonly pronouns: number;
  public readonly customPronouns: string;
  // stylistID is required when creating a new Client
  public readonly stylistID: number;

  public readonly salonID: number;

  public readonly birthDate: Date;

  public readonly bookingHistoryLoaded: boolean;

  public readonly communicationHistoryLoaded: boolean;

  public readonly bookings: List<Booking>;

  public readonly communications: List<Communication>;

  public readonly profilePhoto: string;

  public static parseClientData(clientData): Client {
    let country: Country;
    if (clientData.countryID) {
      country = new Country(clientData.countryID, clientData.countryName);
    }

    return new Client({
      id: clientData.id,
      firstName: clientData.firstName,
      lastName: clientData.lastName,
      pronouns: clientData.pronouns,
      customPronouns: clientData.customPronouns,
      title: clientData.title,
      email: clientData.email,
      phone1: clientData.phone1,
      phone2: clientData.phone2,
      workPh: clientData.workPh,
      address1: clientData.address1,
      address2: clientData.address2,
      city: clientData.city,
      province: clientData.province,
      postal: clientData.postal,
      country: country,
      reminderType: clientData.reminderType,
      notes: clientData.notes,
      badEmail: clientData.badEmail,
      blockedOnline: clientData.blockedOnline,
      birthDate: clientData.birthDate,
      unsubscribed: clientData.unsubscribed,
      stylistID: clientData.stylistID,
      salonID: clientData.salonID,
      workExt: clientData.workExt,
      profilePhoto: clientData.profilePhoto,
      isChatboxEnabled: clientData.isChatboxEnabled,
      isChatBubbleEnabled: clientData.isChatBubbleEnabled,
    });
  }

  public getID(): number {
    return this.id;
  }

  public setID(newID: number): Client {
    const data: ClientJSON = this.toJSON();
    data.id = newID;
    return new Client(data);
  }

  public getFirstName(): string {
    return this.firstName;
  }

  public setFirstName(firstName: string): Client {
    const data: ClientJSON = this.toJSON();
    data.firstName = firstName;
    return new Client(data);
  }

  public getLastName(): string {
    return this.lastName;
  }

  public setLastName(lastName: string): Client {
    const data: ClientJSON = this.toJSON();
    data.lastName = lastName;
    return new Client(data);
  }

  public setPronouns(pronouns: number): Client {
    const data: ClientJSON = this.toJSON();
    data.pronouns = pronouns;
    return new Client(data);
  }

  public setcustomPronouns(customPronouns: string): Client {
    const data: ClientJSON = this.toJSON();
    data.customPronouns = customPronouns;
    return new Client(data);
  }
  public getTitle(): string {
    return this.title;
  }

  public setTitle(title: string): Client {
    const data: ClientJSON = this.toJSON();
    data.title = title;
    return new Client(data);
  }

  public getEmail(): string {
    return this.email;
  }

  public setEmail(email: string): Client {
    const data: ClientJSON = this.toJSON();
    data.email = email;
    return new Client(data);
  }

  public getPhone1(): string {
    return this.phone1;
  }

  public setPhone1(phone1: string): Client {
    const data: ClientJSON = this.toJSON();
    data.phone1 = phone1;
    return new Client(data);
  }

  public getPhone2(): string {
    return this.phone2;
  }

  public setPhone2(phone2: string): Client {
    const data: ClientJSON = this.toJSON();
    data.phone2 = phone2;
    return new Client(data);
  }

  public getAddress1(): string {
    return this.address1;
  }

  public setAddress1(address1: string): Client {
    const data: ClientJSON = this.toJSON();
    data.address1 = address1;
    return new Client(data);
  }

  public getAddress2(): string {
    return this.address2;
  }

  public setAddress2(address2: string): Client {
    const data: ClientJSON = this.toJSON();
    data.address2 = address2;
    return new Client(data);
  }

  public getCity(): string {
    return this.city;
  }

  public setCity(city: string): Client {
    const data: ClientJSON = this.toJSON();
    data.city = city;
    return new Client(data);
  }

  public getProvince(): string {
    return this.province;
  }

  public setProvince(province: string): Client {
    const data: ClientJSON = this.toJSON();
    data.province = province;
    return new Client(data);
  }

  public getPostal(): string {
    return this.postal;
  }

  public setPostal(postal: string): Client {
    const data: ClientJSON = this.toJSON();
    data.postal = postal;
    return new Client(data);
  }

  public getCountry(): Country {
    return this.country;
  }

  public setCountry(newCountry: Country): Client {
    const data: ClientJSON = this.toJSON();
    data.country = newCountry ? newCountry.clone() : undefined;
    return new Client(data);
  }

  public isCommunicationHistoryAlreadyLoaded(): boolean {
    return this.communicationHistoryLoaded;
  }

  public isBookingHistoryAlreadyLoaded(): boolean {
    return this.bookingHistoryLoaded;
  }

  public getWorkph(): string {
    return this.workPh;
  }

  public setWorkPh(workPh: string): Client {
    const data: ClientJSON = this.toJSON();
    data.workPh = workPh;
    return new Client(data);
  }

  public getWorkExt(): string {
    return this.workExt;
  }

  public getPronouns(): number {
    return this.pronouns;
  }

  public getcustomPronouns(): string {
    return this.customPronouns;
  }

  public getReadablePronouns(): string {
    switch (this.pronouns) {
      case 0:
        return '';
      case 1:
        return 'She/Her';
      case 2:
        return 'He/Him';
      case 3:
        return 'They/Them';
      case 4:
        return this.customPronouns;
    }
  }

  public setWorkExt(workExt: string): Client {
    const data: ClientJSON = this.toJSON();
    data.workExt = workExt;
    return new Client(data);
  }

  public getReminderType(): string {
    return this.reminderType;
  }

  public setReminderType(reminderType: string): Client {
    const data: ClientJSON = this.toJSON();
    data.reminderType = reminderType;
    return new Client(data);
  }

  public getNotes(): string {
    return this.notes;
  }

  public setNotes(newNotes: string): Client {
    const data: ClientJSON = this.toJSON();
    data.notes = newNotes;
    return new Client(data);
  }

  public hasClientNotes(): boolean {
    let notes: string = this.getNotes();

    if (!notes) {
      return false;
    }

    // remove spaces
    notes = notes.split(' ').join('');
    return notes && notes.length > 0;
  }

  public isBadEmail(): boolean {
    return this.badEmail === 1;
  }

  public setBadEmail(badEmail: boolean): Client {
    const data: ClientJSON = this.toJSON();
    data.badEmail = badEmail ? 1 : 0;
    return new Client(data);
  }

  public isUnsubscribed(): boolean {
    return this.unsubscribed === 1;
  }

  public setUnsubscribed(subscribed: boolean): Client {
    const data: ClientJSON = this.toJSON();
    data.unsubscribed = subscribed ? 1 : 0;
    return new Client(data);
  }

  public isBlockedOnline(): boolean {
    return this.blockedOnline === 1;
  }

  public setBlockedOnline(blockedOnline: boolean): Client {
    const data: ClientJSON = this.toJSON();
    data.blockedOnline = blockedOnline ? 1 : 0;
    return new Client(data);
  }

  public setStylistID(stylistID: number): Client {
    const data: ClientJSON = this.toJSON();
    data.stylistID = stylistID;
    return new Client(data);
  }

  public getStylistID(): number {
    return this.stylistID;
  }

  public setSalonID(salonID: number): Client {
    const data: ClientJSON = this.toJSON();
    data.salonID = salonID;
    return new Client(data);
  }

  public getBirthDate(): Date {
    return this.birthDate;
  }

  public setBirthDate(date: Date): Client {
    const data: ClientJSON = this.toJSON();
    data.birthDate = Utilities.formatDate(date);
    return new Client(data);
  }

  public setBirthMonth(month: number): Client {
    const data: ClientJSON = this.toJSON();
    let birthDate: Date = Utilities.parseDate(data.birthDate);

    if (!birthDate) {
      birthDate = new Date();
      birthDate.setDate(1);
    }

    birthDate.setMonth(month - 1);
    data.birthDate = Utilities.formatDate(birthDate);
    return new Client(data);
  }

  public setBirthday(day: number) {
    const data: ClientJSON = this.toJSON();
    let birthDate: Date = Utilities.parseDate(data.birthDate);

    if (!birthDate) {
      birthDate = new Date();
      birthDate.setMonth(0);
    }

    birthDate.setDate(day);
    data.birthDate = Utilities.formatDate(birthDate);
    return new Client(data);
  }

  public getCommunications(): List<Communication> {
    return this.communications;
  }

  public setProfilePhoto(profilePhoto: string) {
    const data: ClientJSON = this.toJSON();
    data.profilePhoto = profilePhoto;
    return new Client(data);
  }

  public toJSON(): ClientJSON {
    return Object.assign(super.toJSON(), {
      pronouns: this.getPronouns(),
      customPronouns: this.getcustomPronouns(),
      workPh: this.getWorkph(),
      workExt: this.getWorkExt(),
      reminderType: this.getReminderType(),
      badEmail: this.unsubscribed,
      blockedOnline: this.blockedOnline,
      unsubscribed: this.unsubscribed,
      notes: this.getNotes(),
      stylistID: this.stylistID,
      salonID: this.salonID,
      birthDate:
        this.birthDate !== undefined && this.birthDate !== null
          ? Utilities.formatDate(this.birthDate, 'Y-MM-DD')
          : '0000-00-00',
      bookingHistoryLoaded: this.bookingHistoryLoaded,
      communicationHistoryLoaded: this.communicationHistoryLoaded,
      bookings: this.bookings,
      communications: this.communications,
      profilePhoto: this.profilePhoto,
    });
  }

  public clone(): Client {
    return new Client(this.toJSON());
  }

  public serialize(): ClientSerialized {
    return {
      countryID: this.getCountry() ? this.getCountry().getID() : null,
      salonID: 2,
      ...this.toJSON(),
    };
  }
}
