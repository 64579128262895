import { PaymentJSON } from './payment-json.model';
import { PaymentRecord } from './payment-record.model';

export class Payment extends PaymentRecord {
  public readonly id: number;
  public readonly name: string;
  public readonly type: number;
  public readonly amount: number;
  public readonly intentID: string;
  public readonly last4Digits: string;
  public readonly expDate: string;
  public readonly chargeID: string;
  public readonly isInterac: boolean;
  public readonly applicationFee: number;

  constructor(params: PaymentJSON) {
    super(params);
  }

  public setID(value: number): Payment {
    return this.set('id', value) as Payment;
  }

  public setType(value: number): Payment {
    return this.set('type', value) as Payment;
  }

  public setAmount(value: number): Payment {
    return this.set('amount', value) as Payment;
  }

  public setIntentID(value: string): Payment {
    return this.set('intentID', value) as Payment;
  }

  public setLast4Digits(value: string): Payment {
    return this.set('last4Digits', value) as Payment;
  }

  public setExpDate(value: string): Payment {
    return this.set('expDate', value) as Payment;
  }

  public setChargeID(value: string): Payment {
    return this.set('chargeID', value) as Payment;
  }

  public setIsInterac(value: boolean): Payment {
    return this.set('isInterac', value) as Payment;
  }

  public setApplicationFeeAmount(value: number): Payment {
    return this.set('applicationFee', value) as Payment;
  }
}
