import { Map, fromJS } from 'immutable';
import { CalendarAvailabilities } from '../bookings/calendar/day-view-calendar/day-view-calendar.component';

export class Availability {
  public static parseAvailabilityData(data): CalendarAvailabilities {
  // public static parseAvailabilityData(data): any {
    //   todo
    // let availabilities: CalendarAvailabilities = Map();

    let availabilities: any = Map();

    for (const day in data) {
      if (Object.prototype.hasOwnProperty.call(data, day)) {
        const element = data[day];
        availabilities = availabilities.set(day + '', fromJS(element));
      }
    }

    return availabilities;
  }
}
