import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';

import { ENV_CONFIG } from 'src/bin/env.config';
import { ErrorHandlerService } from './error-handler.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SalonmonsterHttpClient } from '../services/salonmonster-http-client';
import { UserService } from '../services/user.service';
import { WorkDays } from 'src/app/models';
// import 'rxjs/add/operator/map';
import { map } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WorkDayService extends SalonmonsterHttpClient {
  constructor(
    http: HttpClient,
    userService: UserService,
    protected errorHandlerService: ErrorHandlerService
  ) {
    super(http, userService, errorHandlerService);
  }

  public getStylistWorkDays(
    stylistID: number,
    forceLoad?: boolean
  ): Observable<WorkDays> {
    const url = `${ENV_CONFIG.API_ROOT}/stylists/${stylistID}/workdays`;
    return this.get(url).pipe(
      map((data) => {
        if (data && data.length === 1) {
          return WorkDays.parseWorkDay(data[0]);
        } else {
          throwError(new Error('An error occurred'));
        }
      })
    );
  }

  public saveWorkDays(workDays: WorkDays): Observable<WorkDays> {
    let url = `${ENV_CONFIG.API_ROOT}/workdays`;

    if (workDays.getId()) {
      url += '/' + workDays.getId();
      let workDaysData = this.put(url, workDays.serialize()).pipe(
        map((data) => {
          if (data) {
            return WorkDays.parseWorkDay(data);
          } else {
            throwError(new Error('An error occurred'));
          }
        })
      );
      
      return workDaysData;
    } else {
      return this.post(url, workDays.serialize()).pipe(
        map((data) => {
          return workDays.setId(data.id);
        })
      );
    }
  }
}
