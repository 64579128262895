import { ActivityJSON } from './activity-json.model';
import { ActivitySerialized } from './activity-serialized.model';
import { Booking } from '../booking/booking.model';
import { Cloneable } from '../cloneable.model';
import { Serializable } from '../serializable.model';
import { Utilities } from 'src/app/utilities/utilities';

interface TestObject {
  id: string;
  name: string;
}

export class Activity
  implements
    Cloneable<Activity, ActivityJSON>,
    Serializable<ActivitySerialized> {
  public readonly id: number;

  public readonly booking: Booking;

  public readonly time: Date;

  public readonly action: number;

  public readonly actionName: string;

  public readonly stylistID: number;

  public readonly seen: number;

  public readonly viewed: number;

  constructor(options: ActivityJSON) {
    this.id = options.id;
    this.booking = options.booking;
    this.time = options.time;
    this.action = options.action;
    this.actionName = options.actionName;
    this.stylistID = options.stylistID;
    this.seen = options.seen;
    this.viewed = options.viewed;
  }

  public static parseActivityData(activityData): Activity {

    return new Activity({
      id: activityData.id,
      booking: activityData.booking
        ? Booking.parseBooking(activityData.booking)
        : undefined,
      time: Utilities.parseDate(activityData.time),
      action: activityData.action,
      actionName: activityData.actionName,
      stylistID: activityData.stylistID,
      seen: activityData.seen,
      viewed: activityData.viewed
    });
  }

  public clone(): Activity {
    return new Activity(this.toJSON());
  }

  public toJSON(): ActivityJSON {
    return {
      id: this.getId(),
      booking: this.getBooking(),
      time: this.getTime(),
      action: this.getAction(),
      actionName: this.getActionName(),
      stylistID: this.getStylistID(),
      seen: this.getSeen(),
      viewed: this.getViewed()
    };
  }

  public serialize(): ActivitySerialized {
    return this.toJSON();
  }

  public getId(): number {
    return this.id;
  }

  public getBooking(): Booking {
    return this.booking;
  }

  public getTime(): Date {
    return this.time;
  }

  public getAction(): number {
    return this.action;
  }

  public getStylistID(): number {
    return this.stylistID;
  }

  public getSeen(): number {
    return this.seen;
  }

  public getViewed(): number {
    return this.viewed;
  }

  public getActionName(): string {
    return this.actionName;
  }

  public setId(id: number): Activity {
    const data: ActivityJSON = this.toJSON();
    data.id = id;
    return new Activity(data);
  }

  public setBooking(booking: Booking): Activity {
    const data: ActivityJSON = this.toJSON();
    data.booking = booking;
    return new Activity(data);
  }

  public setTime(time: Date): Activity {
    const data: ActivityJSON = this.toJSON();
    data.time = time;
    return new Activity(data);
  }

  public setAction(action: number): Activity {
    const data: ActivityJSON = this.toJSON();
    data.action = action;
    return new Activity(data);
  }

  public setStylistID(stylistID: number): Activity {
    const data: ActivityJSON = this.toJSON();
    data.stylistID = stylistID;
    return new Activity(data);
  }

  public setSeen(seen: number): Activity {
    const data: ActivityJSON = this.toJSON();
    data.seen = seen;
    return new Activity(data);
  }

  public setViewed(viewed: number): Activity {
    const data: ActivityJSON = this.toJSON();
    data.viewed = viewed;
    return new Activity(data);
  }

  public setActionName(actionName: string): Activity {
    const data: ActivityJSON = this.toJSON();
    data.actionName = actionName;
    return new Activity(data);
  }
}
