import { Client } from '../client/client.model';
import { Cloneable } from '../cloneable.model';
import { Discount } from '../discount/discount.model';
import { LineitemJSON } from './line-item-json.model';
import { List } from 'immutable';
import { Stylist } from '../stylist/stylist.model';
import { Utilities } from 'src/app/utilities/utilities';;

export abstract class Lineitem implements Cloneable<Lineitem, LineitemJSON> {
  public readonly lineItemID: number;

  public readonly clientID: number;

  public readonly client: Client;

  public readonly type: string;

  public readonly name: string;

  public price: number;

  public readonly quantity: number;

  public readonly isRefund: number;

  public readonly stylistID: number;

  public readonly stylist: Stylist;

  public readonly discount: Discount;

  public readonly taxRateType: number;

  public readonly customTaxes: List<string>;

  public readonly isPhantom: boolean;

  public preTaxTotals: number;

  constructor(options: LineitemJSON) {
    this.lineItemID = options.lineItemID;
    this.clientID = options.clientID;
    this.client = options.client;
    this.type = options.type;
    this.name = options.name;
    this.price = options.price;
    this.quantity = options.quantity;
    this.isRefund = options.isRefund;
    this.stylistID = options.stylistID;
    this.stylist = options.stylist;
    this.discount = options.discount;
    this.taxRateType = options.taxRateType;
    this.customTaxes = options.customTaxes;
    this.isPhantom = options.isPhantom;
    this.preTaxTotals = 0;
    this.calculatePreTaxTotals();
  }

  protected calculatePreTaxTotals() {
    this.preTaxTotals = Utilities.roundTo2Decimal(this.price * this.quantity);

    if (this.discount !== undefined && this.discount !== null) {
      if (this.discount.getType() === 0) {
        // normal $ amount
        this.preTaxTotals -= Utilities.roundTo2Decimal(
          this.discount.getAmount()
        );
      } else if (this.discount.getType() === 1) {
        // percentage
        const percent = this.discount.getAmount() / 100;
        const discount = Utilities.roundTo2Decimal(this.preTaxTotals * percent);
        this.preTaxTotals -= Utilities.roundTo2Decimal(discount);
      } else {
        throw new Error('Invalid discount type');
      }
    }

    this.preTaxTotals = Utilities.roundTo2Decimal(this.preTaxTotals);

    if (this.isItemRefund()) {
      this.preTaxTotals = this.preTaxTotals * -1;
    }
  }

  public getLineItemID(): number {
    return this.lineItemID;
  }

  public getClientID(): number {
    return this.clientID;
  }

  public getClient(): Client {
    return this.client;
  }

  public getType(): string {
    return this.type;
  }

  public getName(): string {
    return this.name;
  }

  public getPrice(): number {
    return this.price;
  }

  public getQuantity(): number {
    return this.quantity;
  }

  public getStylistID(): number {
    return this.stylistID;
  }

  public getStylist(): Stylist {
    return this.stylist;
  }

  public getDiscount(): Discount {
    return this.discount;
  }

  public getTaxRateType(): number {
    return this.taxRateType;
  }

  public getPreTaxTotals(): number {
    return this.preTaxTotals;
  }

  public phantom(): boolean {
    return this.isPhantom;
  }

  public isItemRefund(): boolean {
    return this.isRefund === 1;
  }

  public getCustomTaxes(): List<string> {
    return this.customTaxes;
  }

  public abstract setCustomTaxes(customTaxes: List<string>): Lineitem;

  public abstract setPhantom(isPhantom: boolean): Lineitem;

  public abstract setLineItemID(newID: number): Lineitem;

  public abstract setClientID(clientID: number): Lineitem;

  public abstract setClient(client: Client): Lineitem;

  public abstract setType(type: string): Lineitem;

  public abstract setName(name: string): Lineitem;

  public abstract setPrice(price: number): Lineitem;

  public abstract setQuantity(quantity: number): Lineitem;

  public abstract setRefund(refund: number): Lineitem;

  public abstract setTaxRateType(taxRateType: number): Lineitem;

  public abstract setStylist(stylistID: Stylist): Lineitem;

  public abstract setDiscount(discount: Discount): Lineitem;

  public abstract removeDiscount(): Lineitem;

  public abstract clone(): Lineitem;

  public toJSON(): LineitemJSON {
    return {
      lineItemID: this.lineItemID,
      clientID: this.clientID,
      client: this.client,
      stylist: this.stylist,
      type: this.type,
      name: this.name,
      price: this.price,
      quantity: this.quantity,
      isRefund: this.isRefund,
      stylistID: this.stylistID,
      discount: this.discount,
      taxRateType: this.taxRateType,
      customTaxes: this.customTaxes,
      isPhantom: this.isPhantom
    };
  }
}
