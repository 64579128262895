import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';

import { ENV_CONFIG } from 'src/bin/env.config';
import { ErrorHandlerService } from './error-handler.service';
import { Injectable } from '@angular/core';
import { List } from 'immutable';
import { Observable } from 'rxjs';
import { SalonmonsterHttpClient } from '../services/salonmonster-http-client';
import { ServiceCategory } from 'src/app/models';
import { UserService } from '../services/user.service';
import { map, mapTo } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ServiceCategoryService extends SalonmonsterHttpClient {
  private serviceCategories: List<ServiceCategory>;

  constructor(
    http: HttpClient,
    userService: UserService,
    protected errorHandlerService: ErrorHandlerService
  ) {
    super(http, userService, errorHandlerService);
    this.serviceCategories = List.of<ServiceCategory>();

    userService.getLogOutMonitor().subscribe((logout) => {
      if (logout) {
        this.serviceCategories = List([]);
      }
    });
  }

  public getServiceCategories(
    stylistID: number,
    salonID: number,
    forceLoad?: boolean
  ): Observable<List<ServiceCategory>> {
    const url = `${ENV_CONFIG.API_ROOT}/stylists/${stylistID}/servicecategories?salonID=${salonID}`;
    return this.get(url).pipe(
      map((data) => {
        let serviceCategories: List<ServiceCategory> = List.of<
          ServiceCategory
        >();

        data.forEach((element) => {
          serviceCategories = serviceCategories.push(
            ServiceCategory.parseData(element)
          );
        });

        this.serviceCategories = serviceCategories;
        return this.serviceCategories;
      })
    );
  }

  public getSalonServiceCategories(): Observable<List<ServiceCategory>> {
    const url = `${ENV_CONFIG.API_ROOT}/salons/servicecategories`;
    return this.get(url).pipe(
      map((data) => {
        let serviceCategories: List<ServiceCategory> = List.of<
          ServiceCategory
        >();

        data.forEach((element) => {
          serviceCategories = serviceCategories.push(
            ServiceCategory.parseData(element)
          );
        });

        this.serviceCategories = serviceCategories;
        return this.serviceCategories;
      })
    );
  }

  // public saveServiceCategory(
  //   serviceCategory: ServiceCategory
  // ): Observable<ServiceCategory> {
  //   return new Observable <ServiceCategory> (observer => {
  //   let url = `${ENV_CONFIG.API_ROOT}/servicecategories`;

  //   if (serviceCategory.getId()) {
  //     url += '/' + serviceCategory.getId();
  //     return this.put(url, serviceCategory.serialize());
  //   } else {
  //     this.post(url, serviceCategory.serialize()).subscribe({
  //       next: (data) => { 
  //         return serviceCategory.setId(data.id);
  //       } ,
  //         // map((data) => {
  //         //   return serviceCategory.setId(data.id);
  //         // })
        
  //       error: (e) => 
  //     });
      
  //   }
  // });
  // }

  public saveServiceCategory(serviceCategory: ServiceCategory): Promise<ServiceCategory> {
    let url = `${ENV_CONFIG.API_ROOT}/servicecategories`;
  
    if (serviceCategory.getId()) {
      url += '/' + serviceCategory.getId();
  
      return this.put(url, serviceCategory.serialize())
        .pipe(
          map((res: Response) => serviceCategory)
        )
        .toPromise()
        .catch((err) => this.errorHandlerService.handleError(err));
    } else {
      return this.post(url, serviceCategory.serialize())
        .toPromise()
        .catch((err) => this.errorHandlerService.handleError(err));
    }
  }

  public deleteServiceCategory(
    serviceCategory: ServiceCategory
  ): Observable<ServiceCategory> {
    const url = `${ENV_CONFIG.API_ROOT}/servicecategories/${serviceCategory.id}`;
    return this.delete(url, {});
  }

  public saveServiceSort(
    serviceCategories: List<ServiceCategory>
  ): Observable<boolean> {
    const url = `${ENV_CONFIG.API_ROOT}/servicecategories/sort`;

    const params = {
      serviceCategories: []
    };

    serviceCategories.forEach((sc) => {
      params.serviceCategories.push(sc.toJSON());
    });

    return this.put(url, params).pipe(mapTo(true));
  }
}
