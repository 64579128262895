import { List, Record } from 'immutable';

export const RegisterClosureRecord = Record({
  cashPaidIn: 0,
  cashPaidOut: 0,
  closingFloatValue: 0,
  countedAMEXValue: 0,
  countedCashValue: 0,
  countedChequeValue: 0,
  countedDebitValue: 0,
  countedDiscoverValue: 0,
  countedETransferValue: 0,
  countedGiftCardValue: 0,
  countedAccountValue: 0,
  countedMasterCardValue: 0,
  countedSquareValue: 0,
  countedVisaValue: 0,
  dateClosed: undefined,
  dateModified: undefined,
  dateOpened: undefined,
  expectedAmexAmountFromSales: 0,
  expectedCashAmountFromSales: 0,
  expectedChequeAmountFromSales: 0,
  expectedDebitAmountFromSales: 0,
  expectedDiscoverAmountFromSales: 0,
  expectedEtransferAmountFromSales: 0,
  expectedGiftcardAmountFromSales: 0,
  expectedAccountAmountFromSales: 0,
  expectedMastercardAmountFromSales: 0,
  expectedSquareAmountFromSales: 0,
  expectedVisaAmountFromSales: 0,
  
  expectedStripeDebitAmountFromSales: 0,

  expectedStripeVisaAmountFromSales: 0,

  expectedStripeMastercardAmountFromSales: 0,
  expectedStripeAmexAmountFromSales: 0,

  expectedStripeDinersAmountFromSales: 0,

  expectedStripeDiscoverAmountFromSales: 0,

  expectedStripeJcbAmountFromSales: 0,

  expectedStripeUnionpayAmountFromSales: 0,

  expectedStripeUnknownAmountFromSales: 0,

  expectedStripePrepaidAmountFromSales: 0,
  id: 0,
  note: '',
  openingFloatValue: 0,
  productsTotal: 0,
  servicesTotal: 0,
  stylistID: 0,
  taxesTotal: List(),
  totalTips: 0,
  isMostRecentRegisterClosure: false
});
