import { List, fromJS } from 'immutable';

import { BookingJSON } from './booking-json.model';
import { BookingSerialized } from './booking-serialized.model';
import { Client } from '../client/client.model';
import { Cloneable } from '../cloneable.model';
import { Country } from '../country/country.model';
import { RecurringOptions } from '../recurring-options.model';
import { Serializable } from '../serializable.model';
import { Service } from '../service/service.model';
import { Stylist } from '../stylist/stylist.model';
import { Utilities } from 'src/app/utilities/utilities';

export class Booking
  implements Cloneable<Booking, BookingJSON>, Serializable<BookingSerialized> {
  constructor(options: BookingJSON) {
    this.id = options.id;
    this.salonID = options.salonID;
    this.startDateTime = options.startDateTime;
    this.endDateTime = options.endDateTime;
    this.client = options.client;
    this.status = options.status;
    this.recurring = options.recurring;
    this.reminderSent = options.reminderSent;
    this.sendEmail = options.sendEmail || 0;
    this.sendSMS = options.sendSMS || 0;
    this.creationDate = options.creationDate;
    this.services = options.services || List.of<Service>();
    this.type = options.type;
    this.photos = options.photos || List([]);
    this.beforePhotos = options.beforePhotos || List([]);
    this.afterPhotos = options.afterPhotos || List([]);

    let dateTime = this.startDateTime;
    const length = this.services.count();
    for (let i = 0; i < length; i++) {
      this.services = this.services.update(i, (service: Service) => {
        const updatedService = service.setStartDateTime(dateTime);
        dateTime = updatedService.getEndDateTime();
        return updatedService;
      });
    }
    this.clientIP = options.clientIP;
    this.createdBy = options.createdBy;
  }

  public readonly id: number;

  public readonly salonID: number;

  public readonly startDateTime: Date;

  public readonly endDateTime: Date;

  public readonly client: Client;

  public readonly status: number;

  public readonly services: List<Service>;

  public readonly recurring: RecurringOptions;

  public readonly reminderSent: number;

  public readonly sendEmail: number;

  public readonly sendSMS: number;

  public readonly creationDate: Date;

  public readonly type: string;

  public readonly photos: List<string>;

  public readonly beforePhotos: List<string>;

  public readonly afterPhotos: List<string>;

  public readonly createdBy: Stylist;

  public readonly clientIP: string;

  public static parseBooking(bookingData): Booking {
    const startDateTime: Date = Utilities.parseDate(
      bookingData['bookingStartDateTime']
    );
    const endDateTime: Date = Utilities.parseDate(
      bookingData['bookingEndDateTime']
    );
    const status: number = parseInt(bookingData['status'], 10);
    const clientData = bookingData['client'];

    let country: Country;
    if (clientData.countryID) {
      country = new Country(clientData.countryID, clientData.countryName);
    }

    const client = clientData ? Client.parseClientData(clientData) : undefined;

    let services: List<Service> = List.of<Service>();
    for (const serviceData of bookingData['services']) {
      let service: Service = Service.parseService(serviceData);
      service = service.setClient(client);
      services = services.push(service);
    }

    let recurring: RecurringOptions;
    if (bookingData['recurring'] !== undefined) {
      const recurringID: number = bookingData['recurring'].id;
      const frequencyType: string = bookingData['recurring'].frequencyType;
      const frequencyCount: number = bookingData['recurring'].frequencyCount;

      if (recurringID !== 0) {
        recurring = {
          id: recurringID,
          frequencyType: frequencyType,
          frequencyCount: frequencyCount,

          // default
          change: 'future'
        };
      }
    }

    const bookingOptions: BookingJSON = {
      id: bookingData['id'],
      salonID: bookingData['salonID'],
      startDateTime: startDateTime,
      endDateTime: endDateTime,
      client: client,
      status: status,
      services: services,
      recurring: recurring,
      reminderSent: bookingData['reminderSent'],
      creationDate: Utilities.parseDate(bookingData['creationDate']),
      sendEmail: 0,
      sendSMS: 0,
      type: bookingData['type'],
      photos: bookingData['photos'] ? Utilities.convertToList(fromJS(bookingData['photos'])) : List([]),
      beforePhotos: bookingData['beforePhotos']
        ?  Utilities.convertToList(fromJS(bookingData['beforePhotos']))
        : List([]),
      afterPhotos: bookingData['afterPhotos']
        ?  Utilities.convertToList(fromJS(bookingData['afterPhotos']))
        : List([]),
      clientIP: bookingData['clientIP'],
      createdBy: bookingData['createdBy']
        ? Stylist.parseStylistData(bookingData['createdBy'])
        : undefined
    };

    return new Booking(bookingOptions);
  }

  public serialize(): BookingSerialized {
    const payload: BookingSerialized = {
      id: this.id,
      bookingStartDateTime: Utilities.formatDate(this.startDateTime),
      status: this.status,
      clientID: this.client ? this.client.getID() : undefined,
      services: [],
      recurring: undefined,
      sendEmail: undefined,
      sendSMS: undefined,
      photos: this.photos ? this.photos.toArray() : [],
      beforePhotos: this.beforePhotos ? this.beforePhotos.toArray() : [],
      afterPhotos: this.afterPhotos ? this.afterPhotos.toArray() : []
    };

    this.services.forEach((service: Service) => {
      payload.services.push(service.serialize());
    });

    if (this.recurring) {
      payload.recurring = {
        id: this.recurring.id,
        frequencyType: this.recurring.frequencyType,
        frequencyCount: this.recurring.frequencyCount,
        change: this.recurring.change
      };
    }

    payload.sendEmail = this.sendEmail;
    payload.sendSMS = this.sendSMS
    return payload;
  }

  public toJSON(): BookingJSON {
    return {
      id: this.getId(),
      salonID: this.getSalonID(),
      startDateTime: this.getStartDateTime()
        ? Utilities.cloneDate(this.getStartDateTime())
        : undefined,
      endDateTime: this.getEndDateTime()
        ? Utilities.cloneDate(this.getEndDateTime())
        : undefined,
      client: this.getClient() ? this.getClient().clone() : undefined,
      status: this.getStatus(),
      services: this.services,
      recurring: this.getRecurringSettings()
        ? { ...this.getRecurringSettings() }
        : undefined,
      reminderSent: this.getReminderSent(),
      sendEmail: this.getSendEmail(),
      sendSMS: this.getSendSMS(),
      creationDate: this.getCreationDate(),
      type: this.type,
      photos: this.photos,
      beforePhotos: this.beforePhotos,
      afterPhotos: this.afterPhotos,
      clientIP: this.clientIP,
      createdBy: this.createdBy
    };
  }

  public clone(): Booking {
    return new Booking(this.toJSON());
  }

  public getId(): number {
    return this.id;
  }

  public setId(id: number): Booking {
    const data: BookingJSON = this.toJSON();
    data.id = id;
    return new Booking(data);
  }

  public getSalonID(): number {
    return this.salonID;
  }

  public getStartDateTime(): Date {
    return this.startDateTime;
  }

  public getStartDateTimeAsString(): string {
    return Utilities.formatDate(this.startDateTime, 'HH:mm');
  }

  public setStartDateTime(date: Date): Booking {
    const data: BookingJSON = this.toJSON();
    data.startDateTime = date;
    return new Booking(data);
  }

  public getEndDateTime(): Date {
    if (this.services.count() === 0) {
      return this.startDateTime;
    }
    return this.services.last().getEndDateTime();
  }

  public getClient(): Client {
    return this.client;
  }

  public setClient(client: Client): Booking {
    const data: BookingJSON = this.toJSON();
    data.client = client;
    return new Booking(data);
  }

  public getStatus(): number {
    return this.status;
  }

  public setStatus(status: number): Booking {
    const data: BookingJSON = this.toJSON();
    data.status = status;
    return new Booking(data);
  }

  public setType(type: string): Booking {
    const data: BookingJSON = this.toJSON();
    data.type = type;
    return new Booking(data);
  }

  public getReminderSent(): number {
    return this.reminderSent;
  }

  public getServices(): List<Service> {
    return this.services;
  }

  public findServiceById(serviceId: number): Service {
    let s: Service;

    this.services.some((service: Service) => {
      if (service.getId() === serviceId) {
        s = service;
        return true;
      }

      return false;
    });

    return s;
  }

  private generateUniqueServiceID(): number {
    let token = 1;

    while (true) {
      let unique = true;

      // loop through all lineitems to see if this token has been already used

      this.services.some((service: Service) => {
        if (service.getId() === token) {
          unique = false;
          return true;
        }

        return false;
      });

      if (unique) {
        return token;
      } else {
        // generate another token
        token++;
      }
    }
  }

  public addService(newService: Service): Booking {
    // create a temporary ID for this lineitem
    let service = newService.setId(this.generateUniqueServiceID());

    // important, forcce new lineitem to be Phsantom
    service = service.setPhantom(true);

    const data: BookingJSON = this.toJSON();
    data.services = data.services.push(service);
    return new Booking(data);
  }

  public insertService(index: number, newService: Service): Booking {
    // create a temporary ID for this lineitem
    let service = newService.setId(this.generateUniqueServiceID());

    // important, forcce new lineitem to be Phsantom
    service = newService.setPhantom(true);

    const data: BookingJSON = this.toJSON();
    data.services = data.services.insert(index, newService);
    return new Booking(data);
  }

  public removeService(serviceID: number): Booking {
    let index = 0;
    let serviceToBeDeleted;

    this.services.some((service) => {
      if (service.getId() === serviceID) {
        serviceToBeDeleted = service;
        return true;
      }

      index++;
      return false;
    });

    if (serviceToBeDeleted !== undefined) {
      const data: BookingJSON = this.toJSON();
      data.services = data.services.remove(index);
      return new Booking(data);
    } else {
      // service to be deleted not found
      throw new Error('invalid ServiceID');
    }
  }

  public setServices(services: List<Service>) {
    const data: BookingJSON = this.toJSON();
    data.services = services;
    return new Booking(data);
  }

  public removeServices() {
    const data: BookingJSON = this.toJSON();
    data.services = List.of<Service>();
    return new Booking(data);
  }

  public getRecurringSettings(): RecurringOptions {
    return this.recurring;
  }

  public isRecurring(): boolean {
    return this.recurring !== undefined && this.recurring.change !== 'turnoff';
  }

  public isAlreadyLinkedToARecurringAppointment() {
    return this.recurring !== undefined && this.recurring.id !== undefined;
  }

  public setRecurring(options: RecurringOptions) {
    if (this.recurring.id !== undefined && this.recurring.id !== null) {
      throw new Error(
        `Can't set recurring options. Please use setFrequencyCount() or setFrequencyType()`
      );
    }

    const data: BookingJSON = this.toJSON();
    data.recurring = options;
    return new Booking(data);
  }

  public setRecurringFrequencyCount(
    count: number,
    change: string = 'single'
  ): Booking {
    let newRecurringValue: RecurringOptions;

    if (this.recurring === undefined) {
      newRecurringValue = {
        id: undefined,
        frequencyCount: 1,
        frequencyType: 'days'
      };
    } else {
      newRecurringValue = {
        id: this.recurring.id,
        frequencyCount: this.recurring.frequencyCount,
        frequencyType: this.recurring.frequencyType
      };
    }

    if (this.isAlreadyLinkedToARecurringAppointment()) {
      newRecurringValue.change = change;
    }

    newRecurringValue.frequencyCount = count;

    const data: BookingJSON = this.toJSON();
    data.recurring = newRecurringValue;
    return new Booking(data);
  }

  public setRecurringFrequencyType(
    type: string,
    change: string = 'single'
  ): Booking {
    let newRecurringValue: RecurringOptions;

    if (this.recurring === undefined) {
      newRecurringValue = {
        id: undefined,
        frequencyCount: 1,
        frequencyType: 'days'
      };
    } else {
      newRecurringValue = {
        id: this.recurring.id,
        frequencyCount: this.recurring.frequencyCount,
        frequencyType: this.recurring.frequencyType
      };
    }

    if (this.isAlreadyLinkedToARecurringAppointment()) {
      newRecurringValue.change = change;
    }

    newRecurringValue.frequencyType = type;

    const data: BookingJSON = this.toJSON();
    data.recurring = newRecurringValue;
    return new Booking(data);
  }

  public setRecurringChangeOptionType(change: string = 'single'): Booking {
    let newRecurringValue: RecurringOptions;

    if (this.recurring === undefined) {
      newRecurringValue = {
        id: undefined,
        frequencyCount: 1,
        frequencyType: 'days'
      };
    } else {
      newRecurringValue = {
        id: this.recurring.id,
        frequencyCount: this.recurring.frequencyCount,
        frequencyType: this.recurring.frequencyType
      };
    }

    newRecurringValue.change = change;

    const data: BookingJSON = this.toJSON();
    data.recurring = newRecurringValue;
    return new Booking(data);
  }

  public turnOffRecurring(): Booking {
    let newRecurringValue: RecurringOptions;

    if (this.isAlreadyLinkedToARecurringAppointment()) {
      newRecurringValue = this.recurring;
      newRecurringValue.change = 'turnoff';
    } else {
      newRecurringValue = undefined;
    }

    const data: BookingJSON = this.toJSON();
    data.recurring = newRecurringValue;

    return new Booking(data);
  }

  public setSendEmail(type: number): Booking {
    const data: BookingJSON = this.toJSON();
    data.sendEmail = type;
    return new Booking(data);
  }

  public setSendSMS(type: number): Booking {
    const data: BookingJSON = this.toJSON();
    data.sendSMS = type;
    return new Booking(data);
  }

  public setBeforePhotos(photos: List<string>): Booking {
    const data: BookingJSON = this.toJSON();
    data.beforePhotos = photos;
    return new Booking(data);
  }

  public setAfterPhotos(photos: List<string>): Booking {
    const data: BookingJSON = this.toJSON();
    data.afterPhotos = photos;
    return new Booking(data);
  }

  public getSendEmail(): number {
    return this.sendEmail;
  }

  public getSendSMS(): number {
    return this.sendSMS;
  }

  public getCreationDate(): Date {
    return this.creationDate;
  }

  public isCreatedByClient(): boolean {
    return this.clientIP && this.clientIP !== '';
  }

  public getCreatedByLabel(): string {
    if (this.isCreatedByClient()) {
      if (this.client) {
        return `${this.client.firstName} ${this.client.lastName}`;
      } else {
        return `Client`;
      }
    } else {
      if (this.createdBy) {
        return `${this.createdBy.firstName} ${this.createdBy.lastName}`;
      } else {
        if (this.services.count() === 0) {
          return 'Stylist';
        } else {
          const s: Stylist = this.services.get(0).serviceDefinition.stylist;
          return `${s.firstName} ${s.lastName}`;
        }
      }
    }
  }

  public willReminderUsersHoursBefore(): number {
    let remindUserHoursBefore = 0;

    if (this.type === 'service') {
      this.services.forEach((service) => {
        if (
          service.getStylist().getSendAppointmentReminderBefore() >
          remindUserHoursBefore
        ) {
          remindUserHoursBefore = service
            .getStylist()
            .getSendAppointmentReminderBefore();
        }
      });
    }

    return remindUserHoursBefore;
  }
}
