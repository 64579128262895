import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {

  @Input() options

  constructor(private router: Router, private popoverCtrl: PopoverController) { }

  ngOnInit() {
  }

  navigate(url: string) {
    this.router.navigateByUrl(url)
    this.popoverCtrl.dismiss()
  }

}
