import { Booking, Sale, Stylist, Tax, Client } from 'src/app/models';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { map, switchMap } from 'rxjs/operators';

import { BookingService } from './booking.service';
import { ENV_CONFIG } from 'src/bin/env.config';
import { ErrorHandlerService } from './error-handler.service';
import { Injectable } from '@angular/core';
import { List } from 'immutable';
import { Observable } from 'rxjs';
import { SalonService } from './salon.service';
import { SalonmonsterHttpClient } from '../services/salonmonster-http-client';
import { UserService } from '../services/user.service';
import { Utilities } from '../utilities/utilities';
import { throwError } from 'rxjs';

export enum SALE_CHECKOUT_TYPE {
  salonMonsterPay = 0,
  SquarePOS = 1,
  PaypalHere = 2
}

@Injectable({
  providedIn: 'root'
})
export class SaleService extends SalonmonsterHttpClient {
  private saleToBeCheckedOut: Sale;

  private checkOutPromise: () => any;

  private checkOutType: SALE_CHECKOUT_TYPE;

  constructor(
    http: HttpClient,
    userService: UserService,
    private bookingService: BookingService,
    private salonService: SalonService,
    protected errorHandlerService: ErrorHandlerService
  ) {
    super(http, userService, errorHandlerService);
    userService.getLogOutMonitor().subscribe((logout) => {
      if (logout) {
        this.saleToBeCheckedOut = null;
        this.checkOutPromise = null;
        this.checkOutType = null;
      }
    });
  }

  public loadSaleByID(saleID: number): Observable<Sale> {
    const url = `${ENV_CONFIG.API_ROOT}/sales/${saleID}`;
    return this.get(url).pipe(
      map((data) => {
        if (data && data.length === 1) {
          return Sale.parseSaleData(data[0]);
        } else {
          throwError(new Error('Invalid Booking'));
        }
      })
    );
  }

  public loadSales(
    stylistID: number,
    startDate: Date,
    endDate: Date,
    status?: List<number>
  ): Observable<List<Sale>> {
    let url = `${
      ENV_CONFIG.API_ROOT
    }/stylists/${stylistID}/sales?start=${Utilities.formatDate(
      startDate
    )}&end=${Utilities.formatDate(endDate)}&sort=desc`;

    if (status) {
      url += `&status=${status.join(',')}`;
    }

    return this.get(url).pipe(
      map((data) => {
        let sales: List<Sale> = List([]);

        for (const saleData of data) {
          sales = sales.push(Sale.parseSaleData(saleData));
        }

        return sales;
      })
    );
  }

  public loadSalonSalesByPaymentType(
    startDate: Date,
    endDate: Date,
    paymentTypes: List<number>
  ): Observable<List<Sale>> {
    let url = `${ENV_CONFIG.API_ROOT}/salons/sales?start=${Utilities.formatDate(
      startDate
    )}&end=${Utilities.formatDate(endDate)}&sort=desc`;
    url += `&paymentTypes=${paymentTypes.join(',')}`;

    return this.get(url).pipe(
      map((data) => {
        let sales: List<Sale> = List([]);

        for (const saleData of data) {
          sales = sales.push(Sale.parseSaleData(saleData));
        }

        return sales;
      })
    );
  }

  public loadSalonSales(
    startDate: Date,
    endDate: Date,
    status?: List<number>,
    forceFullDay?: boolean
  ): Observable<List<Sale>> {
    let url = `${ENV_CONFIG.API_ROOT}/salons/sales?start=${Utilities.formatDate(
      startDate
    )}&end=${Utilities.formatDate(
      endDate
    )}&sort=desc&forceFullDay=${forceFullDay}`;
    if (status) {
      url += `&status=${status.join(',')}`;
    }
    return this.get(url).pipe(
      map((data) => {
        let sales: List<Sale> = List([]);

        for (const saleData of data) {
          sales = sales.push(Sale.parseSaleData(saleData));
        }
        return sales;
      })
    );
  }

  public searchSaleByUUID(uuid: string): Observable<Sale> {
    const url = `${ENV_CONFIG.API_ROOT}/sales?uuid=${uuid}`;
    return this.get(url).pipe(
      map((data) => {

        if (data && data.length === 1) {
          return Sale.parseSaleData(data[0]);
        } else {
          throwError(new Error('Invalid Booking'));
        }
      })
    );
  }

  public loadHistory(sale: Sale): Observable<Sale> {
    const url = `${ENV_CONFIG.API_ROOT}/sales/${sale.getID()}/history`;
    return this.get(url).pipe(
      map((data) => {
        for (const saleRawData of data) {
          // const total = parseFloat(saleRawData['paymentsTotal']);
          const history: Sale = Sale.parseSaleData(saleRawData);
          // history.setTotal(total);
          sale = sale.addHistory(history);
        }

        return sale;
      })
    );
  }

  public loadClientSalesHistory(clientID: number): Observable<List<Sale>> {
    const url = `${ENV_CONFIG.API_ROOT}/clients/${clientID}/sales`;
    let sales: List<Sale> = List([]);

    return this.get(url).pipe(
      map((data) => {
        for (const saleRawData of data) {
          // const total = parseFloat(saleRawData['paymentsTotal']);
          const history: Sale = Sale.parseSaleData(saleRawData);
          // history.setTotal(total);
          sales = sales.push(history);
        }

        return sales;
      })
    );
  }

  public save(sale: Sale): Observable<Sale> {
    // TODO: make sure updating sale won't create a new sale instead of updating the existing sale
    // it seems like the backend also require post for updating a sale with sale_edit_parent
    let url = `${ENV_CONFIG.API_ROOT}/sales`;
    url += sale.getID() !== undefined ? '/' + sale.getID() : '';

    if (sale.getID() !== undefined) {
      // update
      return this.put(url, sale.serialize()).pipe(
        map((data) => {
          return Sale.parseSaleData(data);
        })
      );
    } else {
      // create
      return this.post(url, sale.serialize()).pipe(
        map((data) => {
          return Sale.parseSaleData(data);
        })
      );
    }
  }

  public void(sale: Sale): Observable<Sale> {
    const url = `${ENV_CONFIG.API_ROOT}/sales/${sale.getID()}`;
    return this.delete(url, {}).pipe(
      map(() => {
        return sale.setStatus(5);
      })
    );
  }

  public cancel(sale: Sale): Observable<boolean> {
    const url = `${ENV_CONFIG.API_ROOT}/sales/${sale.getID()}`;
    return this.delete(url, {}).pipe(
      map(() => {
        return true;
      })
    );
  }

  public fetchReceiptHTML(sale: Sale): Observable<string> {
    const url = `${ENV_CONFIG.API_ROOT}/sales/${sale.id}/receipt`;
    return this.get(url);
  }

  public sendReceipt(sale: Sale, email: string): Observable<boolean> {
    const url = `${ENV_CONFIG.API_ROOT}/sales/${sale.getID()}/receipt`;
    return this.post(url, {
      recipient: email
    }).pipe();
  }

  // public static refundSale (saleID: number, lineItemIDs: Array<number>) : Q.Promise <Sale> {
  //   const defered = Q.defer<Sale>();

  //   $.ajax({
  //     url: ENV_CONFIG.API_ROOT + '/sales/' + saleID + '/refund',
  //     type: 'post',
  //     dataType: "json",
  //     data: {
  //       lineItemIDs: lineItemIDs
  //     },
  //     success: (data) => {

  //       if (data.success === false) {
  //         defered.reject("An error occured");
  //         return;
  //       }

  //       const response = Sale.parseSaleData(data.data);
  //       defered.resolve(response);

  //     },
  //     error: (() => {
  //       defered.reject(new Error('An error occured'));
  //     })
  //   });

  //   return defered.promise;
  // }

  // public editCompletedSale (saleID: number) : Observable <Sale> {
  //   return new Observable <Sale> ((observer) => {
  //     const url = `${ENV_CONFIG.API_ROOT}/sales/${saleID}/edit`;
  //     this.post(url, {})
  //       .map((res: Response) => {
  //         const body = res.json();
  //         const data = body.data;
  //         return Sale.parseSaleData(data);
  //       })
  //       .subscribe((sale: Sale) => {
  //         observer.next(sale);
  //         observer.complete();
  //       },
  //       (err) => {
  //         observer.error(this.errorHandlerService.handleError(err));
  //         observer.complete();
  //       });

  //     });
  // }

  // public static refundCompletedSale (salonID: number, stylistID: number, saleID: number, lineItemIDs: Array<number>) : Q.Promise<Sale> {

  //   // TODO validate parameters
  //   const defered = Q.defer<Sale>();
  //   $.ajax({
  //     url: ENV_CONFIG.API_ROOT + `/sales/${saleID}/refund`,
  //     type: 'post',
  //     dataType: "json",
  //     data: {
  //       salonID: salonID,
  //       stylistID: stylistID,
  //       lineItemIDs: lineItemIDs
  //     },
  //     success: (data) => {
  //       if (data.success === false) {
  //         defered.reject("An error occured");
  //         return;
  //       }

  //       defered.resolve(Sale.parseSaleData(data.data));
  //     },
  //     error: (() => {
  //       defered.reject(new Error('An error occured'));
  //     })
  //   });

  //   return defered.promise;
  // }

  public createRawSaleForClient(client: Client): Observable<Sale> {
    return this.createRawSaleForWalkInClient().pipe(
      map((sale: Sale) => sale.setClient(client))
    );
  }

  public createRawSaleForWalkInClient(): Observable<Sale> {
    return this.salonService.getTaxes().pipe(
      map((taxes: List<Tax>) => {
        return new Sale({
          id: undefined,
          uuid: undefined,
          client: new Client({
            id: null,
            firstName: 'Walk In',
            lastName: '',
            title:'',
            email: '',
            phone1: '',
            phone2: '',
            address1: '',
            address2: '',
            city: '',
            province: '',
            postal: '',
            country: undefined,
            pronouns:0,
            customPronouns:'',
            workPh: '',
            workExt: '',
            reminderType: '0',
            badEmail: 0,
            blockedOnline: 0,
            unsubscribed: 0,
            notes: '',
            stylistID: undefined,
            salonID: undefined,
            birthDate: undefined,
            profilePhoto: '',
            isChatboxEnabled: true,
            isChatBubbleEnabled: true,
          }),
          stylist: undefined,
          datetime: new Date(),
          dateModified: new Date(),
          lineitems: List([]),
          status: undefined,
          taxes,
          tips: List([]),
          payments: List([]),
          saleEditParent: undefined,
          saleRefundParent: undefined,
          cashRounding: 0,
          history: List([]),
          cachedTotalsFromServer: 0,
        
        });
      })
    );
  }

  public createRawSaleFromABooking(booking: Booking): Observable<Sale> {
    return this.createRawSaleForClient(booking.getClient()).pipe(
      map((sale: Sale) => {
        booking.services.forEach((service) => {
          service = service.setTaxRateType(0);
          sale = sale.addLineItem(service);
        });

        return sale;
      })
    );
  }

  public createSaleFromBookingGroupID(
    stylist: Stylist,
    bookingGroupID: number
  ): Observable<Sale> {
    return this.bookingService.loadBookingByID(bookingGroupID).pipe(
      switchMap((booking: Booking) => {
        if (booking.getStatus() === 8) {
          return throwError(
            new Error(
              'Checking out a booking group with a status of 8 is not allowed'
            )
          );
        }

        const sale = new Sale({
          id: undefined,
          uuid: undefined,
          client: booking.getClient(),
          stylist,
          datetime: new Date(),
          dateModified: new Date(),
          status: 2,
          lineitems: booking.getServices(),
          taxes: List([]),
          tips: List([]),
          payments: List([]),
          saleEditParent: undefined,
          saleRefundParent: undefined,
          cashRounding: 0,
          history: List([])
        });

        return this.save(sale);
      })
    );
  }

  public createSaleFromClientID(
    stylist: Stylist,
    client: Client = null
  ): Observable<Sale> {
    const sale = new Sale({
      id: undefined,
      uuid: undefined,
      client,
      stylist,
      datetime: new Date(),
      dateModified: new Date(),
      status: 2,
      lineitems: List([]),
      taxes: List([]),
      tips: List([]),
      payments: List([]),
      saleEditParent: undefined,
      saleRefundParent: undefined,
      cashRounding: 0,
      history: List([])
    });

    return this.save(sale);
  }

  public setSaleToBeCheckedOut(
    sale: Sale,
    checkOutType: SALE_CHECKOUT_TYPE = SALE_CHECKOUT_TYPE.salonMonsterPay,
    checkOutPromise?: () => any
  ) {
    this.saleToBeCheckedOut = sale;
    this.checkOutType = checkOutType;
    this.checkOutPromise = checkOutPromise;
  }

  public getSaleToBeCheckedOut(): Sale {
    return this.saleToBeCheckedOut;
  }

  public getCheckOutPromise(): () => any {
    return this.checkOutPromise ? this.checkOutPromise : () => {};
  }

  public getCheckOutType(): SALE_CHECKOUT_TYPE {
    return this.checkOutType;
  }
}
