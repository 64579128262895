import { Cloneable } from '../cloneable.model';
import { Serializable } from '../serializable.model';
import { SocialMediaLinksJSON } from './social-media-links-json.model';
import { SocialMediaLinksSerialized } from './social-media-links-serialized.model';

export class SocialMediaLinks
  implements
    Cloneable<SocialMediaLinks, SocialMediaLinksJSON>,
    Serializable<SocialMediaLinksSerialized> {
  public readonly facebook: string;
  public readonly googleplus: string;
  public readonly id: number;
  public readonly instagram: string;
  public readonly pinterest: string;
  public readonly tumblr: string;
  public readonly twitter: string;
  public readonly yelp: string;

  constructor(options: SocialMediaLinksJSON) {
    this.facebook = options.facebook;
    this.googleplus = options.googleplus;
    this.id = options.id;
    this.instagram = options.instagram;
    this.pinterest = options.pinterest;
    this.tumblr = options.tumblr;
    this.twitter = options.twitter;
    this.yelp = options.yelp;
  }

  public static parseSocialMediaLinks(data: Object): SocialMediaLinks {
    return new SocialMediaLinks({
      facebook: data['facebook'],
      googleplus: data['googleplus'],
      id: data['id'],
      instagram: data['instagram'],
      pinterest: data['pinterest'],
      tumblr: data['tumblr'],
      twitter: data['twitter'],
      yelp: data['yelp']
    });
  }

  public clone(): SocialMediaLinks {
    return new SocialMediaLinks(this.toJSON());
  }

  public toJSON(): SocialMediaLinksJSON {
    return {
      facebook: this.getFacebook(),
      googleplus: this.getGoogleplus(),
      id: this.getId(),
      instagram: this.getInstagram(),
      pinterest: this.getPinterest(),
      tumblr: this.getTumblr(),
      twitter: this.getTwitter(),
      yelp: this.getYelp()
    };
  }

  public serialize(): SocialMediaLinksSerialized {
    return this.toJSON();
  }

  public getId(): number {
    return this.id;
  }

  public getFacebook(): string {
    return this.facebook;
  }

  public getTwitter(): string {
    return this.twitter;
  }

  public getInstagram(): string {
    return this.instagram;
  }

  public getGoogleplus(): string {
    return this.googleplus;
  }

  public getPinterest(): string {
    return this.pinterest;
  }

  public getTumblr(): string {
    return this.tumblr;
  }

  public getYelp(): string {
    return this.yelp;
  }

  public setId(value: number): SocialMediaLinks {
    const data: SocialMediaLinksJSON = this.toJSON();
    data.id = value;
    return new SocialMediaLinks(data);
  }

  public setFacebook(value: string): SocialMediaLinks {
    const data: SocialMediaLinksJSON = this.toJSON();
    data.facebook = value;
    return new SocialMediaLinks(data);
  }

  public setTwitter(value: string): SocialMediaLinks {
    const data: SocialMediaLinksJSON = this.toJSON();
    data.twitter = value;
    return new SocialMediaLinks(data);
  }

  public setInstagram(value: string): SocialMediaLinks {
    const data: SocialMediaLinksJSON = this.toJSON();
    data.instagram = value;
    return new SocialMediaLinks(data);
  }

  public setGoogleplus(value: string): SocialMediaLinks {
    const data: SocialMediaLinksJSON = this.toJSON();
    data.googleplus = value;
    return new SocialMediaLinks(data);
  }

  public setPinterest(value: string): SocialMediaLinks {
    const data: SocialMediaLinksJSON = this.toJSON();
    data.pinterest = value;
    return new SocialMediaLinks(data);
  }

  public setTumblr(value: string): SocialMediaLinks {
    const data: SocialMediaLinksJSON = this.toJSON();
    data.tumblr = value;
    return new SocialMediaLinks(data);
  }

  public setYelp(value: string): SocialMediaLinks {
    const data: SocialMediaLinksJSON = this.toJSON();
    data.yelp = value;
    return new SocialMediaLinks(data);
  }
}
