import { Cloneable } from '../cloneable.model';
import { ProductBrandJSON } from './product-brand-json.model';
import { ProductBrandSerialized } from './product-brand-serialized.model';
import { Serializable } from '../serializable.model';

export class ProductBrand
  implements
    Cloneable<ProductBrand, ProductBrandJSON>,
    Serializable<ProductBrandSerialized> {
  constructor(options: ProductBrandJSON) {
    this.id = options.id;
    this.name = options.name;
  }
  public readonly id: number;

  public readonly name: string;

  public static parse(params: Object): ProductBrand {
    return new ProductBrand({
      id: params['id'],
      name: params['name']
    });
  }

  public getID(): number {
    return this.id;
  }

  public setID(value: number): ProductBrand {
    const data: ProductBrandJSON = this.toJSON();
    data.id = value;
    return new ProductBrand(data);
  }

  public getName(): string {
    return this.name;
  }

  public setName(value: string): ProductBrand {
    const data: ProductBrandJSON = this.toJSON();
    data.name = value;
    return new ProductBrand(data);
  }

  public toJSON(): ProductBrandJSON {
    return {
      id: this.id,
      name: this.name
    };
  }

  public clone(): ProductBrand {
    return new ProductBrand(this.toJSON());
  }

  public serialize(): ProductBrandSerialized {
    return {
      id: this.id,
      name: this.name
    };
  }
}
