import { Cloneable } from '../cloneable.model';
import { ProductCategoryJSON } from './product-category-json.model';
import { ProductCategorySerialized } from './product-category-serialized.model';
import { Serializable } from '../serializable.model';

export class ProductCategory
  implements
    Cloneable<ProductCategory, ProductCategoryJSON>,
    Serializable<ProductCategorySerialized> {
  constructor(options: ProductCategoryJSON) {
    this.id = options.id;
    this.name = options.name;
  }
  public readonly id: number;

  public readonly name: string;

  public static parse(params: Object): ProductCategory {
    return new ProductCategory({
      id: params['id'],
      name: params['name']
    });
  }

  public getID(): number {
    return this.id;
  }

  public setID(value: number): ProductCategory {
    const data: ProductCategoryJSON = this.toJSON();
    data.id = value;
    return new ProductCategory(data);
  }

  public getName(): string {
    return this.name;
  }

  public setName(value: string): ProductCategory {
    const data: ProductCategoryJSON = this.toJSON();
    data.name = value;
    return new ProductCategory(data);
  }

  public toJSON(): ProductCategoryJSON {
    return {
      id: this.id,
      name: this.name
    };
  }

  public clone(): ProductCategory {
    return new ProductCategory(this.toJSON());
  }

  public serialize(): ProductCategorySerialized {
    return {
      id: this.id,
      name: this.name
    };
  }
}
