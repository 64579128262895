import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';

import { ENV_CONFIG } from 'src/bin/env.config';
import { ErrorHandlerService } from './error-handler.service';
import { Injectable } from '@angular/core';
import { List } from 'immutable';
import { Observable } from 'rxjs';
import { SalonmonsterHttpClient } from '../services/salonmonster-http-client';
import { ServiceDefinition } from 'src/app/models';
import { UserService } from '../services/user.service';
import { map, mapTo } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ServiceDefinitionService extends SalonmonsterHttpClient {
  private serviceDefinitions: List<ServiceDefinition>;

  constructor(
    http: HttpClient,
    userService: UserService,
    protected errorHandlerService: ErrorHandlerService
  ) {
    super(http, userService, errorHandlerService);
    this.serviceDefinitions = List.of<ServiceDefinition>();

    userService.getLogOutMonitor().subscribe((logout) => {
      if (logout) {
        this.serviceDefinitions = List([]);
      }
    });
  }

  public getServiceDefinitions(
    stylistID: number
  ): Observable<List<ServiceDefinition>> {
    const url = `${ENV_CONFIG.API_ROOT}/stylists/${stylistID}/servicedetails`;
    return this.get(url).pipe(
      map((data) => {
        let serviceDefinitions: List<ServiceDefinition> = List.of<
          ServiceDefinition
        >();

        data.forEach((element) => {
          serviceDefinitions = serviceDefinitions.push(
            ServiceDefinition.parseServiceDefinition(element)
          );
        });

        this.serviceDefinitions = serviceDefinitions;
        return this.serviceDefinitions;
      })
    );
  }

  public saveServiceDefinition(
    serviceDefinition: ServiceDefinition
  ): Observable<ServiceDefinition> {
    let url = `${ENV_CONFIG.API_ROOT}/servicedetails`;

    if (serviceDefinition.getID()) {
      url += '/' + serviceDefinition.getID();
      return this.put(url, serviceDefinition.serialize());
    } else {
      return this.post(url, serviceDefinition.serialize()).pipe(
        map((data) => {
          return serviceDefinition.setID(data.id);
        })
      );
    }
  }

  public offerSalonServiceDetail(
    serviceDefinition: ServiceDefinition
  ): Observable<number> {
    const url = `${ENV_CONFIG.API_ROOT}/servicedetails/offer`;
    return this.post(url, serviceDefinition.serialize());
  }

  public deleteServiceDefinition(
    serviceDefinition: ServiceDefinition
  ): Observable<ServiceDefinition> {
    const url = `${ENV_CONFIG.API_ROOT}/servicedetails/${serviceDefinition.id}`;
    return this.delete(url, {});
  }

  public deleteServiceParent(serviceParentID: number): Observable<boolean> {
    const url = `${ENV_CONFIG.API_ROOT}/servicedetails/serviceparent/${serviceParentID}`;
    return this.delete(url, {}).pipe(mapTo(true));
  }

  public getServiceParentServiceDefinitions(
    parentID: number
  ): Observable<List<ServiceDefinition>> {
    const url = `${ENV_CONFIG.API_ROOT}/servicedetails?parentID=${parentID}`;
    return this.get(url).pipe(
      map((data) => {
        let serviceDefinitions: List<ServiceDefinition> = List.of<
          ServiceDefinition
        >();

        data.forEach((element) => {
          serviceDefinitions = serviceDefinitions.push(
            ServiceDefinition.parseServiceDefinition(element)
          );
        });

        this.serviceDefinitions = serviceDefinitions;
        return this.serviceDefinitions;
      })
    );
  }

  public saveSalonService(
    parentID: number,
    serviceName: string,
    description: string,
    startingAt: number,
    categoryID: number,
    serviceDefinitions: List<ServiceDefinition>
  ): Observable<number> {
    let url = `${ENV_CONFIG.API_ROOT}/salons/services`;

    const params = {
      name: serviceName,
      description,
      startingAt,
      categoryID,
      stylistServiceDetails: []
    };

    serviceDefinitions.forEach((sd) => {
      params.stylistServiceDetails.push(sd.serialize());
    });

    if (parentID) {
      url += `/${parentID}`;

      return this.put(url, params).pipe(
        map((data) => {
          return data;
        })
      );
    } else {
      return this.post(url, params).pipe(
        map((data) => {
          return data;
        })
      );
    }
  }
}
