import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpResponse
} from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, map, skip } from 'rxjs/operators';
import { LoadingManagerService } from '../services';

interface LoadingManagerType {
  url: string;
  startToggle: boolean;
}

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  loadingManager: BehaviorSubject<LoadingManagerType> = new BehaviorSubject({
    url: '',
    startToggle: false
  });

  loadingUrlQueue: string[] = [];

  private get randomNumberAsString() {
    return '' + Math.floor(Math.random() * 10000) + 1;
  }

  private get urlsNotToIntercept(): string[] {
    return [];
  }

  constructor(private loadingService: LoadingManagerService) {
    this.init();
  }

  private init() {
    this.loadingService.initLoadingComponent();
    this.initLoadingManagerListener();
  }

  private shouldInterceptUrl(url: string) {
    const shouldIntercept = !this.urlsNotToIntercept.some((val) => {
      return url.endsWith(val);
    });

    return shouldIntercept;
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.shouldInterceptUrl(request.url)) {
      const urlWithId = request.url + this.randomNumberAsString;

      this.loadingManager.next({ url: urlWithId, startToggle: true });

      return next
        .handle(request)
        .pipe(
          catchError((err) => {
            this.loadingManager.next({ url: urlWithId, startToggle: false });
            return throwError(err);
          })
        )
        .pipe(
          map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
            if (evt instanceof HttpResponse) {
              this.loadingManager.next({
                url: urlWithId,
                startToggle: false
              });
            }
            return evt;
          })
        );
    }
    return next.handle(request);
  }

  private initLoadingManagerListener() {
    this.loadingManager.pipe(skip(1)).subscribe((payload) => {
      this.handleLoadingManager(payload);
    });
  }

  private handleLoadingManager(payload: LoadingManagerType) {
    if (payload.startToggle) {
      this.loadingUrlQueue.push(payload.url);
    } else {
      const urlIndex = this.loadingUrlQueue.indexOf(payload.url);
      if (urlIndex > -1) {
        this.loadingUrlQueue.splice(urlIndex, 1);
      }
    }
    if (this.loadingUrlQueue.length > 0) {
      //TODO: fix the loading service
      // this.loadingService.startLoading();
    } else {
      // this.loadingService.stopLoading();
    }
  }
}
