import { Cloneable } from '../cloneable.model';
import { Serializable } from '../serializable.model';
import { SpecialHourJSON } from './special-hour-json.model';
import { SpecialHourSerialized } from './special-hour-serialized.model';

export class SpecialHour
  implements
    Cloneable<SpecialHour, SpecialHourJSON>,
    Serializable<SpecialHourSerialized> {
  public readonly id: number;

  public readonly stylistID: number;

  public readonly date: string;

  public readonly startTime: string;

  public readonly endTime: string;

  constructor(options: SpecialHourJSON) {
    this.id = options.id;
    this.stylistID = options.stylistID;
    this.date = options.date;
    this.startTime = options.startTime;
    this.endTime = options.endTime;
  }

  public static parseSpecialHour(data: Object): SpecialHour {
    return new SpecialHour({
      id: data['id'],
      stylistID: data['stylistID'],
      date: data['date'],
      startTime: data['startTime'],
      endTime: data['endTime']
    });
  }

  public clone(): SpecialHour {
    return new SpecialHour(this.toJSON());
  }

  public toJSON(): SpecialHourJSON {
    return {
      id: this.getId(),
      stylistID: this.getStylistID(),
      date: this.getDate(),
      startTime: this.getStartTime(),
      endTime: this.getEndTime()
    };
  }

  public serialize(): SpecialHourSerialized {
    return this.toJSON();
  }

  public getId(): number {
    return this.id;
  }

  public getStylistID(): number {
    return this.stylistID;
  }

  public getDate(): string {
    return this.date;
  }

  public getStartTime(): string {
    return this.startTime;
  }

  public getEndTime(): string {
    return this.endTime;
  }

  public setId(value: number): SpecialHour {
    const data: SpecialHourJSON = this.toJSON();
    data.id = value;
    return new SpecialHour(data);
  }

  public setStylistID(value: number): SpecialHour {
    const data: SpecialHourJSON = this.toJSON();
    data.stylistID = value;
    return new SpecialHour(data);
  }

  public setDate(value: string): SpecialHour {
    const data: SpecialHourJSON = this.toJSON();
    data.date = value;
    return new SpecialHour(data);
  }

  public setStartTime(value: string): SpecialHour {
    const data: SpecialHourJSON = this.toJSON();
    data.startTime = value;
    return new SpecialHour(data);
  }

  public setEndTime(value: string): SpecialHour {
    const data: SpecialHourJSON = this.toJSON();
    data.endTime = value;
    return new SpecialHour(data);
  }
}
