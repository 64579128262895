import { Injectable } from '@angular/core';

import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class ExportTableService {
  constructor() {}

  public columns: any[];

  public exportPdf(exportArray: any[], outputFileName: string, cols: any[]) {
    const doc: any = new jsPDF();
    this.columns = cols.map((col) => ({
      title: col.header,
      dataKey: col.field
    }));
    doc.autoTable(this.columns, exportArray);
    doc.save(`SalonMonster_` + outputFileName + `_report`);
  }
  public exportExcel(
    exportArray: any[],
    outputFileName: string,
    header: string[]
  ) {
    const worksheet = XLSX.utils.json_to_sheet([], { header });
    XLSX.utils.sheet_add_json(worksheet, exportArray, {
      skipHeader: true,
      origin: 'A2'
    });
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    });
    this.saveAsExcelFile(
      excelBuffer,
      `SalonMonster_` + outputFileName + `_report`
    );
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(
      data,
      fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
    );
  }
}
