import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';

import { Currency } from '../models';
import { ENV_CONFIG } from '../../bin/env.config';
import { ErrorHandlerService } from './error-handler.service';
import { Injectable } from '@angular/core';
import { List } from 'immutable';
import { Observable } from 'rxjs';
import { SalonmonsterHttpClient } from '../services/salonmonster-http-client';
import { UserService } from './user.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService extends SalonmonsterHttpClient {
  constructor(
    http: HttpClient,
    userService: UserService,
    protected errorHandlerService: ErrorHandlerService
  ) {
    super(http, userService, errorHandlerService);
  }

  public getCurrencies(): Observable<List<Currency>> {
    const url = `${ENV_CONFIG.API_ROOT}/currencies`;
    return this.get(url).pipe(
      map((data) => {
        let currencies: List<Currency> = List([]);

        for (const currencyData of data) {
          currencies = currencies.push(Currency.parse(currencyData));
        }

        return currencies;
      })
    );
  }
}
