import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';

import { ENV_CONFIG } from 'src/bin/env.config';
import { ErrorHandlerService } from './error-handler.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OnBoarding } from 'src/app/models';
import { SalonmonsterHttpClient } from '../services/salonmonster-http-client';
import { UserService } from '../services/user.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PhotoService extends SalonmonsterHttpClient {
  constructor(
    http: HttpClient,
    protected errorHandlerService: ErrorHandlerService,
    protected userService: UserService
  ) {
    super(http, userService, errorHandlerService);
  }

  public convertPhotoURLToBase64(photoUrl: string): Observable<string> {
    const url = `${ENV_CONFIG.API_ROOT}/photo/convert/base64`;
    return this.post(url, { url: photoUrl });
  }
}
