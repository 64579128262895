import { Booking, Email, EmailType, Sale, Service } from 'src/app/models';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';

import { ENV_CONFIG } from 'src/bin/env.config';
import { ErrorHandlerService } from './error-handler.service';
import { Injectable } from '@angular/core';
import { List } from 'immutable';
import { SalonmonsterHttpClient } from '../services/salonmonster-http-client';
import { UserService } from '../services/user.service';
import { Utilities } from '../utilities/utilities';
import { map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class BookingService extends SalonmonsterHttpClient {
  private bookings: List<Booking>;

  private bookingRequests: List<Booking>;

  constructor(
    http: HttpClient,
    protected userService: UserService,
    protected errorHandlerService: ErrorHandlerService
  ) {
    super(http, userService, errorHandlerService);
    this.bookings = List.of<Booking>();

    this.userService.getLogOutMonitor().subscribe((logout) => {
      if (logout) {
        this.bookings = List([]);
        this.bookingRequests = List([]);
      }
    });
  }

  public loadBookingByID(bookingID: number): Observable<Booking> {
    const url = `${ENV_CONFIG.API_ROOT}/bookings/${bookingID}`;
    return this.get(url).pipe(
      map((res) => {
        // const body = res.json();
        // const data = body.data;
        if (res && res.length === 1) {
          return Booking.parseBooking(res[0]);
        } else {
          throwError(new Error('Invalid Booking'));
        }
      })
    );
  }

  public loadBookings(
    startDate: Date,
    endDate: Date,
    stylistID: number,
    statuses?: number[]
  ): Observable<List<Booking>> {
    const start = Utilities.formatDate(startDate, 'Y-MM-DD');
    const end = Utilities.formatDate(endDate, 'Y-MM-DD');
    const sid =
      stylistID !== undefined ? stylistID : this.userService.getUser().getID();
    let url = `${ENV_CONFIG.API_ROOT}/stylists/${sid}/clients/bookings?start=${start}&end=${end}`;

    if (statuses) {
      url += `&status=${statuses.join(',')}`;
    }

    return this.get(url).pipe(
      map((data) => {
        let bookings: List<Booking> = List.of<Booking>();
        for (const bookingData of data) {
          bookings = bookings.push(Booking.parseBooking(bookingData));
        }

        this.bookings = bookings;
        return this.bookings;
      })
    );
  }

  public saveBooking(booking: Booking): Observable<Booking> {
    let url = `${ENV_CONFIG.API_ROOT}/bookings`;
    url += booking.getId() !== undefined ? '/' + booking.getId() : '';

    if (booking.getId() !== undefined) {
      // update
      return this.put(url, booking.serialize()).pipe(
        map((data) => {
          return Booking.parseBooking(data);
        })
      );
    } else {
      // create
      return this.post(url, booking.serialize()).pipe(
        map((data) => {
          return Booking.parseBooking(data);
        })
      );
    }
  }

  public deleteBooking(
    booking: Booking,
    action: string = 'single'
  ): Observable<Booking> {
    const url = `${ENV_CONFIG.API_ROOT}/bookings/${booking.getId()}`;
    return this.delete(url, { action, sendEmail: booking.getSendEmail(), sendSMS: booking.getSendSMS() }).pipe(
      map(() => {
        return booking.setStatus(6);
      })
    );
  }

  public loadBookingRequests(stylistID: number): Observable<List<Booking>> {
    const url = `${ENV_CONFIG.API_ROOT}/stylists/${stylistID}/requests`;
    return this.get(url).pipe(
      map((data) => {
        let bookingRequests: List<Booking> = List.of<Booking>();
        for (const bookingData of data) {
          bookingRequests = bookingRequests.push(
            Booking.parseBooking(bookingData)
          );
        }

        this.bookingRequests = bookingRequests;
        return this.bookingRequests;
      })
    );
  }

  public approveBookingRequests(
    stylistID: number,
    requests: List<Booking>
  ): Observable<List<Booking>> {
    return new Observable<List<Booking>>((observer) => {
      const url = `${ENV_CONFIG.API_ROOT}/stylists/${stylistID}/requests`;
      let bookingGroupIDs: List<number> = List.of<number>();

      if (requests.count() === 0) {
        observer.error('empty requests');
        observer.complete();
        return;
      }

      requests.forEach((booking: Booking) => {
        bookingGroupIDs = bookingGroupIDs.push(booking.getId());
      });

      this.put(url, {
        bookingGroupIDs: bookingGroupIDs.toJSON()
      })
        .pipe(
          map(() => {
            let approvedBookings: List<Booking> = List.of<Booking>();
            requests.forEach((booking: Booking) => {
              approvedBookings = approvedBookings.push(booking.setStatus(3));
            });

            return approvedBookings;
          })
        )
        .subscribe({
          next: (bookings: List<Booking>) => {
            observer.next(bookings);
            observer.complete();
          },
          error: (err) => {
            observer.error(this.errorHandlerService.handleError(err));
            observer.complete();
          }}
        );
    });
  }

  public declineBookingRequests(
    stylistID: number,
    requests: List<Booking>,
    customDeclineMessage: string = ''
  ): Observable<List<Booking>> {
    return new Observable<List<Booking>>((observer) => {
      const url = `${ENV_CONFIG.API_ROOT}/stylists/${stylistID}/requests`;
      let bookingGroupIDs: List<number> = List.of<number>();

      if (requests.count() === 0) {
        observer.error('empty requests');
        observer.complete();
        return;
      }

      requests.forEach((booking: Booking) => {
        bookingGroupIDs = bookingGroupIDs.push(booking.getId());
      });

      this.delete(url, {
        bookingGroupIDs: bookingGroupIDs.toJSON(),
        customDeclineMessage
      })
        .pipe(
          map(() => {
            let declinedBookings: List<Booking> = List.of<Booking>();
            requests.forEach((booking: Booking) => {
              declinedBookings = declinedBookings.push(booking.setStatus(6));
            });

            return declinedBookings;
          })
        )
        .subscribe({
          next: (bookings: List<Booking>) => {
            observer.next(bookings);
            observer.complete();
          },
          error: (err) => {
            observer.error(this.errorHandlerService.handleError(err));
            observer.complete();
          }}
        );
    });
  }

  public loadWaitListBookings(stylistID: number): Observable<List<Booking>> {
    const url = `${ENV_CONFIG.API_ROOT}/stylists/${stylistID}/waitlist`;
    return this.get(url).pipe(
      map((data) => {
        let bookings: List<Booking> = List.of<Booking>();

        for (const bookingData of data) {
          bookings = bookings.push(Booking.parseBooking(bookingData));
        }

        return bookings;
      })
    );
  }

  public bookingOverlapChecker(
    booking: Booking,
    service: Service
  ): Observable<List<Service>> {
    const url = `${
      ENV_CONFIG.API_ROOT
    }/bookings/${service.getStylist().getID()}/overlaps`;
    const body: any = {
      startDate: Utilities.formatDate(service.getStartDateTime()),
      startDuration: service.getDurations().startDuration,
      processDuration: service.getDurations().processDuration,
      finishDuration: service.getDurations().finishDuration
    };

    if (booking.getId() !== undefined && booking.getId() !== null) {
      body.bookingGroupID = booking.getId();
    }

    if (
      booking.getRecurringSettings() !== undefined &&
      booking.getRecurringSettings() !== null
    ) {
      body.recurringID = booking.getRecurringSettings().id;
      body.frequencyCount = booking.getRecurringSettings().frequencyCount;
      body.frequencyType = booking.getRecurringSettings().frequencyType;
    }

    return this.post(url, body).pipe(
      map((data) => {
        let services: List<Service> = List.of<Service>();

        for (const serviceData of data) {
          services = services.push(Service.parseService(serviceData));
        }

        return services;
      })
    );
  }

  public loadSalesLinkedToTheBooking(booking: Booking): Observable<List<Sale>> {
    const url = `${ENV_CONFIG.API_ROOT}/bookings/${booking.id}/sales`;
    return this.get(url).pipe(
      map((data) => {
        let sales: List<Sale> = List([]);

        for (const saleData of data) {
          sales = sales.push(Sale.parseSaleData(saleData));
        }

        return sales;
      })
    );
  }

  public sendEmail(
    booking: Booking,
    emailType: EmailType,
    recipients: List<Email>
  ): Observable<boolean> {
    const url = `${ENV_CONFIG.API_ROOT}/bookings/${booking.getId()}/email`;

    let type: string;

    if (emailType === EmailType.Confirmed) {
      type = 'confirmed';
    } else if (emailType === EmailType.Updated) {
      type = 'updated';
    } else if (emailType === EmailType.Cancelled) {
      type = 'cancelled';
    }

    const payload = {
      emailType: type,
      recipients: recipients.toJSON()
    };

    return this.post(url, payload).pipe(
      map((data) => {
        if (data.sent) {
          return true;
        } else {
          return false;
        }
      })
    );
  }

  public sendSMS(booking: Booking, clientPhone: string): Observable<boolean> {
    const url = `${ENV_CONFIG.API_ROOT}/bookings/${booking.getId()}/sms`;
    const payload = {
      phoneNumber: clientPhone
    };

    return this.post(url, payload).pipe(
      map((data) => {
        if (data.sent) {
          return true;
        } else {
          return false;
        }
      })
    );
  }

  public saveBookingPhotos(booking: Booking): Observable<Booking> {
    const url = `${ENV_CONFIG.API_ROOT}/bookings/${booking.id}/photos`;
    return this.put(url, booking.serialize()).pipe(
      map((data) => {
        return Booking.parseBooking(data);
      })
    );
  }
  public onSMSTap(clientPhone: string, clientFirstName: string,platform: Platform) {
    window.location.href = `sms:${clientPhone} ${platform.is('ios') ? '&' : '?'
      }body=Hi ${clientFirstName}`;
  } 
}
