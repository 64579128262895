import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ENV_CONFIG } from 'src/bin/env.config';
import { SalonmonsterHttpClient } from '../services/salonmonster-http-client';
import { ErrorHandlerService } from './error-handler.service';
import { UserService } from './user.service';

import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

export interface CardTerminalParams {
  sessionKey: string;
  amount: number;
}

export interface CardTerminalResponse {
  respstat: string;
  payment: number;
  tipType: number;
  tipValue: number;
  calculatedTipValue: number;
}

@Injectable({
  providedIn: 'root'
})
export class CardTerminalService extends SalonmonsterHttpClient {
  constructor(
    protected userService: UserService,
    protected readonly errorHandlerService: ErrorHandlerService,
    public http: HttpClient
  ) {
    super(http, userService, errorHandlerService);
  }

  public processPayment(
    params: CardTerminalParams
  ): Observable<CardTerminalResponse> {
    const url = `${ENV_CONFIG.API_ROOT}/cardterminal/process`;
    return this.post(url, params);
  }

  // public processPayment(
  //   params: CardTerminalParams
  // ): Observable<CardTerminalResponse> {
  //   return new Observable<CardTerminalResponse>((observer) => {
  //     const url = `${ENV_CONFIG.API_ROOT}/cardterminal/process`;
  //     this.post(url, params).subscribe(
  //       (transaction) => {
  //         observer.next(transaction);
  //         observer.complete();
  //       },
  //       (err) => {
  //         observer.error(this.errorHandlerService.handleError(err));
  //         observer.complete();
  //       }
  //     );
  //   });
  // }

  public pingTerminal(): Observable<boolean> {
    const url = `${ENV_CONFIG.API_ROOT}/cardterminal/ping`;
    return this.post(url, {});
  }

  public connect(): Observable<string> {
    const url = `${ENV_CONFIG.API_ROOT}/cardterminal/connect`;
    return this.post(url, {}).pipe(map((response) => response.sessionKey));
  }

  public disconnect(sessionKey: string): Observable<boolean> {
    const url = `${ENV_CONFIG.API_ROOT}/cardterminal/disconnect`;
    return this.post(url, {
      sessionKey
    }).pipe(map((response) => response.disconnected));
  }
}
