import { PaymentTypeRecord } from './payment-type-record.model';
import { SalonPaymentTypesJSON } from './salon-payment-types-json.model';

export class SalonPaymentTypes extends PaymentTypeRecord {
  constructor(params: SalonPaymentTypesJSON) {
    super(params);
  }
  public readonly account: number;
  public readonly amex: number;
  public readonly cash: number;
  public readonly cheque: number;
  public readonly debit: number;
  public readonly discover: number;
  public readonly etransfer: number;
  public readonly giftcard: number;
  public readonly id: number;
  public readonly mastercard: number;
  public readonly square: number;
  public readonly visa: number;

  public static parse(params: Object): SalonPaymentTypes {
    return new SalonPaymentTypes({
      id: params['id'],
      cash: params['cash'],
      debit: params['debit'],
      visa: params['visa'],
      mastercard: params['mastercard'],
      amex: params['amex'],
      cheque: params['cheque'],
      giftcard: params['giftcard'],
      etransfer: params['etransfer'],
      account: params['account'],
      square: params['square'],
      discover: params['discover']
    });
  }

  public setID(value: number): SalonPaymentTypes {
    return this.set('id', value) as SalonPaymentTypes;
  }

  public setCash(value: number): SalonPaymentTypes {
    return this.set('cash', value) as SalonPaymentTypes;
  }

  public setDebit(value: number): SalonPaymentTypes {
    return this.set('debit', value) as SalonPaymentTypes;
  }

  public setVisa(value: number): SalonPaymentTypes {
    return this.set('visa', value) as SalonPaymentTypes;
  }

  public setMasterCard(value: number): SalonPaymentTypes {
    return this.set('mastercard', value) as SalonPaymentTypes;
  }

  public setAMEX(value: number): SalonPaymentTypes {
    return this.set('amex', value) as SalonPaymentTypes;
  }

  public setCheque(value: number): SalonPaymentTypes {
    return this.set('cheque', value) as SalonPaymentTypes;
  }

  public setGiftcard(value: number): SalonPaymentTypes {
    return this.set('giftcard', value) as SalonPaymentTypes;
  }

  public setEtransfer(value: number): SalonPaymentTypes {
    return this.set('etransfer', value) as SalonPaymentTypes;
  }

  public setAccount(value: number): SalonPaymentTypes {
    return this.set('account', value) as SalonPaymentTypes;
  }

  public setSquare(value: number): SalonPaymentTypes {
    return this.set('square', value) as SalonPaymentTypes;
  }

  public setDiscover(value: number): SalonPaymentTypes {
    return this.set('discover', value) as SalonPaymentTypes;
  }

  public serialize(): Object {
    return {
      id: this.id,
      cash: this.cash,
      debit: this.debit,
      visa: this.visa,
      mastercard: this.mastercard,
      amex: this.amex,
      cheque: this.cheque,
      giftcard: this.giftcard,
      etransfer: this.etransfer,
      account: this.account,
      square: this.square,
      discover: this.discover
    };
  }
}
