import { Cloneable } from '../cloneable.model';
import { Country } from '../country/country.model';
import { Serializable } from '../serializable.model';
import { UserJSON } from './user-json.model';
import { UserSerialized } from './user-serialized.model';

export abstract class User<T extends User<T>>
  implements Cloneable<T, UserJSON>, Serializable<UserSerialized> {
  public readonly id: number;
  public readonly firstName: string;
  public readonly lastName: string;
  public readonly title: string;
  public readonly email: string;

  public readonly isChatboxEnabled: boolean;

  public readonly phone1: string;

  public readonly phone2: string;

  public readonly address1: string;

  public readonly address2: string;

  public readonly city: string;

  public readonly province: string;

  public readonly postal: string;
  public readonly isChatBubbleEnabled: boolean;

  public readonly country: Country;

  constructor(options?: UserJSON) {
    if (options) {
      this.id = options.id;
      this.firstName = options.firstName;
      this.lastName = options.lastName;
      this.title = options.title;
      this.email = options.email;
      this.phone1 = options.phone1;
      this.phone2 = options.phone2;
      this.address1 = options.address1;
      this.address2 = options.address2;
      this.city = options.city;
      this.province = options.province;
      this.postal = options.postal;
      this.isChatboxEnabled = options.isChatboxEnabled;
      this.isChatBubbleEnabled = options.isChatBubbleEnabled;
      this.country = options.country || new Country(undefined, '');
    } else {
      this.country = new Country(undefined, '');
    }
  }

  public abstract setID(newID: number): T;

  public abstract setFirstName(firstName: string): T;

  public abstract setLastName(lastName: string): T;
  public abstract setTitle(title: string): T;

  public abstract setEmail(email: string): T;

  public abstract setPhone1(phone1: string): T;

  public abstract setPhone2(phone2: string): T;

  public abstract setAddress1(address1: string): T;

  public abstract setAddress2(address2: string): T;

  public abstract setCity(city: string): T;

  public abstract setProvince(province: string): T;

  public abstract setPostal(postal: string): T;

  public abstract setCountry(newCountry: Country): T;

  public getID(): number {
    return this.id;
  }

  public getFirstName(): string {
    return this.firstName;
  }

  public getLastName(): string {
    return this.lastName;
  }

  public getTitle(): string {
    return this.title;
  }

  public getEmail(): string {
    return this.email;
  }

  public getPhone1(): string {
    return this.phone1;
  }

  public getPhone2(): string {
    return this.phone2;
  }

  public getAddress1(): string {
    return this.address1;
  }

  public getAddress2(): string {
    return this.address2;
  }

  public getCity(): string {
    return this.city;
  }

  public getProvince(): string {
    return this.province;
  }

  public getPostal(): string {
    return this.postal;
  }

  public getCountry(): Country {
    return this.country;
  }
  public getIsChatboxEnabled(): boolean 
  {
    return this.isChatboxEnabled;
  }
  public getIsChatBubbleEnabled(): boolean
  {
    return this.isChatBubbleEnabled; 
  }

  public abstract clone(): T;

  public toJSON(): UserJSON {
    return {
      id: this.getID(),
      firstName: this.getFirstName(),
      lastName: this.getLastName(),
      title: this.getTitle(),
      email: this.getEmail(),
      address1: this.getAddress1(),
      address2: this.getAddress2(),
      phone1: this.getPhone1(),
      phone2: this.getPhone2(),
      city: this.getCity(),
      postal: this.getPostal(),
      province: this.getProvince(),
      country: this.getCountry(),
      isChatboxEnabled: this.getIsChatboxEnabled(),
      isChatBubbleEnabled: this.getIsChatBubbleEnabled(),

    };
  }

  public serialize(): UserSerialized {
    return this.toJSON();
  }
}
