import { Record } from 'immutable';

export const TaxRecord = Record({
  appliesTo: 0,
  bizNo: undefined,
  id: undefined,
  name: '',
  rate: 0,
  totals: 0
});
