import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';

import { ENV_CONFIG } from 'src/bin/env.config';
import { ErrorHandlerService } from './error-handler.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SalonmonsterHttpClient } from '../services/salonmonster-http-client';
import { SocialMediaLinks } from 'src/app/models';
import { UserService } from '../services/user.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SocialMediaLinksService extends SalonmonsterHttpClient {
  constructor(
    http: HttpClient,
    userService: UserService,
    protected errorHandlerService: ErrorHandlerService
  ) {
    super(http, userService, errorHandlerService);
  }

  public getStylistSocialMediaLinks(
    stylistID: number,
    forceLoad?: boolean
  ): Observable<SocialMediaLinks> {
    const url = `${ENV_CONFIG.API_ROOT}/stylists/${stylistID}/socialmedialinks`;
    return this.get(url).pipe(
      map((data) => {
        return SocialMediaLinks.parseSocialMediaLinks(data);
      })
    );
  }

  public saveSocialMediaLinks(
    stylistID: number,
    socialMediaLinks: SocialMediaLinks
  ): Observable<SocialMediaLinks> {
    const url = `${ENV_CONFIG.API_ROOT}/stylists/${stylistID}/socialmedialinks`;
    return this.put(url, socialMediaLinks.serialize());
  }
}
