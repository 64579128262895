import { Injectable, NgZone } from '@angular/core';

import { List } from 'immutable';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Platform, AlertController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';

import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { WebIntent } from '@awesome-cordova-plugins/web-intent/ngx';
import { AppAvailability } from '@awesome-cordova-plugins/app-availability/ngx';

import { Payment, Sale } from 'src/app/models';

import { ENV_CONFIG } from 'src/bin/env.config';

import { ErrorHandlerService } from './error-handler.service';
import { SalonmonsterHttpClient } from './salonmonster-http-client';
import { UserService } from './user.service';
import { Events } from './event-handler.service';

import { Utilities } from '../utilities/utilities';

export interface SquareMoney {
  amount: number;
  currency: string;
}

// src: https://github.com/square/connect-javascript-sdk/blob/master/docs/Card.md
export interface SquareCard {
  id: string;
  card_brand: string;
  last_4: string;
  exp_month: string;
  exp_year: string;
  cardholder_name: string;
}

// src: https://github.com/square/connect-javascript-sdk/blob/master/docs/TenderCardDetails.md
export interface SquareTenderCardDetails {
  status: string;
  card: SquareCard;
  entry_method: string;
}

export interface SquareTernderType {
  id: string;
  location_id: string;
  transaction_id: string;
  note: string;
  created_at: string;
  amount_money: SquareMoney;
  tip_money: SquareMoney;
  processing_fee_money: SquareMoney;
  customer_id: string;
  type: string; // 'CARD' or 'CASH'
  cash_details?: {};
  card_details?: SquareTenderCardDetails;
  // "cash_details":{"buyer_tendered_money":{"amount":50000,"currency":"CAD"},"change_back_money":{"amount":0,"currency":"CAD"}}
}

export interface SquareTransaction {
  id: string;
  location_id: string;
  created_at: string;
  tenders: SquareTernderType[];
  // "tenders": [
  // {
  //   "type":"CASH",
  //   "id":"RFRlC23zYiMGcvAiyNYXKQB",
  //   "location_id":"RE23A053B7K58",
  //   "transaction_id":"5fQxtOMBnKGmhILEyZD5W5qeV",
  //   "created_at":"2019-01-19T04:34:44Z",
  //   "amount_money":{"amount":50000,"currency":"CAD"},
  //   "processing_fee_money":{"amount":0,"currency":"CAD"},
  //   "cash_details":{"buyer_tendered_money":{"amount":50000,"currency":"CAD"},"change_back_money":{"amount":0,"currency":"CAD"}}
  // }
  // ],
  // "product":"REGISTER",
  // "client_id":"3353B899-F087-4F62-B64B-E31606976363"
}

export interface SquareLocation {
  id: string;
  name: string;
  address: {
    address_line_1: string;
    locality: string;
    administrative_district_level_1: string;
    postal_code: string;
    country: string;
  };
  timezone: string;
  capabilities: string[];
  status: string;
  created_at: string;
  merchant_id: string;
  country: string;
  language_code: string;
  currency: string;
  type: string;
}

export interface StylistSquareCredentials {
  id: number;
  stylistID: number;
  accessToken: string;
  merchantID: string;
  lastUpdated: string;
  deleted: number;
}

export interface SquareBusiness {
  id: string;
  name: string;
  email: string;
  account_type: string;
  country_code: string;
  language_code: string;
  currency_code: string;
  business_type: string;
}

@Injectable({
  providedIn: 'root'
})
export class SquarePOSService extends SalonmonsterHttpClient {
  // public accessToken: string;

  public squareBusinessLocation: SquareLocation;

  public paymentToBeAddedOnSuccess: Payment;

  constructor(
    private platform: Platform,
    private alertCtrl: AlertController,
    private inAppBrowser: InAppBrowser,
    private appAvailability: AppAvailability,
    private webIntent: WebIntent,
    protected errorHandlerService: ErrorHandlerService,
    protected userService: UserService,
    public http: HttpClient,
    public events: Events,
    public zone: NgZone
  ) {
    super(http, userService, errorHandlerService);
    // this.accessToken = localStorage.getItem('squareAccessToken');

    const businessLocation: string = localStorage.getItem(
      'squareBusinessLocation'
    );
    if (businessLocation) {
      try {
        this.squareBusinessLocation = JSON.parse(businessLocation);
      } catch (e) {}
    }

    this.userService.getLogOutMonitor().subscribe((success: boolean) => {
      if (success) {
        // this.setAccessToken(null);
        this.setSquareBusinessLocation(null);
        this.paymentToBeAddedOnSuccess = null;
      }
    });
  }

  // public getAccessToken () : string {
  //   return this.accessToken;
  // }

  // public setAccessToken (token: string) {
  //   this.accessToken = token;
  //   localStorage.setItem('squareAccessToken', this.accessToken);
  // }

  public saveOauthFromCode(code: string, stylistID: number) {
    // return new Observable <IStylistSquareCredentials> (observer => {
    const url = `${ENV_CONFIG.API_ROOT}/square/oauth/callback`;
    this.post(url, {
      code,
      stylistID
    }).subscribe({

      next: (credentials: StylistSquareCredentials) => {
        this.events.publish('updateScreen');
        // observer.next(credentials);
        // observer.complete();
      },
      error: (err) => {
        // observer.error(this.errorHandlerService.handleError(err));
        // observer.complete();
      }
    }
    );
    // });
  }

  public getSquareBusinessLocation(): SquareLocation {
    return this.squareBusinessLocation;
  }

  public setSquareBusinessLocation(data: SquareLocation) {
    this.squareBusinessLocation = data;
    localStorage.setItem(
      'squareBusinessLocation',
      JSON.stringify(this.squareBusinessLocation)
    );
  }

  // public userHasAccessToken () : boolean {
  //   return this.getAccessToken() !== 'null' && this.getAccessToken()
  // !== undefined && this.getAccessToken() !== null && this.getAccessToken() !== '';
  // }

  public requestAccessToken() {
    // request token
    // https://docs.connect.squareup.com/api/oauth/#oauthflowrequestpermission
    const url = `https://connect.squareup.com/oauth2/authorize?client_id=${ENV_CONFIG.squareApplicationID}&scope=PAYMENTS_WRITE%20PAYMENTS_READ&state=somestring`;
    this.inAppBrowser.create(url, '_system');
  }

  public saveStylistOAuthCredentials(
    accessToken: string,
    merchantID: string
  ): Observable<StylistSquareCredentials> {
    // i belive this has been deprecated
    const url = `${ENV_CONFIG.API_ROOT}/square/oauth`;
    return this.post(url, {
      accessToken,
      merchantID
    });
  }

  public getStylistOAuthCredentials(): Observable<StylistSquareCredentials> {
    const url = `${ENV_CONFIG.API_ROOT}/square/credentials`;
    return this.get(url);
  }

  public getSquareBusiness(): Observable<SquareBusiness> {
    const url = `${ENV_CONFIG.API_ROOT}/square/business`;
    return this.get(url);
  }

  public getSquareLocations(): Observable<List<SquareLocation>> {
    const url = `${ENV_CONFIG.API_ROOT}/square/locations`;
    return this.get(url).pipe(
      map((data) => {
        return List(data);
      })
    );
  }

  public fetchTransactionDetails(
    transactionID: string,
    locationID: string
  ): Observable<SquareTransaction> {
    // const url: string = `https://connect.squareup.com/v2/locations`;
    // let headers = new Headers();
    // headers.append('Authorization', `Bearer sq0atp-HgeZyyYKwxLTPUyAHA5Ipg`);
    // headers.append('Content-Type', 'application/json');
    // headers.append('Accept', 'application/json');


    // return this.http.get(url, {
    // headers: headers
    // });

    // "{"status":"ok","transaction_id":"ibwMRVdHB7NM6msFFUZ49tMF","client_transaction_id":"65F160FD-9486-49C7-979A-042AD2A5BC69"}"

    // TEST
    // this.accessToken = `sq0atp-HgeZyyYKwxLTPUyAHA5Ipg`;


    const url = `${ENV_CONFIG.API_ROOT}/square/transactions/${transactionID}?locationID=${locationID}`;
    return this.get(url);
  }

  public setSquarePayment(payment: Payment) {
    this.paymentToBeAddedOnSuccess = payment;
  }

  public launchPOS(sale: Sale) {
    if (
      !this.platform.is('capacitor') ||
      (!this.platform.is('ios') && !this.platform.is('android'))
    ) {
      return;
    }

    // if (!this.userHasAccessToken()) {
    //   this.requestAccessToken();
    //   return;
    // } else {
    // }

    // src: https://docs.connect.squareup.com/articles/point-of-sale-api-ios
    const iosSchemaName = 'square-commerce-v1://';
    const androidPackageName = 'com.squareup';
    let squareDeepLink: string;
    let appStoreLink: string;
    let app: string;
    let currencyCode = 'USD';

    switch (this.userService.getSalon().getCountry().id) {
      case 124:
        currencyCode = 'CAD';
        break;
      case 826:
        currencyCode = 'GBP';
        break;
      case 36:
        currencyCode = 'AUD';
        break;
      case 356:
        currencyCode = 'INR';
        break;
      case 356:
        currencyCode = 'INR';
        break;
      case 729:
        currencyCode = 'ZAR';
        break;
      case 76:
        currencyCode = 'BRL';
        break;
      // case 76:
      //   currencyCode = "JPY";
      //   break;
      default:
        break;
    }

    const totalAmountInCents: number = Utilities.toCents(sale.total);

    // For payments in Australia, UK, or Japan, partner applications
    // must verify the total amount at checkout is greater than 100 cents (amount >=100).
    if (
      totalAmountInCents < 100 &&
      (currencyCode === 'GBP' ||
        currencyCode === 'AUD' ||
        currencyCode === 'JPY')
    ) {
      return;
    }

    if (this.platform.is('ios')) {
      const dataParameter = {
        amount_money: {
          // amount is in cents
          amount: totalAmountInCents,
          currency_code: currencyCode
        },
        client_id: ENV_CONFIG.squareApplicationID,
        callback_url: 'salonmonster://payment',
        version: '1.3',
        notes: '',
        options: {
          // "supported_tender_types" : ["CREDIT_CARD","CASH","OTHER","SQUARE_GIFT_CARD","CARD_ON_FILE"],
          supported_tender_types: ['CREDIT_CARD', 'CASH'],
          auto_return: true,
          skip_receipt: true,
          clear_default_fees: true,
          location_id: this.getSquareBusinessLocation()
            ? this.getSquareBusinessLocation().id
            : null
          // "location_id": "CBASECqtVFhwuuKB1GX_zQYsVC0gAQ"
        }
      };

      app = iosSchemaName;
      appStoreLink = `https://itunes.apple.com/ca/app/square-point-of-sale-pos-system-register/id335393788`;
      squareDeepLink = `${iosSchemaName}payment/create?data=${encodeURIComponent(
        JSON.stringify(dataParameter)
      )}`;
    } else if (this.platform.is('android')) {
      app = androidPackageName;
      appStoreLink = `https://play.google.com/store/apps/details?id=${androidPackageName}`;
      squareDeepLink = `intent:#Intent;action=com.squareup.pos.action.CHARGE;package=com.squareup;S.browser_fallback_url=salonmonster://payment;S.com.squareup.pos.WEB_CALLBACK_URI=salonmonster://payment;S.com.squareup.pos.CLIENT_ID=${ENV_CONFIG.squareApplicationID};S.com.squareup.pos.API_VERSION=v2.0;i.com.squareup.pos.TOTAL_AMOUNT=100;S.com.squareup.pos.CURRENCY_CODE=CAD;S.com.squareup.pos.TENDER_TYPES=com.squareup.pos.TENDER_CARD,com.squareup.pos.TENDER_CARD_ON_FILE,com.squareup.pos.TENDER_CASH,com.squareup.pos.TENDER_OTHER;end`;
    } else {
      return;
    }

    this.appAvailability.check(app).then(
      () => {
        if (this.platform.is('ios')) {
          this.inAppBrowser.create(squareDeepLink, '_system');
          // this.inAppBrowser.create("square-commerce-v1://payment/create?data=" +
          // encodeURIComponent(JSON.stringify(dataParameter)), '_system');
        } else {
          const options = {
            // url: `${iosSchemaName}payment/create`,
            // url: 'com.squareup',
            // type: 'application/vnd.android.package-archive',
            // action: (<any>window).plugins.intentShim.ACTION_VIEW,
            action: 'com.squareup.pos.action.CHARGE',
            package: 'com.squareup',
            extras: {
              // package: "com.squareup",
              'S.browser_fallback_url': 'salonmonster://payment',
              'S.com.squareup.pos.WEB_CALLBACK_URI': 'salonmonster://payment',
              'S.com.squareup.pos.CLIENT_ID': ENV_CONFIG.squareApplicationID,
              'S.com.squareup.pos.API_VERSION': 'v2.0',
              'i.com.squareup.pos.TOTAL_AMOUNT': totalAmountInCents,
              'S.com.squareup.pos.CURRENCY_CODE': currencyCode,
              'S.com.squareup.pos.TENDER_TYPES':
                'com.squareup.pos.TENDER_CARD,com.squareup.pos.TENDER_CARD_ON_FILE,com.squareup.pos.TENDER_CASH,com.squareup.pos.TENDER_OTHER'
            }

            // package: "com.squareup",
            // "S.browser_fallback_url": "salonmonster://payment",
            // "S.com.squareup.pos.WEB_CALLBACK_URI": "salonmonster://payment",
            // "S.com.squareup.pos.CLIENT_ID": "sq0ids-yOurCLieNtId",
            // "S.com.squareup.pos.API_VERSION" : "v2.0",
            // "i.com.squareup.pos.TOTAL_AMOUNT":100,
            // "S.com.squareup.pos.CURRENCY_CODE" : "CAD",
            // "S.com.squareup.pos.TENDER_TYPES": "com.squareup.pos.TENDER_CARD,
            // com.squareup.pos.TENDER_CARD_ON_FILE,com.squareup.pos.TENDER_CASH,com.squareup.pos.TENDER_OTHER"
          };

          // this.webIntent.startActivity(options).then(() => {
          //   },
          //   (err) => {
          //   });

          this.webIntent.startActivityForResult(options).then(
            () => {
            },
            (err) => {
            }
          );

          // (<any>window).plugins.intentShim.sendBroadcast(options,
          //   function (intent) {
          //     alert("getIntent() :" + JSON.stringify(intent));
          //   },
          //   function () {
          //     alert('Error getting intent');
          //   });
        }
      },
      () => {
        this.inAppBrowser.create(appStoreLink, '_system');
      }
    );
  }

  public handleError(errorCode: string): Observable<boolean> {
    return new Observable((observer) => {
      let errorToDisplay = '';
      switch (errorCode) {
        // The request had a missing or invalid amount to charge.
        case 'amount_invalid_format':
          errorToDisplay = `Amount invalid format`;
          break;

        // The request's amount to charge was too large.
        case 'amount_too_large':
          errorToDisplay = `Amount too large`;
          break;

        // The request's amount to charge was too small.
        case 'amount_too_small':
          errorToDisplay = `Amount too small`;
          break;

        // The request could not be performed. This is usually because there is an unfinished transaction pending in Square Point of Sale.
        // Merchants must open Square Point of Sale and complete the transaction before initiating a new request.
        case 'could_not_perform':
          errorToDisplay = `The request could not be performed. This is usually because there is an unfinished transaction pending in Square Point of Sale. Merchants must open Square Point of Sale and complete the transaction before initiating a new request.`;
          break;

        // The currency code provided in the request does not match the currency associated with the current business.
        case 'currency_code_mismatch':
          errorToDisplay = `The currency code provided in the request does not match the currency associated with the current business.`;
          break;

        // The currency code provided in the request is missing or invalid.
        case 'currency_code_missing':
          errorToDisplay = `Oops! An error occured (currency_code_missing), if this problem persists please contact our <a href="http://suppor.salonmonster.com">support</a>.`;
          break;

        // This merchant account does not support customer management and therefore cannot associate transactions with customers.
        case 'customer_management_not_supported':
          errorToDisplay = `Oops! An error occured (customer_management_not_supported), if this problem persists please contact our <a href="http://suppor.salonmonster.com">support</a>.`;
          break;

        // The URL sent to Square Point of Sale had missing or invalid information.
        case 'data_invalid':
          errorToDisplay = `Oops! An error occured (data_invalid), if this problem persists please contact our <a href="http://suppor.salonmonster.com">support</a>.`;
          break;

        // The customer ID provided in the request does not correspond to a customer in the logged in Square merchant's customer directory.
        case 'invalid_customer_id':
          errorToDisplay = `Oops! An error occured (invalid_customer_id), if this problem persists please contact our <a href="http://suppor.salonmonster.com">support</a>.`;
          break;

        // The request included an invalid tender type.
        case 'invalid_tender_type':
          errorToDisplay = `Oops! An error occured (invalid_tender_type), if this problem persists please contact our <a href="http://suppor.salonmonster.com">support</a>.`;
          break;

        // The transaction failed because the device has no network connection.
        case 'no_network_connection':
          errorToDisplay = `No network conncetion`;
          break;

        // A merchant is not currently logged in to Square Point of Sale.
        case 'not_logged_in':
          errorToDisplay = `You must be logged in before you can use the Square POS`;
          break;

        // The merchant canceled the payment in Square Point of Sale.
        case 'payment_canceled':
          errorToDisplay = '';
          break;

        case 'unsupported_api_version':
          errorToDisplay = `Unsupported API Version`;
          break;

        case 'unsupported_currency_code':
          errorToDisplay = `Unsupported currency`;
          break;

        case 'unsupported_tender_type':
          errorToDisplay = `Oops! An error occured (unsupported_tender_type), if this problem persists please contact our <a href="http://suppor.salonmonster.com">support</a>.`;
          break;

        // The business location currently logged in
        // to Square Point of Sale does not match the location represented by the merchant_id you provided in your request.
        case 'user_id_mismatch':
          errorToDisplay = `Oops! An error occured (user_id_mismatch), if this problem persists please contact our <a href="http://suppor.salonmonster.com">support</a>.`;
          break;

        // The currently logged in location has not activated card processing.
        case 'user_not_active':
          errorToDisplay = `Card processing is not activated.`;
          break;
        default:
          errorToDisplay = `Oops! An error occured, if this problem persists please contact our <a href="http://suppor.salonmonster.com">support</a>.`;
          break;
      }

      if (errorToDisplay === '') {
        observer.next(true);
        observer.complete();
        return;
      }

      this.alertCtrl
        .create({ message: errorToDisplay, buttons: ['OK'] })
        .then((alert) => alert.present())
        .then(() => {
          observer.next(true);
          observer.complete();
        });
    });
  }
}
