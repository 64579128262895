import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WindowResizeService {
  private _windowWidth = new BehaviorSubject(0);

  get windowWidth$() {
    return this._windowWidth;
  }

  get windowWidthValue() {
    return this._windowWidth.value;
  }

  constructor() {
    this.init();
  }

  init() {
    this._windowWidth.next(window.innerWidth);
    fromEvent(window, 'resize')
      .pipe(debounceTime(1000))
      .subscribe(() => {
        const windowWidth = window.innerWidth;
        this._windowWidth.next(windowWidth);
      });
  }
}
