import { Booking, Client, Communication, Stylist } from 'src/app/models';

import { ENV_CONFIG } from 'src/bin/env.config';
import { ErrorHandlerService } from './error-handler.service';
import { Injectable } from '@angular/core';
import { List } from 'immutable';
import { Observable, of, Subject } from 'rxjs';
import { SalonmonsterHttpClient } from '../services/salonmonster-http-client';
import { UserService } from './user.service';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// import 'rxjs/add/observable/of';

@Injectable({
  providedIn: 'root'
})
export class ClientService extends SalonmonsterHttpClient {
  private clientSearchHistory: List<Client>;

  private emailCheckerClientID: number;
  private editClientListSource = new Subject<Client>();

  editClientList$ = this.editClientListSource.asObservable();

  constructor(
    http: HttpClient,
    protected errorHandlerService: ErrorHandlerService,
    public userService: UserService
  ) {
    super(http, userService, errorHandlerService);
    this.clientSearchHistory = List([]);
    this.userService.getLogOutMonitor().subscribe((logout) => {
      if (logout) {
        this.clientSearchHistory = List([]);
        this.emailCheckerClientID = null;
      }
    });
  }

  public setEmailCheckerClientID(clientID: number) {
    this.emailCheckerClientID = clientID;
  }

  public loadClientInfo(clientID: number): Observable<Client> {
    const url = `${ENV_CONFIG.API_ROOT}/clients/${clientID}`;
    return this.get(url).pipe(
      map((data) => {
        const clientData = data[0];
        return Client.parseClientData(clientData);
      })
    );
  }

  public isTermSearchable(term: string): boolean {
    return (
      term !== null &&
      term !== undefined &&
      term.trim() !== '' &&
      term.length > 2
    );
  }

  /**
   * Created to allow search of small numbers as some clients could search for products with price equals 12 or size equals 8"
   *
   * @param {string} term
   * @returns {boolean}
   * @memberof ClientService
   */
  public isProductTermSearchable(term: string): boolean {
    return (
      term !== null &&
      term !== undefined &&
      term.trim() !== '' &&
      term.length > 1
    );
  }

  public searchClients(
    term: string,
    stylist: Stylist,
    useStylistSettings: string = 'false'
  ): Observable<List<Client>> {
    if (!this.isTermSearchable(term)) {
      return of(List([]));
    }

    const url = `${ENV_CONFIG.API_ROOT}/stylists/${stylist.id}/clients/search/${term}?useStylistSettings=${useStylistSettings}`;

    return this.get(url).pipe(
      map((data) => {
        let clients: List<Client> = List([]);
        for (const clientData of data) {
          clients = clients.push(Client.parseClientData(clientData));
        }
        return clients;
      })
    );
  }

  public emailChecker(email: string): Observable<boolean> {
    let url = `${ENV_CONFIG.API_ROOT}/salons/${
      this.userService.getSalon().id
    }/clients/emailchecker/${email}`;

    if (this.emailCheckerClientID) {
      url += `?clientID=${this.emailCheckerClientID}`;
    }

    return this.get(url).pipe(
      map((data) => {
        const available: boolean = data.available;
        return available;
      })
    );
  }

  public getClientBookings(clientID: number, status?: Number []): Observable<List<Booking>> {
    let url = `${ENV_CONFIG.API_ROOT}/clients/${clientID}/bookings?sort=desc`;
    if (status != null) {   
      url += `&status=${status.join(',')}`;
    }
    return this.get(url).pipe(
      map((data) => {
        let bookings: List<Booking> = List.of<Booking>();
        for (const bookingData of data) {
          bookings = bookings.push(Booking.parseBooking(bookingData));
        }

        return bookings;
      })
    );
  }

  public saveClient(
    client: Client,
    mergeFromClientID?: number,
    ignoreEmail?: boolean
  ): Observable<Client> {
    let url = `${ENV_CONFIG.API_ROOT}/clients`;
    url += client.getID() !== undefined ? '/' + client.getID() : '';

    if (client.getID() !== undefined) {
      const params = client.serialize();

      if (mergeFromClientID) {
        params['mergeFromClientID'] = mergeFromClientID;
      }

      if (ignoreEmail) {
        params.email = undefined;
      }

      // update
      return this.put(url, params)
    } else {
      // create
      return this.post(url, client.serialize()).pipe(
        map((data) => {
          return client.setID(data.id);
        })
      );
    }
  }

  public deleteClient(client: Client, index?: number): Observable<Client> {
    const url = `${ENV_CONFIG.API_ROOT}/clients/${client.getID()}`;
    return this.delete(url, {});
  }

  public getCommunicationHistory(
    client: Client,
    salonID: number,
  ): Observable<List<Communication>> {
    const url = `${ENV_CONFIG.API_ROOT}/salons/clients/${client.id}/communications?salonID=${salonID}`;
    return this.get(url).pipe(
      map((data) => {
        let communications: List<Communication> = List([]);
        if(data) {
          for (const communicationData of data) {
            communications = communications.push(
              Communication.parse(communicationData)
            );
          }
        }
        return communications;
      })
    );
  }

  public reSendCommunicationHistory(
    client: Client,
    communication: Communication
  ): Observable<Communication> {
    const url = `${ENV_CONFIG.API_ROOT}/salons/clients/${client.id}/communications/${communication.id}/resend`;
    return this.post(url, {}).pipe(
      map((data) => {
        return Communication.parse(data);
      })
    );
  }

  public getClientSales(
    client: Client
  ): Observable<Communication> {
    const url = `${ENV_CONFIG.API_ROOT}/clients/${client.id}/sales`;
    return this.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }

  public getSearchHistory(): List<Client> {
    return this.clientSearchHistory;
  }

  public addIntoSearchHistory(client: Client): List<Client> {
    if (this.clientSearchHistory.count() === 10) {
      this.clientSearchHistory = this.clientSearchHistory.pop();
    }

    this.clientSearchHistory = this.clientSearchHistory.unshift(client);
    return this.clientSearchHistory;
  }

  public exportToCSV( stylistID: number, fields?: string[],): Observable<Blob> {
    let url = `${ENV_CONFIG.API_ROOT}/salons/clients/export/csv`;

    if (fields) {
      url += '?fields=' + fields.join(',');
    }

    let headers = new HttpHeaders();
    headers = this.createAuthorizationHeader(headers);
    headers.append('Content-Type', 'application/x-www-form-urlencoded');

    return this.http
      .get(url, {
        headers,
        params: {
          stylistID: stylistID
        },
        responseType: 'blob',

      })
      .pipe(
        map((res) => {
          const blob = new Blob([res], { type: 'text/csv' });
          return blob;
        })
      );
  }

  public sendClientCardRequest(client: Client, salonID: number) : Observable<Object> {
    const url = `${ENV_CONFIG.API_ROOT}/clients/${client.id}/requestcard`;
    // let params.salonID = salonID;
    let parameters:any = {};
    parameters.salonID = salonID;
    parameters.clientID = client.id;
    return new Observable<Object> (observer => {
      this.put(url, parameters)
      .pipe(
        map((res: Response) => {
          const body = res;
          const data = body;
          return true;
        })
      )
      .subscribe((success) => {
        observer.next({success: "true"});
        observer.complete();
      },
      (err) => {
        observer.error(this.errorHandlerService.handleError(err));
        observer.complete();
      });    
    });
  }

  public loadClientPhotos(clientID) {
    const url = `${ENV_CONFIG.API_ROOT}/clients/${clientID}/photos`;
    return this.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }

  public insertClientPhoto(clientID, base64String) {
    const url = `${ENV_CONFIG.API_ROOT}/clients/${clientID}/photos`;
    return this.post(url, {clientID: clientID, base64String: base64String}).pipe(
      map((data) => {
        return data;
      })
    );
  }

  public deleteClientPhotos(clientPhotoIDs) {
    const url = `${ENV_CONFIG.API_ROOT}/client-photos`;
    return this.delete(url, {clientPhotoIDs: clientPhotoIDs}).pipe(
      map((data) => {
        return data;
      })
    );
  }

  public updateClientProfilePhoto(clientID, profilePhoto) {
    const url = `${ENV_CONFIG.API_ROOT}/clients/${clientID}/update-profile-photo`;
    return this.put(url, {clientID: clientID, profilePhoto: profilePhoto}).pipe(
      map((data) => {
        return data;
      })
    );
  }
  editClientList(client: Client) {
    this.editClientListSource.next(client);
  }
}
