import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export interface HttpErrorModel {
  status?: number;
  message: string;
}

export class HttpErrorInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const errorMsg: HttpErrorModel = { message: '' };

        if (error.error instanceof ErrorEvent) {
          console.log('this is client side error');
          errorMsg.message = error.error.message;
        } else {
          console.log('this is server side error');
          errorMsg.status = error.status;
          errorMsg.message = error.message;
        }

        console.log(errorMsg);
        return throwError(errorMsg);
      })
    );
  }
}
