import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChartsService {
  constructor() {}

  static timeDisplayFormat() {
    return {
      millisecond: 'D MMM, h:mm a',
      second: 'D MMM, h:mm a',
      minute: 'D MMM, h:mm a',
      hour: 'D MMM, h:mm a',
      day: 'ddd Do MMM YY',
      week: 'ddd Do MMM YY',
      month: 'll',
      quarter: 'll',
      year: 'll'
    };
  }

  public tooltipCallbackFunction(yearTotalWeekData) {
    return {
      title: (point) => {
        return 'Week of ' + point[0].xLabel;
      },
      label: (point) => {
        return 'Daily Avg: ' + point.yLabel;
      },
      afterLabel: (point) => {
        return 'Week Total: ' + yearTotalWeekData[point.index].sum;
      }
    };
  }

  public chartColorArrayFunction(
    r: number,
    b: number,
    g: number,
    transparency: number
  ) {
    return {
      backgroundColor: `rgba( ${r}, ${b}, ${g}, ${transparency})`,
      borderColor: `rgba( ${r}, ${b}, ${g}, 1)`,
      pointBackgroundColor: `rgb(${r}, ${b}, ${g}, 1)`,
      pointBorderColor: 'rgba(51, 51, 51, 0.6)',
      pointHoverBackgroundColor: '#fff'
    };
  }

  public lineChartOption(yearTotalWeekData, xAxisDateChanger) {
    return {
      responsive: true,
      legend: {
        display: true,
        position: 'top',
        labels: {
          usePointStyle: true
        }
      },
      scales: {
        xAxes: [
          {
            type: 'time',
            time: {
              unit: xAxisDateChanger,
              displayFormats: {
                millisecond: 'D MMM, h:mm a',
                second: 'D MMM, h:mm a',
                minute: 'D MMM, h:mm a',
                hour: 'D MMM, h:mm a',
                day: 'D MMM, h:mm a',
                week: 'ddd Do MMM YY',
                month: 'll',
                quarter: 'll',
                year: 'll'
              }
            }
          }
        ],
        yAxes: [
          {
            ticks: {
              precision: 0
            }
          }
        ]
      },
      tooltips: {
        backgroundColor: 'rgba(255,255,255,0.9)',
        bodyFontColor: '#333',
        borderColor: '#999',
        borderWidth: 1,
        caretPadding: 15,
        colorBody: '#666',
        displayColors: true,
        enabled: true,
        intersect: true,
        mode: 'x',
        titleFontColor: '#999',
        titleMarginBottom: 10,
        xPadding: 15,
        yPadding: 15,
        callbacks: this.tooltipCallbackFunction(yearTotalWeekData)
      },
      elements: { point: { radius: 2 } }
    };
  }

  public pieChartOptions(position: string) {
    return {
      legend: {
        position,
        labels: {
          usePointStyle: true
        }
      },
      responsive: true,
      tooltips: {
        callbacks: {}
      },
      pieceLabel: {
        render: (args) => {
          return args.percentage + '%';
        }
      }
    };
  }

  public pieChartColor(...args: any[]) {
    return {
      backgroundColor: [...args],
      borderColor: ['#333', '#333', '#333']
    };
  }

  public barChartOption(label: string, position: string) {
    return {
      scaleShowVerticalLines: false,
      responsive: true,
      legend: {
        display: true,
        position,
        labels: {
          usePointStyle: true
        }
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false
            },
            scaleLabel: {
              display: true,
              labelString: label
            }
          }
        ],
        yAxes: [
          {
            ticks: {
              precision: 0,
              beginAtZero: true
            }
          }
        ]
      }
    };
  }

  public radarChartOption() {
    return {
      legend: {
        labels: {
          usePointStyle: true
        }
      },
      responsive: true
    };
  }
}
