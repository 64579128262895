import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';

import { ENV_CONFIG } from 'src/bin/env.config';
import { ErrorHandlerService } from './error-handler.service';
import { Injectable } from '@angular/core';
import { List } from 'immutable';
import { Observable } from 'rxjs';
import { SalonmonsterHttpClient } from '../services/salonmonster-http-client';
import { UserService } from '../services/user.service';
import { Vacation } from 'src/app/models';
// import 'rxjs/add/operator/map';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VacationService extends SalonmonsterHttpClient {
  constructor(
    http: HttpClient,
    userService: UserService,
    protected errorHandlerService: ErrorHandlerService
  ) {
    super(http, userService, errorHandlerService);
  }

  public getStylistVacations(
    stylistID: number,
    forceLoad?: boolean
  ): Observable<List<Vacation>> {
    const url = `${ENV_CONFIG.API_ROOT}/stylists/${stylistID}/vacations`;

    return this.get(url).pipe(
      map((data) => {
        let response: List<Vacation> = List.of<Vacation>();

        for (const item of data) {
          response = response.push(Vacation.parseVacation(item));
        }

        return response;
      })
    );
  }

  public saveVacation(vacation: Vacation): Observable<Vacation> {
    let url = `${ENV_CONFIG.API_ROOT}/vacations`;

    if (vacation.getId()) {
      url += '/' + vacation.getId();
      return this.put(url, vacation.serialize()).pipe(
        map((data) => {
          return Vacation.parseVacation(data);
        })
      );
    } else {
      return this.post(url, vacation.serialize()).pipe(
        map((data) => {
          return Vacation.parseVacation(data);
        })
      );
    }
  }

  public deleteVacation(vacation: Vacation): Observable<Vacation> {
    const url = `${ENV_CONFIG.API_ROOT}/vacations/${vacation.id}`;
    return this.delete(url, {});
  }
}
