import { Record } from 'immutable';

export const PaymentTypeRecord = Record({
  account: 1,
  amex: 1,
  cash: 1,
  cheque: 1,
  debit: 1,
  discover: 1,
  etransfer: 1,
  giftcard: 1,
  id: undefined,
  mastercard: 1,
  square: 1,
  visa: 1
});
